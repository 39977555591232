// prettier-ignore
const CampaignDefinitionsProd: any[] = [
  {
    "CampaignId": "9554f910-b4f7-4f22-b336-005d4d51f201", // Assigned by OCV
    "StartTimeUtc": "2024-08-30T00:00:00Z", // If null, the campaign is inactive
    "EndTimeUtc": "2026-12-29T00:00:00Z",
    "GovernedChannelType": 0, // 1: Urgent Channel(No channel cooldown and NOT recommended for Production use as user can be bombarded with surveys each time); 0 - Recommended for Production as it has channel cooldown
    "Scope": {
      "Type": 0
    },
    "AdditionalDataRequested": [
      "EmailAddress"
      ],
    "NominationScheme": {
      "Type": 0, // Default
      "PercentageNumerator": 100, // Ratio of users to be nominated to be a candidate numerator
      "PercentageDenominator": 100, // Ratio of users to be nominated to be a candidate denominator
      "NominationPeriod": {
        "Type": 0, // Default
        "IntervalSeconds": 5 
      },
      "CooldownPeriod": {
        "Type": 0,
        "IntervalSeconds": 5
      },
      "FallbackSurveyDurationSeconds": 5
    },
    "SurveyTemplate": {
      "Type": 2, // FPS Template, can be NPS 5pts (0), NPS 11pts (1), FPS (2), NLQs (3), NPS (4), or GenericMessaging Survey (22)
      "ActivationEvent": {
        "Type": 0, // 0 For one event, 1 for multiple events (array of events)
        "Activity": "SurveyActivityTriggered",
        "Count": 1,
        "IsAggregate": true
      },
      "Content": {
        "Prompt": {
          "Title": "We'd love your feedback on this feature.",
          "Question": "We just have two questions for you.",
          "YesLabel": "Sure",
          "NoLabel": "Not now"
        },
        "Rating": {
          "IsZeroBased": false,
          "Question": "How satisfied are you with this feature in JEM?",
          "RatingValuesAscending": ["1 = Not Satisfied", "2 = Satisfied", "3 = Very Satisfied"]
        },
        "Question": { "Question": "If not satisfied, please tell us why." }
      }
    }
  }
];

export default CampaignDefinitionsProd;

import { IDropdownProps } from "@fluentui/react";
import { EmptyRefGuid } from "../../../../GeneralLedger/utilities/EmptyRefGuid";
import { FCWChecklistModel } from "../../../../GeneralLedger/utilities/FetchUserChecklists";
import { fyFp } from "../../../utilities/FiscalYearFiscalPeriod";
import { DomainDataEnum, DomainDataObjects, FiscalPeriod } from "../JEMContext.domainData.types";

export const convertToFiscalYearAndFiscalMonthsDropdownOptions = (
  fiscalYearsAndPeriods: DomainDataObjects["FiscalPeriods"]
): {
  fiscalYears: IDropdownProps["options"];
  fiscalMonths: IDropdownProps["options"];
} => {
  const fiscalYears = new Set<number>();
  const fiscalMonths = new Set<number>();
  for (let i = 0; i < fiscalYearsAndPeriods.length; i++) {
    fiscalYears.add(fiscalYearsAndPeriods[i].fiscalYear);
    fiscalMonths.add(fiscalYearsAndPeriods[i].fiscalMonth);
  }
  const fys = Array.from(fiscalYears).sort((a, b) => a - b);
  const smallest = fys[0];
  const smallestMinus10Years = smallest - 10;
  const range = Array.from({ length: 10 }, (_, i) => smallestMinus10Years + i);
  const fiscalYearsOptions = range.concat(fys).map((fy) => ({ key: `${fy}`, text: `${fy}` }));
  return {
    fiscalYears: fiscalYearsOptions,
    fiscalMonths: Array.from(fiscalMonths)
      .sort((a, b) => a - b)
      .map((fp) => ({ key: `${fp}`, text: `${fp}` }))
  };
};

export const convertToFiscalDropdownOptions = (
  fiscalYearsAndPeriods: DomainDataObjects["FiscalPeriods"],
  limitToCurrentFP = false,
  showblankFYFP = false
): IDropdownProps["options"] => {
  const fiscalYearDropdownObject: IDropdownProps["options"] = [];
  if (showblankFYFP) {
    fiscalYearDropdownObject.push({
      key: `-1 FP -1`,
      text: `All`
    } as IDropdownProps["options"][number]);
  }
  for (let i = 0; i < fiscalYearsAndPeriods.length; i++) {
    if (
      fiscalYearsAndPeriods[i].fiscalMonth > fyFp.fiscalMonth &&
      fiscalYearsAndPeriods[i].fiscalYear === fyFp.fiscalYear &&
      limitToCurrentFP
    ) {
      continue;
    }
    fiscalYearDropdownObject.push({
      key: `${fiscalYearsAndPeriods[i].fiscalYear} FP ${fiscalYearsAndPeriods[i].fiscalMonth}`,
      text: `${fiscalYearsAndPeriods[i].fiscalYear} FP ${fiscalYearsAndPeriods[i].fiscalMonth}`
    } as IDropdownProps["options"][number]);
  }
  if (fiscalYearDropdownObject.length === 0) {
    // Offline FYFPs
    const currentFy = fyFp.fiscalYear;
    const fps = Array.from({ length: 12 }, (_, i) => i + 1);
    for (const fp of fps) {
      fiscalYearDropdownObject.push({
        key: `${currentFy - 1} FP ${fp}`,
        text: `${currentFy - 1} FP ${fp}`
      } as IDropdownProps["options"][number]);
    }
    for (const fp of fps) {
      if (fp > fyFp.fiscalMonth && limitToCurrentFP) {
        continue;
      }
      fiscalYearDropdownObject.push({
        key: `${currentFy} FP ${fp}`,
        text: `${currentFy} FP ${fp}`
      } as IDropdownProps["options"][number]);
    }
  }

  return fiscalYearDropdownObject;
};

export const convertToCurrecyDropdownOptions = (
  currecyCodes: DomainDataObjects["CurrencyCodes"]
): IDropdownProps["options"] => {
  const currencyCodeObject: IDropdownProps["options"] = [];
  for (let i = 0; i < currecyCodes.length; i++) {
    currencyCodeObject.push({
      key: currecyCodes[i].currencyCode,
      text: currecyCodes[i].currencyCode
    });
  }
  return currencyCodeObject;
};

export const convertToReasonCodeDropdownOptions = (
  reasonCodes: DomainDataObjects["JeReasonCodes"],
  allowInactive = false
): IDropdownProps["options"] => {
  const reasonCodeObject: IDropdownProps["options"] = [];
  for (let i = 0; i < reasonCodes.length; i++) {
    if (reasonCodes[i].isActive || allowInactive) {
      reasonCodeObject.push({
        key: reasonCodes[i].code,
        text: reasonCodes[i].jeReason
      });
    }
  }
  return reasonCodeObject;
};

export const convertToJeTypeDropdownOptions = (jeTypes: DomainDataObjects["JeTypes"]): IDropdownProps["options"] => {
  const jeTypeObject: IDropdownProps["options"] = [];
  for (let i = 0; i < jeTypes.length; i++) {
    if (jeTypes[i].jemSupports) {
      jeTypeObject.push({
        key: jeTypes[i].code,
        text: jeTypes[i].code + " - " + jeTypes[i].description
      });
    }
  }
  return jeTypeObject;
};

export const convertToRecurringScheduleFrequencyOptions = (
  frequencies: DomainDataObjects["RecurringScheduleFrequency"]
): IDropdownProps["options"] => {
  const frquencyObject: IDropdownProps["options"] = [];
  for (let i = 0; i < frequencies.length; i++) {
    frquencyObject.push({
      key: frequencies[i].code,
      text: frequencies[i].name
    });
  }
  return frquencyObject;
};

export const convertToReversalReasonsDropdownOptions = (
  reversalReasons: DomainDataObjects["ReversalReasons"]
): IDropdownProps["options"] => {
  const reversalReasonObject: IDropdownProps["options"] = [];
  for (let i = 0; i < reversalReasons.length; i++) {
    reversalReasonObject.push({
      key: reversalReasons[i].code,
      text: reversalReasons[i].code + " - " + reversalReasons[i].name
    });
  }
  return reversalReasonObject;
};

export const getFiscalStartDate = (
  fiscalYear: number,
  fiscalPeriod: number,
  fiscalobject: DomainDataObjects["FiscalPeriods"]
) => {
  const startDate = fiscalobject.find((x) => x.fiscalMonth === fiscalPeriod && x.fiscalYear === fiscalYear)?.startDate;

  return startDate ? startDate : "";
};

export function convertToChecklistsDropdownOptions(checklists: FCWChecklistModel[]) {
  // sort the checklists
  const sortedChecklists = checklists.sort((a, b) => {
    if (a.checklistName < b.checklistName) {
      return -1;
    }
    if (a.checklistName > b.checklistName) {
      return 1;
    }
    return 0;
  });
  const userChecklists: IDropdownProps["options"] = [];
  for (const checklist of sortedChecklists) {
    userChecklists.push({
      key: checklist.checklistRefGuid,
      text: checklist.checklistName,
      data: checklist
    });
  }
  return userChecklists;
}

export function sortDraftDateCodes(
  a: DomainDataObjects[DomainDataEnum.PostingDueDates][0],
  b: DomainDataObjects[DomainDataEnum.PostingDueDates][0]
) {
  if (a.fiscalYear !== b.fiscalYear) {
    return a.fiscalYear - b.fiscalYear;
  }

  if (a.fiscalPeriod !== b.fiscalPeriod) {
    return a.fiscalPeriod - b.fiscalPeriod;
  }

  const numA = parseInt(a.draftDateCode.substring(2));
  const numB = parseInt(b.draftDateCode.substring(2));

  if (numA === numB) {
    return a.draftDateCode.localeCompare(b.draftDateCode);
  }

  if (numA >= 1 && numB >= 1) {
    return numA - numB;
  } else if (numA < 0 && numB < 0) {
    return numB - numA;
  } else if (numA < 0) {
    return 1;
  } else {
    return -1;
  }
}

export function convertToDraftDateCodeDropdownOptions(
  draftDateCodes: DomainDataObjects["PostingDueDates"],
  fiscalPeriod?: FiscalPeriod
): IDropdownProps["options"] {
  // sort the checklists
  const baseDraftDateCodes = fiscalPeriod
    ? (draftDateCodes = draftDateCodes.filter(
        (cc) => cc.fiscalYear === fiscalPeriod.fiscalYear && cc.fiscalPeriod === fiscalPeriod.fiscalMonth
      ))
    : draftDateCodes;
  const sortedDraftDateCodes = baseDraftDateCodes.sort(sortDraftDateCodes);
  const draftDateCodesForDropdown: IDropdownProps["options"] = sortedDraftDateCodes.map((dc) => {
    if (fiscalPeriod) {
      return {
        key: dc.draftDateCode,
        text: dc.draftDateCode,
        data: dc
      };
    }
    return {
      key: `${dc.fiscalYear}${dc.fiscalPeriod}-${dc.draftDateCode}`,
      text: `${dc.fiscalYear}${dc.fiscalPeriod}-${dc.draftDateCode}`,
      data: dc
    };
  });

  return draftDateCodesForDropdown;
}

export function checkForDisabledChecklist(isAdhocDraft: boolean, checklistRefGuid: string | null) {
  if (!checklistRefGuid) {
    return false;
  }

  if (checklistRefGuid === EmptyRefGuid()) {
    return false;
  }

  return !isAdhocDraft;
}

import { isValid } from "date-fns";
import { IAttachment } from "../../../../Shared/components/Attachments/Attachments.types";
import { LocalMessage } from "../../../../Shared/utilities/SharedModels";
import { AdditionalData } from "../../../shared/GL.ActionsRequests";
import { GLAttachmentChanges } from "../../../shared/GL.Attachments";
import { EmptyRefGuid } from "../../../utilities/EmptyRefGuid";
import { FiscalPeriodStringTransformations } from "../../../utilities/FiscalPeriodStringTransformations";
import { GLPageState } from "../GLCreate.State";
import { JeDetailsInfoHeaderRequest, JeDraftLineItemRequest, SupportingDocumentDataInfo } from "../GLCreate.types";
import { ErrorListModel, GLCreateFormikState } from "../GLCreateForm.types";
import { GLCreateActionsEnum } from "../useWeightage";

export interface CreateActionPayload {
  state: GLPageState;
  formikState: GLCreateFormikState;
  diffedLineItems: JeDraftLineItemRequest[];
  attachmentObjects: GLAttachmentChanges;
}

export interface GLCreateResult {
  lineItemsErrors?: ErrorListModel[];
  genericHeaderErrors?: string[];
  message: LocalMessage;
  blockPage: boolean;
  pageState?: GLPageState;
}

export interface CreateActionOnSubmitHandlerMap {
  [GLCreateActionsEnum.Save]: (payload: CreateActionPayload) => Promise<GLCreateResult>;
  [GLCreateActionsEnum.Post]: (payload: CreateActionPayload) => Promise<GLCreateResult>;
  [GLCreateActionsEnum.SendToPoster]: (payload: CreateActionPayload) => Promise<GLCreateResult>;
  [GLCreateActionsEnum.Validate]: (payload: CreateActionPayload) => Promise<GLCreateResult>;
  [GLCreateActionsEnum.NeedsClarification]: (payload: CreateActionPayload) => Promise<GLCreateResult>;
  [GLCreateActionsEnum.Clear]: (payload: CreateActionPayload) => Promise<GLCreateResult>;
  [GLCreateActionsEnum.NoActivity]: (payload: CreateActionPayload, options?: AdditionalData) => Promise<GLCreateResult>;
  [GLCreateActionsEnum.PreReview]: (payload: CreateActionPayload) => Promise<GLCreateResult>;
}

export interface PayloadForSave {
  jeDraftLineItems: JeDraftLineItemRequest[];
  jeDetailsInfo: JeDetailsInfoHeaderRequest;
}

export function IAttachmentsToSdInfos(attachments: IAttachment[]): SupportingDocumentDataInfo[] {
  return attachments.reduce((ctr, attachment) => {
    let path;
    try {
      const url = new URL(attachment.url);
      path = url.pathname.split("/corpacctjestore/")[1];
    } catch (_) {
      path = attachment.url;
    }
    const obj: SupportingDocumentDataInfo = {
      documentType: "",
      fileSize: `${attachment.fileSize}`,
      fileType: "",
      documentURL: path,
      size: undefined,
      type: undefined,
      fileName: attachment.fileName,
      blobName: attachment.blobName,
      document: undefined,
      documentInfo: undefined,
      documentSize: attachment.fileSize,
      errorText: "",
      fileID: `${attachment.id || ""}`,
      isSelected: false,
      isSelectionEnabled: false,
      isStoredInCloud: true,
      isStoredInCorpShare: false,
      name: attachment.fileName,
      status: "" as any,
      statusCode: "" as any,
      uploadedDate: new Date(attachment.uploadedOn).toISOString()
    };
    ctr.push(obj);
    return ctr;
  }, [] as SupportingDocumentDataInfo[]);
}

export function assembleEmptyJeDetails(
  items: CreateActionPayload,
  uiLegacyAction: "" | "Save" | "Clarify" | "Post" | "Validate" | "PreReview" | "Recall"
): PayloadForSave {
  const { fiscalMonth, fiscalYear } = FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
    items.formikState.detailsTabFiscalYearPeriod
  );

  const details: Partial<JeDetailsInfoHeaderRequest> = {
    amounts: [
      {
        currency: "", // <-- NECESSARY
        credit: "0",
        debit: "0",
        total: "0"
      }
    ],
    attachmentStatus: -1,
    attachments: 0,
    author: items.state.createState.author,

    companyCode: 0,
    currency: "",
    description: "",
    fiscalMonth,
    fiscalPeriod: "",
    fiscalYear,
    isBPOEntry: false,
    isEdited: false,
    isReusable: false,
    jEPurpose: "",
    jEType: "",
    poster: "",
    postByDate: items.formikState.detailsTabPostingDate.toLocaleDateString("en-US"),
    reasonCode: "",
    refGuid: items.state.pageConfiguration.refGuid,
    refNumber: "",
    reversalDate: null,
    reviewer: items.formikState.reviewersTabReviewer,
    sdData: {
      isSelectionEnabled: "" as unknown as boolean,
      bulkJEID: [],
      jeid: "" as unknown as number,
      refGuid: items.state.pageConfiguration.refGuid,
      selectedStorage: {
        regionName: "",
        regionKey: null as any
      },
      sdInfos: [],
      countOfDocuments: "" as unknown as number
    },
    status: 0,
    translationDate: null,
    checkListAuthors: items.state.createState.jeDetails.checkListAuthors,
    postedBy: "",
    procStatusAlias: "",
    sequenceNo: items.state.createState.jeDetails.sequenceNo,
    tenantId: items.state.createState.jeDetails.tenantId,
    uiAction: uiLegacyAction,
    userComments: {
      comment: null,
      commentedAlias: ""
    },
    additionalPosters: "",
    additionalReviewers: "",
    draftName: items.state.createState.jeName,
    fileName: items.state.createState.jeName,
    lastComments: "",
    actualTimeTakenMins: 0,
    preReviewers: ""
  };

  return {
    jeDraftLineItems: items.diffedLineItems.map((item) => {
      item.action = "D";
      return item;
    }),
    jeDetailsInfo: details as JeDetailsInfoHeaderRequest
  };
}

export function assembleJeDetails(
  items: CreateActionPayload,
  uiLegacyAction: "" | "Save" | "Clarify" | "Post" | "Validate" | "PreReview"
): PayloadForSave {
  const { fiscalMonth, fiscalYear } = FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
    items.formikState.detailsTabFiscalYearPeriod
  );
  const companyCode =
    items.formikState.detailsTabCompanyCode.length >= 4
      ? Number(items.formikState.detailsTabCompanyCode.substring(0, 4))
      : null;

  const checklistForPayload = items.formikState.detailsTabSelectedChecklist
    ? items.formikState.detailsTabSelectedChecklist.checklistRefGuid
    : items.state.createState.jeDetails.checklistRefGuid || undefined;

  const details: Partial<JeDetailsInfoHeaderRequest> = {
    //TODO : need to check if to add LC amounts here
    amounts: [
      {
        currency: items.formikState.detailsTabCurrency, // <-- NECESSARY
        credit: String(items.formikState.detailsTabCredit),
        debit: String(items.formikState.detailsTabDebit),
        total: String(items.formikState.detailsTabTotal)
      }
    ],
    attachmentStatus: -1,
    attachments: items.formikState.attachments_count,
    author: items.state.createState.author,

    companyCode: companyCode,
    currency: items.formikState.detailsTabCurrency,
    description:
      items.formikState.detailsTabDescription !== ""
        ? items.formikState.detailsTabDescription.trim()
        : items.formikState.detailsTabDescription,
    fiscalMonth: fiscalMonth || undefined,
    fiscalPeriod: fiscalMonth !== 0 ? items.formikState.detailsTabFiscalYearPeriod : "",
    fiscalYear: fiscalYear || undefined,
    isBPOEntry: items.formikState.detailsTabIsBpo,
    isEdited: false,
    isReusable: items.formikState.detailsTabIsLongTerm,
    jEPurpose: items.formikState.detailsTabJePurpose,
    jEType: items.formikState.detailsTabJeType,
    poster: items.formikState.postersTabPoster,
    postByDate: items.formikState.detailsTabPostingDate.toLocaleDateString("en-US"),
    reasonCode: items.formikState.detailsTabReasonCode,
    refGuid: items.state.pageConfiguration.refGuid,
    refNumber: items.formikState.detailsTabRefNumber,
    reversalDate:
      isValid(items.formikState.detailsTabReversalDate) && items.formikState.detailsTabJeType.trim() === "SV"
        ? items.formikState.detailsTabReversalDate.toLocaleDateString("en-US")
        : null,
    reviewer: items.formikState.reviewersTabReviewer,
    sdData: {
      isSelectionEnabled: "" as unknown as boolean,
      bulkJEID: [],
      jeid: "" as unknown as number,
      refGuid: items.state.pageConfiguration.refGuid,
      selectedStorage: {
        regionName: items.attachmentObjects.region_name,
        regionKey: items.attachmentObjects.region_key
      },
      sdInfos: IAttachmentsToSdInfos(items.attachmentObjects.attachments),
      countOfDocuments: "" as unknown as number
    },
    status: 0,
    translationDate: isValid(items.formikState.detailsTabTranslationDate)
      ? items.formikState.detailsTabTranslationDate.toLocaleDateString("en-US")
      : null,
    checkListAuthors: items.state.createState.jeDetails.checkListAuthors,
    fcwChecklistGuid: checklistForPayload === EmptyRefGuid() ? null : checklistForPayload,
    postedBy: items.state.createState.jeDetails.postedBy,
    procStatusAlias: items.state.createState.jeDetails.procStatusAlias,
    sequenceNo: items.state.createState.jeDetails.sequenceNo,
    tenantId: items.state.createState.jeDetails.tenantId,
    uiAction: uiLegacyAction,
    userComments: {
      addlPosters: items.formikState.postersTabBackupPosters.join(";"),
      comment: items.formikState.postersTabComments,
      commentedAlias: items.formikState.postersTabPoster,
      poster: items.formikState.postersTabPoster
    },
    additionalPosters: items.formikState.postersTabBackupPosters.join(";"),
    additionalReviewers: items.formikState.reviewersTabAdditionalReviewers.join(";"),
    draftName: items.formikState.headerJeName,
    fileName: items.state.createState.jeName,
    lastComments: items.formikState.postersTabComments,
    actualTimeTakenMins: items.formikState.detailsTabActualTimeTaken,
    preReviewers: items.formikState.reviewersTabPreReviewers.join(";"),
    scheduleName: items.formikState.recurringDetailsTabScheduleName,
    frequency: items.formikState.recurringDetailsTabFrequency,
    recurringJEScheduleId: items.formikState.recurringDetailsTabRJEScheduleId,
    scheduleStartDate: items.formikState.recurringDetailsTabStartDate
      ? items.formikState.recurringDetailsTabStartDate.toLocaleDateString("en-US")
      : null,
    scheduleEndDate: items.formikState.recurringDetailsTabEndDate
      ? items.formikState.recurringDetailsTabEndDate.toLocaleDateString("en-US")
      : null,
    nextPostingDate: items.formikState.recurringDetailsTabNextPostingDate
      ? items.formikState.recurringDetailsTabNextPostingDate.toLocaleDateString("en-US")
      : null,
    lastPostedDate: "",
    isLongTerm: items.formikState.recurringDetailsTabIsLongTerm,
    allowedToPost: true,
    lastProcessed: "",
    templateJeId: 0,
    featureFlag: true
  };

  return {
    jeDraftLineItems: items.diffedLineItems,
    jeDetailsInfo: details as JeDetailsInfoHeaderRequest
  };
}

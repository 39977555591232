import { OrderedMap } from "immutable";
import { useLocation } from "react-router-dom";
import { getStateObjects } from "../../utilities/UrlHelpers";
import { Filters } from "../DashboardFilterBar/DashboardFilterBar";
import { FilterSchema, FilterState, IIndexedTile, IPivotItem } from "./DashboardFilter.types";

// eslint-disable-next-line
import toCamelCase from "lodash/camelCase";

export interface TilesConfiguration {
  tilesForPivot: IPivotItem[];
  tiles: OrderedMap<string, IIndexedTile>;
  currentTile: string | null;
}

export type TileConfiurationAndFilters = TilesConfiguration & { filterBar: Filters };

export interface IInternalFilterState extends Omit<TilesConfiguration, "filterBar"> {
  searchCriteria: string | null;
  control: number;
}

export function getMainTiles<ListType extends { [key: string]: any }>(
  gridItems: ListType[],
  columnNameForTiles: string
): string[] {
  const availableStatus = Array.from(
    gridItems.reduce((set: Set<string>, row: ListType) => {
      set.add(row[columnNameForTiles as keyof typeof row]);
      return set;
    }, new Set<string>())
  ) as string[];
  const order = {
    f: 1,
    d: 2,
    u: 3,
    p: 4,
    s: 6
  };
  availableStatus.sort((a: string, b: string) => {
    const newA = a[0].toLowerCase();
    const newB = b[0].toLowerCase();
    const compareA = newA in order ? order[newA as keyof typeof order] : 100;
    const compareB = newB in order ? order[newB as keyof typeof order] : 100;
    return compareA - compareB;
  });
  return availableStatus as string[];
}

export function assembleFilterBar<ListType extends { [key: string]: any }>(
  gridItems: ListType[],
  filters: FilterSchema<ListType>[],
  localStorageKey?: string
): Filters {
  const filterSets = filters.reduce(
    (setDictionary, singleFilter) => {
      const fName = singleFilter.filterName;

      setDictionary[fName] = new Set<string>(!singleFilter.singleSelection ? ["All"] : undefined);

      return setDictionary;
    },
    {} as {
      [key: string]: Set<string>;
    }
  );

  for (const row of gridItems) {
    for (const filterObject of filters) {
      if (!filterObject.columnName) continue;

      const fName = filterObject.filterName;
      const currentSet = filterSets[fName];
      const currentFilterColumnName = filterObject.columnName;
      if (typeof row[currentFilterColumnName] === "string") {
        currentSet.add(row[currentFilterColumnName].trim());
      } else if (Array.isArray(row[currentFilterColumnName])) {
        const rowArray = row[currentFilterColumnName] as unknown as string[] | number[];
        for (const rowItem of rowArray) {
          if (typeof rowItem === "string") {
            currentSet.add(rowItem.trim().toUpperCase());
          } else if (typeof rowItem === "number") {
            currentSet.add(rowItem.toString());
          }
        }
      } else {
        currentSet.add(row[currentFilterColumnName]);
      }
    }
  }

  const checkFiltersAvailability = (filters) => {
    if (filters) return Object.keys(filters).filter((f) => filters[f] !== null).length !== 0;
    else return false;
  };

  const localStoreValue = localStorageKey && localStorage.getItem(localStorageKey);
  const isFiltersAvailable = localStoreValue ? checkFiltersAvailability(JSON.parse(localStoreValue).filterBar) : false;

  let filterState = {} as Filters;
  if (localStoreValue && isFiltersAvailable) {
    filterState = JSON.parse(localStoreValue).filterBar;
  } else {
    filterState = Object.keys(filterSets).reduce((ctr, fNameKey) => {
      const filterName = toCamelCase(fNameKey);
      // MAKE SURE THE ARRAY IS SORTED BUT "All" MUST COME FIRST
      ctr[`${filterName}Available`] = Array.from(filterSets[fNameKey]).sort((a, b) => {
        if (a === "All") return -1;
        if (b === "All") return 1;
        return typeof a === "number" && typeof b === "number" ? a - b : a?.localeCompare(b);
      });
      ctr[`${filterName}Selected`] = null;
      return ctr;
    }, {} as Filters);
  }
  return filterState;
}

// Calculate tiles (remove tiles with 0 count)
// Calculate indices of tiles (use an OrderedDict if necessary)
// Get the currentTile from location or first tile
export function convertGridItemsToFilters<ListType extends { [key: string]: any }>(
  gridItems: ListType[],
  columnNameForTiles: string
): TilesConfiguration {
  const tilesForPivot: {
    name: string;
    count: number;
  }[] = [];
  let tiles = OrderedMap({});
  let index = 0;
  const allTiles: string[] = getMainTiles(gridItems, columnNameForTiles);
  for (const tileName of allTiles) {
    const countArr = gridItems.filter(
      (row: ListType) => row[columnNameForTiles].toLowerCase().indexOf(tileName.toLowerCase()) !== -1
    );
    const count = countArr.length;
    const tileObj = {
      tileIndex: index,
      tileCount: count
    };
    tiles = tiles.set(tileName, tileObj);
    tilesForPivot.push({
      name: tileName,
      count
    });
    index = index + 1;
  }

  return {
    tiles: tiles as OrderedMap<string, IIndexedTile>,
    tilesForPivot,
    currentTile: null
  };
}

// Grab the FilterState from the Location State
// Any existing filters previously applied need to replace the current ones

export function mergeFilterStateWithLocationFilterState(
  location: ReturnType<typeof useLocation>,
  newFilterState: FilterState
): { state: FilterState; locationCleared: boolean } {
  const state = getStateObjects(location);
  if (state) {
    try {
      // once we merge the location state we clear it
      window.history.replaceState({}, document.title);
      location.state = null;
      if (state.state && "filterState" in state.state) {
        const locationFilterState = new FilterState(state.state.filterState as unknown as FilterState);
        newFilterState.currentTile = locationFilterState.currentTile;
        newFilterState.searchCriteria = locationFilterState.searchCriteria;
        for (const filterKey of Object.keys(locationFilterState.filterBar)) {
          if (filterKey.indexOf("Selected") !== -1 && filterKey in newFilterState.filterBar) {
            newFilterState.filterBar[filterKey] = Array.isArray(locationFilterState.filterBar[filterKey])
              ? [...(locationFilterState.filterBar[filterKey] as any[])]
              : locationFilterState.filterBar[filterKey];
          }
        }
        return {
          state: newFilterState,
          locationCleared: true
        };
      }
    } catch (e) {
      // do nothing (not even log)
    }
  }
  return {
    state: newFilterState,
    locationCleared: false
  };
}

// Current Tile might be unselected (null)
// Or might be a Number (the index of the tile)
// we +1 to account for the All Tile
export function getCurrentTile(tileState: IInternalFilterState, filterCriteria: Filters): string | null {
  if (!tileState.currentTile) {
    return null;
  }
  if (tileState.tiles.size > 0) {
    const possibleIndex = Number(tileState.currentTile);
    if (isNaN(possibleIndex)) {
      return tileState.currentTile;
    }
    return tileState.tilesForPivot[possibleIndex].name;
  } else if (filterCriteria.statusAvailable) {
    const index = filterCriteria.statusAvailable.findIndex((v) => v === tileState.currentTile);
    if (index !== -1) {
      return filterCriteria.statusAvailable[index];
    }
  }
  return null;
}

import { IDropdownOption } from "@fluentui/react";
import { GLAttachment } from "../../../Shared/components/Attachments/Attachments.types";
import {
  DomainDataEnum,
  DomainDataObjects,
  FiscalPeriod
} from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import {
  convertToCurrecyDropdownOptions,
  convertToFiscalDropdownOptions,
  convertToJeTypeDropdownOptions,
  convertToReasonCodeDropdownOptions
} from "../../../Shared/contexts/JEMContext/utilities/DomainData.utils";
import { ApiParsingUtilities } from "../../../Shared/utilities/ApiParsingUtilities";
import { ConvertToDefaultTimeString } from "../../../Shared/utilities/DateUtilities";
import { calculateCalendarMonthAndYear } from "../../../Shared/utilities/FiscalYearFiscalPeriod";
import { IUserProviderState } from "../../../Shared/utilities/RequestUtilities";
import { EMPTY_CHECKLIST } from "../../shared/ChecklistPeriodChangeHandler";
import { AliasUtilities } from "../../utilities/AliasUtilities";
import { EmptyRefGuid } from "../../utilities/EmptyRefGuid";
import { FCWChecklistModel } from "../../utilities/FetchUserChecklists";

import { FiscalPeriodStringTransformations } from "../../utilities/FiscalPeriodStringTransformations";
import { GetCurrentFiscalPeriod } from "../../utilities/GetCurrentFiscalPeriod";
import { LineAmountFormat } from "../../utilities/GetLineAmountFormat";
import { jeStatusToString, processXmlComments } from "../GLDetails/GLDetails.jeid.State";
import { GLActionResult } from "./Actions/GLCreate.UIActionDelegation";
import { getCurrencyCode } from "./GLCreateTabs/GLCreate.CompanyCode";
import { HardCurrencies } from "./GLCreate.LineAmountCalculation";
import {
  GLDraftObjectFromAPI,
  SupportingDocumentDataInfo,
  JeDraftLineItem,
  GLCreateDraftObjectFromApi,
  JeDetailsInfo,
  FiscalPeriodModel,
  GLCreateQueryStringParameters,
  JeLineItemErrors,
  ValidateCallResponse,
  AttachmentInformation
} from "./GLCreate.types";
import {
  ErrorListModel,
  GLCreateFormikState,
  GLCreateOverrides,
  GLCreateState,
  LineItemModel
} from "./GLCreateForm.types";
import { validateLineItem } from "./GLCreateLineItems/GLCreateLineItems.validateLineItem";
import _default from "yup/lib/locale";
import { calculateLineAmounts, LineAmountOptions } from "./GLCreate.LineAmountCalculation";
import { JemConfiguration } from "../../../JemConfiguration";
import { GLCommentsModel } from "../../../Shared/utilities/SharedModels";

export enum CreatePageType {
  SingleCurrency = "SingleCurrency",
  DualCurrency = "DualCurrency",
  FourCurrency = "FourCurrency"
}

export interface PageConfiguration {
  pageType: CreatePageType;
  isReadOnly: boolean;
  jeId: number;
  refGuid: string;
  templateId: string;
  maxLineItems: number;
  maxAdditionalReviewers: number;
  excludedJeTypes: string[];
  maxBackupPosters: number;
  version: number;
  isChecklist: boolean;
  isF05Posting: boolean;
  isHardCurrency: boolean;
  featureFlags: {
    disableTaxCodeValidation: boolean;
  };
  maxPreReviewers?: number;
  // isCurrentUserAuthor: boolean;
  // isCurrentUserPrimaryReviewer: boolean;
  // isCurrentUserAdditionalReviewer: boolean;
  // isCurrentUserPrimaryPoster: boolean;
  // isCurrentUserBackupPoster: boolean;
}

export interface GLPageState {
  createState: GLCreateState;
  lineItems: LineItemModel[];
  pageConfiguration: PageConfiguration;
}

export function createBlankCreateState(): GLPageState {
  const fiscalPeriod: FiscalPeriodModel = {
    calendarMonth: 0,
    calendarYear: 0,
    fiscalYear: 0,
    fiscalPeriod: "",
    fiscalMonth: 0,
    startDate: new Date(),
    endDate: new Date(),
    index: null
  };
  const state: GLPageState = {
    createState: {
      isDefaultState: true,
      jeName: "",
      jeStatus: "",
      status: 1,
      author: "",
      createdBy: "",
      allowTenantPosting: false,
      tenantId: 0,
      modifiedDate: ConvertToDefaultTimeString(new Date()),
      poster: "",
      createdDate: "",
      lineAmountFormat: 2,
      jeDetails: {
        refNumber: "",
        companyCode: "",
        jeType: "",
        reasonCode: "",
        currency: "",
        fiscalPeriod: fiscalPeriod,
        postingDate: "",
        translationDate: "",
        reversalDate: "",
        description: "",
        jePurpose: "",
        isBpo: false,
        isLongTerm: false,
        debit: 0,
        credit: 0,
        total: 0,
        debitLC: 0,
        creditLC: 0,
        totalLC: 0,
        debitLC2: 0,
        creditLC2: 0,
        totalLC2: 0,
        debitLC3: 0,
        creditLC3: 0,
        totalLC3: 0,
        checkListAuthors: "",
        checklistRefGuid: EmptyRefGuid(),
        checklistName: "NOT APPLICABLE",
        isAdhocDraft: false,
        postedBy: "",
        procStatusAlias: "",
        sequenceNo: "",
        tenantId: "",
        templateId: "",
        uiAction: "",
        actualTimeTakenMins: 0,
        frequency: "",
        scheduleStartDate: null,
        nextPostingDate: null,
        scheduleEndDate: null,
        scheduleName: "",
        recurringJEScheduleId: 0
      },
      attachments: [],
      attachments_region: 0,
      lock_region: false,
      posters: {
        poster: "",
        backupPosters: [],
        comment: "",
        table: []
      },
      reviewers: {
        reviewer: "",
        additionalReviewers: [],
        preReviewers: []
      },
      errorTable: [],
      jeHeaderError: [],
      jeTypes: [],
      reasonCodes: [],
      currencies: [],
      fysfps: [],
      userChecklists: [],
      rowWeightage: 0,
      isExternalDraft: false
    },
    lineItems: [],
    pageConfiguration: {
      pageType: CreatePageType.SingleCurrency,
      isReadOnly: false,
      jeId: -1,
      refGuid: "",
      templateId: "",
      maxLineItems: 0,
      isChecklist: false,
      isF05Posting: false,
      isHardCurrency: false,
      maxAdditionalReviewers: 0,
      excludedJeTypes: [],
      maxBackupPosters: 0,
      version: 0,
      featureFlags: {
        disableTaxCodeValidation: true
      },
      maxPreReviewers: 0
    }
  };
  return state;
}

export function defaultPageStateForCreate(
  configuration: Pick<JemConfiguration, "GeneralLedgerApi" | "featureFlags" | "environment">,
  userContext: Pick<IUserProviderState, "jemUser" | "user">,
  queryStringParams: Pick<GLCreateQueryStringParameters, "Templateid"> &
    Partial<Pick<GLCreateQueryStringParameters, "RefGuid">>,
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.JeParameters
  >,
  initialState: GLCreateDraftObjectFromApi | null,
  defaultOverrides?: GLCreateOverrides
): GLPageState {
  const templateId = initialState ? `${initialState.result.templateID}` : queryStringParams.Templateid;
  // const pageType = templateId === "1" ? CreatePageType.SingleCurrency : CreatePageType.DualCurrency;
  let pageType: CreatePageType;

  if (templateId === "1") {
    pageType = CreatePageType.SingleCurrency;
  } else if (templateId === "2") {
    pageType = CreatePageType.DualCurrency;
  } else if (templateId === "4") pageType = CreatePageType.FourCurrency;
  else pageType = CreatePageType.SingleCurrency;

  const overrideFiscalPeriod: Pick<FiscalPeriodModel, "fiscalMonth" | "fiscalYear"> | null =
    defaultOverrides && defaultOverrides.fiscalMonth && defaultOverrides.fiscalYear
      ? {
          fiscalMonth: defaultOverrides.fiscalMonth,
          fiscalYear: defaultOverrides.fiscalYear
        }
      : null;

  const fiscalPeriod = GetCurrentFiscalPeriod(domainData, overrideFiscalPeriod);
  const jeTypes = convertToJeTypeDropdownOptions(domainData.JeTypes || []);
  const reasonCodes = convertToReasonCodeDropdownOptions(domainData.JeReasonCodes || []);
  const currencies = convertToCurrecyDropdownOptions(domainData.CurrencyCodes || []);
  const fysfps = convertToFiscalDropdownOptions(domainData.FiscalPeriods || []);

  const createState: GLCreateState = {
    isDefaultState: true,
    jeName:
      defaultOverrides && defaultOverrides.jeName
        ? defaultOverrides.jeName
        : initialState
        ? initialState.result.fileName
        : "",
    // seems static on classic
    jeStatus: "Draft",
    status: 1,
    author: userContext.user.alias,
    createdBy: userContext.user.alias,
    allowTenantPosting: false,
    tenantId: 0,
    modifiedDate: ConvertToDefaultTimeString(new Date()),
    poster: userContext.user.alias,
    lineAmountFormat: LineAmountFormat.defaultLineAmountFormat,
    createdDate: new Date().toLocaleDateString("en-US"),
    jeDetails: {
      refNumber: defaultOverrides && defaultOverrides.refNumber ? defaultOverrides.refNumber : "",
      companyCode: defaultOverrides && defaultOverrides.companyCode ? defaultOverrides.companyCode : "",
      jeType: defaultOverrides && defaultOverrides.jeType ? defaultOverrides.jeType : "",
      reasonCode: defaultOverrides && defaultOverrides.reasonCode ? defaultOverrides.reasonCode : "",
      currency: defaultOverrides && defaultOverrides.currency ? defaultOverrides.currency : "",
      fiscalPeriod: fiscalPeriod,
      postingDate: new Date(`${fiscalPeriod.calendarMonth}/15/${fiscalPeriod.calendarYear}`).toLocaleDateString(
        "en-US"
      ),
      translationDate: defaultOverrides && defaultOverrides.translationDate ? defaultOverrides.translationDate : "",
      reversalDate: defaultOverrides && defaultOverrides.reversalDate ? defaultOverrides.reversalDate : "",
      description: defaultOverrides && defaultOverrides.description ? defaultOverrides.description : "",
      jePurpose: defaultOverrides && defaultOverrides.jePurpose ? defaultOverrides.jePurpose : "",
      isBpo: false,
      isLongTerm: defaultOverrides && defaultOverrides.isLongTerm ? defaultOverrides.isLongTerm : false,
      debit: 0,
      credit: 0,
      total: 0,
      debitLC: 0,
      creditLC: 0,
      totalLC: 0,
      debitLC2: 0,
      creditLC2: 0,
      totalLC2: 0,
      debitLC3: 0,
      creditLC3: 0,
      totalLC3: 0,
      checklistRefGuid: EmptyRefGuid(),
      checklistName: "NOT APPLICABLE",
      actualTimeTakenMins: 0,
      isAdhocDraft: false,

      checkListAuthors: "",
      postedBy: "",
      procStatusAlias: "",
      sequenceNo: "",
      tenantId: "",
      templateId: "",
      uiAction: "",
      frequency: "",
      scheduleStartDate: null,
      scheduleEndDate: null,
      nextPostingDate: null,
      scheduleName: "",
      recurringJEScheduleId: 0
    },
    attachments: [],
    attachments_region: 2,
    lock_region: false,
    posters: {
      poster: defaultOverrides && defaultOverrides.poster ? defaultOverrides.poster : userContext.user.alias,
      backupPosters: defaultOverrides && defaultOverrides.backupPosters ? defaultOverrides.backupPosters : [],
      comment: "",
      table: []
    },
    reviewers: {
      reviewer: userContext.jemUser.supervisor,
      additionalReviewers:
        defaultOverrides && defaultOverrides.additionalReviewers ? defaultOverrides.additionalReviewers : [],
      preReviewers: defaultOverrides && defaultOverrides.preReviewers ? defaultOverrides.preReviewers : []
    },
    errorTable: [],
    jeHeaderError: [],
    jeTypes,
    reasonCodes,
    currencies,
    fysfps,
    userChecklists: [],
    rowWeightage: -1,
    isExternalDraft: false
  };
  return {
    createState,
    lineItems: defaultOverrides && defaultOverrides.lineItems ? defaultOverrides.lineItems : [],
    pageConfiguration: {
      pageType,
      isReadOnly: false,
      isChecklist: false,
      isF05Posting: false,
      isHardCurrency: false,
      jeId: initialState ? initialState.result.jeId : -1,
      refGuid: initialState ? initialState.result.refGuid : queryStringParams.RefGuid || "",
      templateId,
      excludedJeTypes:
        domainData.JeParameters.filter((item) => item.name === "ExcludeJEType").map((item) => item.value) || [],
      maxAdditionalReviewers: 75,
      maxLineItems: 900,
      maxBackupPosters: 75,
      version: 1,
      featureFlags: {
        disableTaxCodeValidation:true
      },
      maxPreReviewers: 75
    }
  };
}

export function sanitizeDefaultOverrides(defaultOverrides?: Partial<GLCreateOverrides>): GLCreateOverrides {
  const sanitizedData: GLCreateOverrides = {
    jeName: "",
    refNumber: "",
    companyCode: "",
    jeType: "",
    reasonCode: "",
    currency: "",
    fiscalYear: 0,
    fiscalMonth: 0,
    postingDate: "",
    translationDate: "",
    reversalDate: "",
    description: "",
    jePurpose: "",
    isLongTerm: false,
    attachments: [],
    poster: "",
    backupPosters: [],
    additionalReviewers: [],
    preReviewers: [],
    lineItems: []
  };
  if (defaultOverrides === undefined) return sanitizedData;

  sanitizedData.jeName = defaultOverrides.jeName ? defaultOverrides.jeName.replace(/[<>/]/g, "").substring(0, 50) : "";
  sanitizedData.jeType = defaultOverrides.jeType ? defaultOverrides.jeType : "";
  sanitizedData.reasonCode = defaultOverrides.reasonCode ? defaultOverrides.reasonCode : "";
  sanitizedData.currency = defaultOverrides.currency ? defaultOverrides.currency.toUpperCase() : "";
  sanitizedData.fiscalYear = defaultOverrides.fiscalYear ? defaultOverrides.fiscalYear : 0;
  sanitizedData.fiscalMonth = defaultOverrides.fiscalMonth ? defaultOverrides.fiscalMonth : 0;
  sanitizedData.postingDate = defaultOverrides.postingDate ? defaultOverrides.postingDate : "";
  sanitizedData.translationDate = defaultOverrides.translationDate ? defaultOverrides.translationDate : "";
  sanitizedData.reversalDate = defaultOverrides.reversalDate ? defaultOverrides.reversalDate : "";
  sanitizedData.isLongTerm = defaultOverrides.isLongTerm ? defaultOverrides.isLongTerm : false;
  sanitizedData.attachments = defaultOverrides.attachments ? defaultOverrides.attachments : [];
  sanitizedData.poster = defaultOverrides.poster ? defaultOverrides.poster : "";
  sanitizedData.backupPosters = defaultOverrides.backupPosters ? defaultOverrides.backupPosters : [];
  sanitizedData.additionalReviewers = defaultOverrides.additionalReviewers ? defaultOverrides.additionalReviewers : [];
  sanitizedData.preReviewers = defaultOverrides.preReviewers ? defaultOverrides.preReviewers : [];
  sanitizedData.lineItems = defaultOverrides.lineItems ? defaultOverrides.lineItems : [];

  sanitizedData.jePurpose = defaultOverrides.jePurpose
    ? defaultOverrides.jePurpose.replace(/[<>/]/g, "").substring(0, 250)
    : "";
  sanitizedData.description = defaultOverrides.description
    ? defaultOverrides.description.replace(/[<>/]/g, "").substring(0, 25)
    : "";
  sanitizedData.refNumber = defaultOverrides.refNumber ? defaultOverrides.refNumber.substring(0, 6) : "";
  sanitizedData.companyCode = defaultOverrides.companyCode ? defaultOverrides.companyCode.substring(0, 4) : "";

  return sanitizedData;
}

export function assembleCreatePageState(
  configuration: Pick<JemConfiguration, "GeneralLedgerApi" | "featureFlags" | "environment">,
  userContext: Pick<IUserProviderState, "jemUser" | "user">,
  queryStringParams: Pick<GLCreateQueryStringParameters, "RefGuid" | "Templateid">,
  draftModel: GLDraftObjectFromAPI,
  jeError: ValidateCallResponse,
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.JeParameters
    | DomainDataEnum.JeStatus
  >,
  defaultOverrides?: Partial<GLCreateOverrides>
): GLPageState {
  console.log("assembleCreatePageState", defaultOverrides);
  const overrides = sanitizeDefaultOverrides(defaultOverrides);
  const defaultState = defaultPageStateForCreate(
    configuration,
    userContext,
    queryStringParams,
    domainData,
    null,
    overrides
  );
  const fiscalPeriod =
    getFiscalPeriodFromDetails(draftModel.jeDetailsInfo, domainData) || defaultState.createState.jeDetails.fiscalPeriod;

  // check if fiscalPeriod is in defaultState.createState.fysfps
  const fysfps = defaultState.createState.fysfps.findIndex((item) => {
    const { fiscalMonth, fiscalYear } = FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
      item.text
    );
    return fiscalMonth === fiscalPeriod.fiscalMonth && fiscalYear === fiscalPeriod.fiscalYear;
  });
  if (fysfps === -1) {
    // TODO: Log to appInsights
    defaultState.createState.fysfps.unshift({
      key: FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(
        fiscalPeriod.fiscalYear,
        fiscalPeriod.fiscalMonth
      ),
      text: FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(
        fiscalPeriod.fiscalYear,
        fiscalPeriod.fiscalMonth
      )
    } as IDropdownOption);
  }

  let pageType: CreatePageType | null = null;
  if (draftModel.jeDetailsInfo.templateId === "1") {
    pageType = CreatePageType.SingleCurrency;
  } else if (draftModel.jeDetailsInfo.templateId === "2") {
    pageType = CreatePageType.DualCurrency;
  } else if (draftModel.jeDetailsInfo.templateId === "4") {
    pageType = CreatePageType.FourCurrency;
  }
  const pageConfiguration: PageConfiguration = {
    pageType: pageType ? pageType : defaultState.pageConfiguration.pageType,
    isReadOnly: draftModel.isReadOnly !== null ? draftModel.isReadOnly : defaultState.pageConfiguration.isReadOnly,
    jeId: draftModel.jeId || defaultState.pageConfiguration.jeId,
    refGuid: defaultState.pageConfiguration.refGuid,
    isChecklist:
      draftModel.jeDetailsInfo.fcw?.checklistGuid && draftModel.jeDetailsInfo.fcw.checklistGuid !== EmptyRefGuid()
        ? true
        : false,
    isF05Posting: draftModel.jeDetailsInfo.tenantId !== null ? Number(draftModel.jeDetailsInfo.tenantId) <= 0 : false,
    isHardCurrency: HardCurrencies.includes(
      draftModel.jeDetailsInfo.companyCode !== null ? draftModel.jeDetailsInfo.companyCode.toString() : ""
    ),
    templateId: draftModel.jeDetailsInfo.templateId || defaultState.pageConfiguration.templateId,
    excludedJeTypes: defaultState.pageConfiguration.excludedJeTypes,
    maxAdditionalReviewers: defaultState.pageConfiguration.maxAdditionalReviewers,
    maxLineItems: defaultState.pageConfiguration.maxLineItems,
    maxBackupPosters: defaultState.pageConfiguration.maxBackupPosters,
    version: draftModel.jeDetailsInfo.versionNo || defaultState.pageConfiguration.version,
    featureFlags: {
      disableTaxCodeValidation:true
    },
    maxPreReviewers: defaultState.pageConfiguration.maxPreReviewers
  };

  const [lineItems, errorTable] = jeDraftLineItemsToModelLineItems(
    draftModel.jeDraftLineItems,
    jeError.jeLineItemErrors || [],
    pageConfiguration
  );

  const possibleTenantId = Number(draftModel.jeDetailsInfo.tenantId);

  const { primaryReviewer, secondaryReviewer } = getReviewer(
    draftModel.jeDetailsInfo.reviewer,
    defaultState.createState.reviewers.reviewer
  );
  const additionalReviewers = AliasUtilities.getAdditionalReviewers(
    draftModel.jeDetailsInfo.additionalReviewers,
    overrides.additionalReviewers ? overrides.additionalReviewers.join(";") : secondaryReviewer
  );
  // Reusing getBackupPosters method to get formatted preReviewers
  const preReviewers = AliasUtilities.getBackupPosters(
    draftModel.jeDetailsInfo.preReviewers,
    overrides.preReviewers ? overrides.preReviewers : defaultState.createState.reviewers.preReviewers
  );
  const backupPosters = AliasUtilities.getBackupPosters(
    draftModel.jeDetailsInfo.additionalPosters,
    overrides.backupPosters && overrides.backupPosters.length !== 0
      ? overrides.backupPosters
      : defaultState.createState.posters.backupPosters
  );

  let postingDate = draftModel.jeDetailsInfo.postByDate || defaultState.createState.jeDetails.postingDate;
  if (
    !draftModel.jeDetailsInfo.postByDate &&
    draftModel.jeDetailsInfo.fiscalMonth !== 0 &&
    draftModel.jeDetailsInfo.fiscalYear !== 0
  ) {
    postingDate = new Date(`${fiscalPeriod.calendarMonth}/15/${fiscalPeriod.calendarYear}`).toLocaleDateString("en-US");
  }

  const { region, lockRegion } = getRegionDetails(draftModel.jeDetailsInfo.sdData);
  const attachments =
    draftModel.jeDetailsInfo.sdData && region !== null
      ? supportingDocumentInformationToGLAttachment(draftModel.jeDetailsInfo.sdData.sdInfos, region)
      : [];
  console.log("jeName", overrides);
  const createState: GLCreateState = {
    isDefaultState: false,
    jeName: ApiParsingUtilities.addDefault(
      overrides && overrides.jeName
        ? overrides.jeName
        : draftModel.jeDetailsInfo.draftName || defaultState.createState.jeName
    ),
    // seems static on classic
    jeStatus: jeStatusToString(domainData, draftModel.jeDetailsInfo.status),
    status: draftModel.jeDetailsInfo.status,
    author: ApiParsingUtilities.addDefault(draftModel.jeDetailsInfo.author || defaultState.createState.author, ""),
    createdBy: ApiParsingUtilities.addDefault(draftModel.createdBy || defaultState.createState.createdBy, ""),
    allowTenantPosting: draftModel.jeDetailsInfo.allowTenantPosting || defaultState.createState.allowTenantPosting,
    tenantId: isNaN(possibleTenantId) ? 0 : possibleTenantId,
    modifiedDate: ApiParsingUtilities.parseDateWithTimeStamp(
      draftModel.modifiedOn || defaultState.createState.modifiedDate
    ),
    poster: ApiParsingUtilities.addDefault(draftModel.jeDetailsInfo.poster || defaultState.createState.poster),
    createdDate: ApiParsingUtilities.parseDate(draftModel.createdOn || defaultState.createState.createdDate),
    lineAmountFormat: defaultState.createState.lineAmountFormat,
    jeDetails: {
      refNumber: draftModel.jeDetailsInfo.refNumber
        ? `${draftModel.jeDetailsInfo.refNumber.slice(0, 6) || defaultState.createState.jeDetails.refNumber}`
        : defaultState.createState.jeDetails.refNumber,
      companyCode:
        draftModel.jeDetailsInfo.companyCode !== "0"
          ? `${draftModel.jeDetailsInfo.companyCode}` || defaultState.createState.jeDetails.companyCode
          : defaultState.createState.jeDetails.companyCode,
      jeType: draftModel.jeDetailsInfo.jeType || defaultState.createState.jeDetails.jeType,
      reasonCode: draftModel.jeDetailsInfo.reasonCode || defaultState.createState.jeDetails.reasonCode,
      currency: draftModel.jeDetailsInfo.currency || defaultState.createState.jeDetails.currency,
      fiscalPeriod,
      postingDate: postingDate,
      translationDate: draftModel.jeDetailsInfo.translationDate || defaultState.createState.jeDetails.translationDate,
      reversalDate: draftModel.jeDetailsInfo.reversalDate || defaultState.createState.jeDetails.reversalDate,
      description: draftModel.jeDetailsInfo.description || defaultState.createState.jeDetails.description,
      jePurpose: draftModel.jeDetailsInfo.jePurpose || defaultState.createState.jeDetails.jePurpose,
      isBpo: draftModel.jeDetailsInfo.isbpoEntry || defaultState.createState.jeDetails.isBpo,
      isLongTerm: draftModel.jeDetailsInfo.isReusable || defaultState.createState.jeDetails.isLongTerm,
      debit: 0,
      credit: 0,
      total: 0,
      debitLC: 0,
      creditLC: 0,
      totalLC: 0,
      debitLC2: 0,
      creditLC2: 0,
      totalLC2: 0,
      debitLC3: 0,
      creditLC3: 0,
      totalLC3: 0,
      checklistRefGuid:
        draftModel.jeDetailsInfo.fcw && draftModel.jeDetailsInfo.fcw.checklistGuid
          ? draftModel.jeDetailsInfo.fcw.checklistGuid
          : defaultState.createState.jeDetails.checklistRefGuid,
      checklistName:
        draftModel.jeDetailsInfo.fcw && draftModel.jeDetailsInfo.fcw.checklistName
          ? draftModel.jeDetailsInfo.fcw.checklistName
          : defaultState.createState.jeDetails.checklistName,
      actualTimeTakenMins:
        draftModel.jeDetailsInfo.actualTimeTakenMins || defaultState.createState.jeDetails.actualTimeTakenMins,
      isAdhocDraft: draftModel.jeDetailsInfo.fcw?.isAdhocDraft || defaultState.createState.jeDetails?.isAdhocDraft,
      checkListAuthors:
        draftModel.jeDetailsInfo.checkListAuthors || defaultState.createState.jeDetails.checkListAuthors,
      postedBy: draftModel.jeDetailsInfo.postedBy || defaultState.createState.jeDetails.postedBy,
      procStatusAlias: draftModel.jeDetailsInfo.procStatusAlias || defaultState.createState.jeDetails.procStatusAlias,
      sequenceNo: draftModel.jeDetailsInfo.sequenceNo || defaultState.createState.jeDetails.sequenceNo,
      tenantId: draftModel.jeDetailsInfo.tenantId || defaultState.createState.jeDetails.tenantId,
      templateId: draftModel.jeDetailsInfo.templateId || defaultState.createState.jeDetails.templateId,
      uiAction: defaultState.createState.jeDetails.uiAction,
      frequency: draftModel.jeDetailsInfo.frequency || defaultState.createState.jeDetails.frequency,
      scheduleName: draftModel.jeDetailsInfo.scheduleName || defaultState.createState.jeDetails.scheduleName,
      recurringJEScheduleId:
        draftModel.jeDetailsInfo.recurringJEScheduleId || defaultState.createState.jeDetails.recurringJEScheduleId,
      scheduleStartDate: draftModel.jeDetailsInfo.scheduleStartDate
        ? new Date(draftModel.jeDetailsInfo.scheduleStartDate)
        : defaultState.createState.jeDetails.scheduleStartDate
        ? new Date(defaultState.createState.jeDetails.scheduleStartDate)
        : null,
      scheduleEndDate: draftModel.jeDetailsInfo.scheduleEndDate
        ? new Date(draftModel.jeDetailsInfo.scheduleEndDate)
        : defaultState.createState.jeDetails.scheduleEndDate
        ? new Date(defaultState.createState.jeDetails.scheduleEndDate)
        : null,
      nextPostingDate: draftModel.jeDetailsInfo.nextPostingDate
        ? new Date(draftModel.jeDetailsInfo.nextPostingDate)
        : defaultState.createState.jeDetails.nextPostingDate
        ? new Date(defaultState.createState.jeDetails.nextPostingDate)
        : null
    },
    attachments: attachments,
    attachments_region: region !== null ? region.id : defaultState.createState.attachments_region,
    lock_region: attachments.length > 0 ? lockRegion : false,
    posters: {
      poster: draftModel.jeDetailsInfo.poster || defaultState.createState.posters.poster,
      backupPosters: backupPosters,
      comment: draftModel.jeDetailsInfo.lastComments || defaultState.createState.posters.comment,
      table: concatGLComments(draftModel)
    },
    reviewers: {
      reviewer: primaryReviewer,
      additionalReviewers,
      preReviewers: preReviewers
    },

    errorTable,
    jeHeaderError: jeError.jeHeaderError ? jeError.jeHeaderError.split(";") : [],
    jeTypes: defaultState.createState.jeTypes,
    reasonCodes: defaultState.createState.reasonCodes,
    currencies: defaultState.createState.currencies,
    fysfps: defaultState.createState.fysfps,
    userChecklists: defaultState.createState.userChecklists,
    rowWeightage: !isNaN(draftModel.rowWeightage) ? draftModel.rowWeightage : defaultState.createState.rowWeightage,
    isExternalDraft: draftModel.isExternalDraft || defaultState.createState.isExternalDraft
  };

  return {
    createState,
    lineItems,
    pageConfiguration
  };
}
function concatGLComments(draftModel: GLDraftObjectFromAPI): GLCommentsModel[] {
  let comments: GLCommentsModel[] = [];
  comments = comments.concat(processXmlComments("Poster", draftModel.posterComments));
  comments = comments.concat(processXmlComments("Reviewer", draftModel.reviewerComments));
  return comments;
}
export function singleSupportingDocumentInformationToGLAttachment(
  sdInfo: SupportingDocumentDataInfo,
  index: number,
  region: {
    id: number;
    name: string;
  }
): GLAttachment | null {
  if (sdInfo.name === null || sdInfo.name === "") return null;
  if (sdInfo.documentSize === null) return null;
  if (sdInfo.uploadedDate === null || sdInfo.uploadedDate === "") return null;
  if ((sdInfo.url === null || sdInfo.url === "") && (sdInfo.documentURL === null || sdInfo.documentURL === ""))
    return null;

  const theUrl = sdInfo.url || sdInfo.documentURL || "";
  const urlPieces = theUrl.split("/");
  if (urlPieces.length < 2) return null;

  const blobUriPath = `${urlPieces[0]}/${urlPieces[1]}`;

  const date = !isNaN(Date.parse(sdInfo.uploadedDate))
    ? new Date(Date.parse(sdInfo.uploadedDate)).toLocaleString("en-US")
    : sdInfo.uploadedDate;
  const attachmentForUI: GLAttachment = {
    id: sdInfo.fileID ? Number(sdInfo.fileID) : index,
    blobName: sdInfo.blobName || "",
    fileName: sdInfo.name,
    url: theUrl,
    fileSize: sdInfo.documentSize,
    documentType: "",
    uploadedOn: date,
    region: region,
    isRescindEvidence: false,
    blobUriPath: blobUriPath
  };
  return attachmentForUI;
}

export function supportingDocumentInformationToGLAttachment(
  sdInfos: SupportingDocumentDataInfo[] | null,
  region: {
    id: number;
    name: string;
  }
): GLAttachment[] {
  const attachments = !sdInfos
    ? []
    : sdInfos.reduce((attList, att, index) => {
        const newAttachment = singleSupportingDocumentInformationToGLAttachment(att, index, region);
        if (!newAttachment) {
          return attList;
        }
        attList.push(newAttachment);
        return attList;
      }, [] as GLAttachment[]);
  return attachments;
}

export function getRegionDetails(sdData?: AttachmentInformation | null) {
  let attachmentRegionId: number | null = null;
  let attachmentRegionName: string | null = null;
  let lockRegion = false;
  let region: {
    id: number;
    name: string;
  } | null = null;
  if (
    sdData &&
    sdData.selectedStorage.regionKey !== 0 &&
    sdData.selectedStorage.regionName &&
    !isNaN(Number(sdData.selectedStorage.regionKey))
  ) {
    attachmentRegionId = Number(sdData.selectedStorage.regionKey);
    attachmentRegionName = sdData.selectedStorage.regionName;
    lockRegion = true;
    region = {
      id: attachmentRegionId,
      name: attachmentRegionName
    };
  }
  return {
    lockRegion,
    region
  };
}

export function jeDraftLineItemsToModelLineItems(
  jeDraftLineItems: JeDraftLineItem[],
  jeLineItemErrors: JeLineItemErrors[],
  pageConfiguration: GLPageState["pageConfiguration"]
): [LineItemModel[], ErrorListModel[]] {
  if (!jeDraftLineItems) {
    return [[], []];
  }

  const lineItems: LineItemModel[] = [];
  const errors: ErrorListModel[] = [];

  for (let rowIndex = 0; rowIndex < jeDraftLineItems.length; rowIndex++) {
    const jeDraftLineItem = jeDraftLineItems[rowIndex];
    const newLineItem = lineItemToModelLineItem(jeDraftLineItem);

    if (!newLineItem) {
      return [lineItems, errors];
    }

    const newErrors: string[] = validateLineItem(pageConfiguration, newLineItem);

    if (newErrors.length > 0) {
      const errorStr = newErrors.join("; ");
      newLineItem.processingStatus = errorStr;
      errors.push({
        rowNumber: rowIndex + 1,
        description: errorStr
      });
    } else {
      const errorFromLineItem = jeLineItemErrors
        .filter((item) => item.lineNumber === rowIndex + 1)
        .map((item) => item.description);

      newLineItem.processingStatus =
        errorFromLineItem !== null ? errorFromLineItem.join(";") : newLineItem.processingStatus;
    }

    if (jeLineItemErrors.length > 0) {
      jeLineItemErrors.forEach((x) => {
        errors.push({
          rowNumber: x.lineNumber,
          description: x.description
        });
      });
    }

    lineItems.push(Object.freeze(newLineItem));
  }

  return [lineItems, errors];
}

export function lineItemToModelLineItem(lineItem: JeDraftLineItem): LineItemModel | null {
  // todo: check for invalid line item here (first figure out what is an invalid line item)
  const lineItemForTable: LineItemModel = {
    account: lineItem.account === 0 ? null : lineItem.account,
    costCenter: lineItem.costCenter || "",
    internalOrder: lineItem.internalOrder || "",
    salesOrder: lineItem.salesOrder || "",
    soLineItem: lineItem.soLineItem || "",
    wbsElement: lineItem.wbsElement || "",
    tradingPartner: lineItem.tradingPartner || "",
    profitCenter: lineItem.profitCenter || "",
    lineAmount: lineItem.lineAmount,
    lineAmountInCurrency: lineItem.lineAmountLC,
    lineAmountLC2: lineItem.lineAmountLC2,
    lineAmountLC3: lineItem.lineAmountLC3,
    lineDescription: lineItem.lineDescription || "",
    assignment: lineItem.assignment || "",
    taxCode: lineItem.taxCode || "",
    processingStatus: !lineItem.processingStatus || lineItem.processingStatus === "OK" ? "" : lineItem.processingStatus,
    lineNum: lineItem.lineNum,
    jEid: lineItem.jEid,
    refGuid: lineItem.lineRefGUID,
    extSourceId: lineItem.extSourceId || 0,
    isExtSourceRow: lineItem.isExtSourceRow || false
  };
  return lineItemForTable;
}

export function getReviewer(
  _reviewer: string | null,
  defaultReviewer: string
): {
  primaryReviewer: string;
  secondaryReviewer: string | null;
} {
  if (!_reviewer)
    return {
      primaryReviewer: defaultReviewer,
      secondaryReviewer: null
    };
  if (!defaultReviewer)
    return {
      primaryReviewer: _reviewer,
      secondaryReviewer: null
    };

  const normalizedReviewer = _reviewer ? _reviewer.trim().toLowerCase() : "";
  const normalizedDefaultReviewer = defaultReviewer.trim().toLowerCase();

  if (normalizedReviewer !== normalizedDefaultReviewer)
    return {
      primaryReviewer: defaultReviewer,
      secondaryReviewer: _reviewer
    };

  return {
    primaryReviewer: defaultReviewer,
    secondaryReviewer: null
  };
}

function checklistHandling(initialState: GLCreateState) {
  if (!initialState.jeDetails.checklistRefGuid) {
    return {
      selectedChecklist: EMPTY_CHECKLIST,
      userChecklists: initialState.userChecklists
    };
  }

  const currentChecklist = initialState.userChecklists.find(
    (checklist) => checklist.checklistRefGuid === initialState.jeDetails.checklistRefGuid
  );
  if (currentChecklist) {
    return {
      userChecklists: initialState.userChecklists,
      selectedChecklist: currentChecklist
    };
  }

  const forDropdown: FCWChecklistModel = {
    checklistName: initialState.jeDetails.checklistName || "",
    checklistId: 0,
    checklistRefGuid: initialState.jeDetails.checklistRefGuid,
    postingPeriod: "",
    opsDetailsName: "",
    backupReviewers: "",
    backupPosters: ""
  };
  const addToChecklist = [forDropdown, ...initialState.userChecklists];

  return {
    selectedChecklist: forDropdown,
    userChecklists: addToChecklist
  };
}

function getHeaderErrors(initialState, previousActionAndResult) {
  // PREVIOUS ACTION ALWAYS GOES FIRST
  if (previousActionAndResult && previousActionAndResult.genericHeaderErrors) {
    return previousActionAndResult.genericHeaderErrors;
  } else if (initialState.createState.jeHeaderError) {
    return initialState.createState.jeHeaderError;
  } else {
    return [];
  }
}

function translateCalculateLineAmountsResult(result: ReturnType<typeof calculateLineAmounts>, precision: number) {
  return {
    detailsTabDebit: ApiParsingUtilities.toDecimal(result.auditDebitInfo, precision),
    detailsTabCredit: ApiParsingUtilities.toDecimal(result.auditCreditInfo, precision),
    detailsTabTotal: ApiParsingUtilities.toDecimal(result.total, precision),
    detailsTabDebitLC: ApiParsingUtilities.toDecimal(result.auditDebitLCInfo, precision),
    detailsTabCreditLC: ApiParsingUtilities.toDecimal(result.auditCreditLCInfo, precision),
    detailsTabTotalLC: ApiParsingUtilities.toDecimal(result.totalLC, precision),
    detailsTabDebitLC2: ApiParsingUtilities.toDecimal(result.auditDebitLC2Info, 2),
    detailsTabCreditLC2: ApiParsingUtilities.toDecimal(result.auditCreditLC2Info, 2),
    detailsTabTotalLC2: ApiParsingUtilities.toDecimal(result.totalLC2, 2),
    detailsTabDebitLC3: ApiParsingUtilities.toDecimal(result.auditDebitLC3Info, 2),
    detailsTabCreditLC3: ApiParsingUtilities.toDecimal(result.auditCreditLC3Info, 2),
    detailsTabTotalLC3: ApiParsingUtilities.toDecimal(result.totalLC3, 2)
  };
}

export function createStateToFormikState(
  domainData: Pick<
    DomainDataObjects,
    DomainDataEnum.FiscalPeriods | DomainDataEnum.CurrencyCodes | DomainDataEnum.JeCompanyCodes
  >,
  initialState: GLPageState,
  previousActionAndResult: GLActionResult | null
): GLCreateFormikState {
  const { selectedChecklist, userChecklists } = checklistHandling(initialState.createState);
  const genericHeaderErrors = getHeaderErrors(initialState, previousActionAndResult);

  // adjust line item errors to be linear
  const lineItemErrors =
    previousActionAndResult && previousActionAndResult.lineItemsErrors
      ? previousActionAndResult.lineItemsErrors.map((x) => ({
          rowNumber: x.filteredRowNumber && x.filteredRowNumber >= 0 ? x.filteredRowNumber : x.rowNumber,
          description: x.description
        }))
      : initialState.lineItems
          .map((x, i) => ({ rowNumber: i + 1, description: x.processingStatus }))
          .filter((x) => x.description);

  const columnTypes =
    initialState.pageConfiguration.pageType === CreatePageType.FourCurrency
      ? LineAmountOptions.FourCurrency
      : LineAmountOptions.Both;
  const precision = LineAmountFormat.GetLineAmountFormat(domainData, initialState.createState.jeDetails.currency || "");
  const lineAmounts: ReturnType<typeof translateCalculateLineAmountsResult> =
    previousActionAndResult === null
      ? {
          detailsTabDebit: `${initialState.createState.jeDetails.debit}` || "0",
          detailsTabCredit: `${initialState.createState.jeDetails.credit}` || "0",
          detailsTabTotal: `${initialState.createState.jeDetails.total}` || "0",
          detailsTabDebitLC: `${initialState.createState.jeDetails.debitLC}` || "0",
          detailsTabCreditLC: `${initialState.createState.jeDetails.creditLC}` || "0",
          detailsTabTotalLC: `${initialState.createState.jeDetails.totalLC}` || "0",
          detailsTabDebitLC2: `${initialState.createState.jeDetails.debitLC2}` || "0",
          detailsTabCreditLC2: `${initialState.createState.jeDetails.creditLC2}` || "0",
          detailsTabTotalLC2: `${initialState.createState.jeDetails.totalLC2}` || "0",
          detailsTabDebitLC3: `${initialState.createState.jeDetails.debitLC3}` || "0",
          detailsTabCreditLC3: `${initialState.createState.jeDetails.creditLC3}` || "0",
          detailsTabTotalLC3: `${initialState.createState.jeDetails.totalLC3}` || "0"
        }
      : translateCalculateLineAmountsResult(
          calculateLineAmounts([initialState.lineItems, []], columnTypes, { precision }),
          precision
        );

  const formikValues: GLCreateFormikState = {
    lineAmountFormat: initialState.createState.jeDetails.currency
      ? LineAmountFormat.GetLineAmountFormat(domainData, initialState.createState.jeDetails.currency)
      : initialState.createState.lineAmountFormat,

    headerJeName: initialState.createState.jeName,

    attachments_count: initialState.createState.attachments.length,
    attachments_error: "",
    attachments_region: `${initialState.createState.attachments_region}`,

    detailsTabRefNumber: initialState.createState.jeDetails.refNumber || "",
    detailsTabCompanyCode: initialState.createState.jeDetails.companyCode || "",
    detailsTabJeType: initialState.createState.jeDetails.jeType || "",
    detailsTabReasonCode: initialState.createState.jeDetails.reasonCode || "",
    detailsTabCurrency: initialState.createState.jeDetails.currency || "",
    detailsTabCurrency1:
      getCurrencyCode(initialState.createState.jeDetails.companyCode, domainData.JeCompanyCodes) || "",
    detailsTabFiscalYearPeriod: initialState.createState.jeDetails.fiscalPeriod.fiscalPeriod,
    detailsTabPostingDate: new Date(initialState.createState.jeDetails.postingDate),
    detailsTabTranslationDate: new Date(initialState.createState.jeDetails.translationDate),
    detailsTabReversalDate: new Date(initialState.createState.jeDetails.reversalDate),
    detailsTabDescription: initialState.createState.jeDetails.description || "",
    detailsTabJePurpose: initialState.createState.jeDetails.jePurpose || "",
    detailsTabIsBpo: initialState.createState.jeDetails.isBpo,
    detailsTabIsLongTerm: initialState.createState.jeDetails.isLongTerm,

    ...lineAmounts,

    detailsTabSelectedChecklist: selectedChecklist,
    detailsTabCurrentChecklists: userChecklists,
    detailsTabActualTimeTaken: initialState.createState.jeDetails.actualTimeTakenMins || 0,

    postersTabPoster: initialState.createState.posters.poster || "",
    postersTabBackupPosters: initialState.createState.posters.backupPosters,
    postersTabComments: initialState.createState.posters.comment || "",
    reviewersTabReviewer: initialState.createState.reviewers.reviewer || "",
    reviewersTabAdditionalReviewers: initialState.createState.reviewers.additionalReviewers,
    reviewersTabPreReviewers: initialState.createState.reviewers.preReviewers,

    errorTabErrorTable: lineItemErrors,

    genericHeaderErrors: genericHeaderErrors,

    recurringDetailsTabFrequency: initialState.createState.jeDetails.frequency,
    recurringDetailsTabStartDate: initialState.createState.jeDetails.scheduleStartDate
      ? new Date(initialState.createState.jeDetails.scheduleStartDate)
      : null,
    recurringDetailsTabNextPostingDate: initialState.createState.jeDetails.nextPostingDate
      ? new Date(initialState.createState.jeDetails.nextPostingDate)
      : new Date(initialState.createState.jeDetails.postingDate),
    recurringDetailsTabEndDate: initialState.createState.jeDetails.scheduleEndDate
      ? new Date(initialState.createState.jeDetails.scheduleEndDate)
      : null,
    recurringDetailsTabScheduleName: initialState.createState.jeDetails.scheduleName,
    recurringDetailsTabIsLongTerm: initialState.createState.jeDetails.isLongTerm,
    recurringDetailsTabRJEScheduleId: initialState.createState.jeDetails.recurringJEScheduleId
  };
  return formikValues;
}

interface LocalFp extends FiscalPeriod {
  index: number;
}

export function getFiscalPeriodFromDomainData(
  fiscalMonth: number,
  fiscalYear: number,
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>
) {
  let fp: LocalFp | null = null;
  for (const [i, fiscalPeriod] of domainData.FiscalPeriods.entries()) {
    if (fiscalPeriod.fiscalMonth === fiscalMonth && fiscalPeriod.fiscalYear === fiscalYear) {
      fp = Object.assign(fiscalPeriod, { index: i });
    }
  }
  return fp;
}

export function correlateAndGetFiscalMonthAndFiscalYearWithDomainData(
  fiscalMonth: number,
  fiscalYear: number,
  fiscalPeriod: string,
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>
): FiscalPeriodModel {
  const fp = getFiscalPeriodFromDomainData(fiscalMonth, fiscalYear, domainData);
  // there's no fp in the domain data
  if (fp === null) {
    const { calendarMonth, calendarYear } = calculateCalendarMonthAndYear(fiscalMonth, fiscalYear);
    return {
      calendarMonth: calendarMonth,
      calendarYear: calendarYear,
      fiscalMonth: fiscalMonth,
      fiscalYear: fiscalYear,
      fiscalPeriod: fiscalPeriod,
      endDate: new Date(calendarYear, calendarMonth + 1, 0),
      startDate: new Date(calendarYear, calendarMonth, 1),
      index: null
    };
  }

  return {
    calendarMonth: fp.calendarMonth,
    calendarYear: fp.calendarYear,
    fiscalMonth: fiscalMonth,
    fiscalYear: fiscalYear,
    fiscalPeriod: fiscalPeriod,
    endDate: new Date(fp.endDate),
    startDate: new Date(fp.startDate),
    index: fp.index
  };
}

function getFiscalPeriodFromDetails(
  jeDetailsInfo: JeDetailsInfo,
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>
): FiscalPeriodModel | null {
  // find the fiscal period from the domain data
  if (!jeDetailsInfo.fiscalPeriod) return null;
  const { fiscalMonth, fiscalYear } = FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
    jeDetailsInfo.fiscalPeriod
  );
  if (!fiscalMonth || !fiscalYear) return null;

  return correlateAndGetFiscalMonthAndFiscalYearWithDomainData(
    jeDetailsInfo.fiscalMonth,
    jeDetailsInfo.fiscalYear,
    jeDetailsInfo.fiscalPeriod,
    domainData
  );
}

import { css } from "@emotion/css";
import {
  ActionButton,
  IIconProps,
  ISearchBoxStyles,
  ITheme,
  Pivot,
  PivotItem,
  PivotLinkSize,
  SearchBox,
  useTheme
} from "@fluentui/react";
import { OrderedMap } from "immutable";
import React, { PropsWithChildren, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { ObjectKeys } from "../../utilities/TypeUtils";
import { debounce, LoadingStatus } from "../../utilities/Utilities";
import { DashboardFilterBar, Filters, IDashboardFilterBarRef } from "../DashboardFilterBar/DashboardFilterBar";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { FilterState, IDashboardTilesProps, IIndexedTile, IPivotItem } from "./DashboardFilter.types";
import {
  assembleFilterBar,
  convertGridItemsToFilters,
  getCurrentTile,
  IInternalFilterState,
  mergeFilterStateWithLocationFilterState
} from "./DashboardFilter.utilities";

const isEqual = require("lodash/isEqual");

const styles = (theme: ITheme) => css`
  .tile-styles .ms-Button {
    width: 125px;
    height: 100px;
    margin: 16px 15px 0 0;
    background-color: ${theme.palette.neutralLighterAlt};
    box-shadow: 0px 1.6px 3.6px rgba(0, 0, 0, 0.132), 0px 0.3px 0.9px rgba(0, 0, 0, 0.108);

    &:hover {
      box-shadow: 0px 2.6px 5.6px rgba(0, 0, 0, 0.132), 0px 1.3px 1.9px rgba(0, 0, 0, 0.108);
      background-color: ${theme.palette.neutralLighter};
    }
  }

  .tile-styles {
    overflow: auto;
    height: 140px;
    padding-left: 2px;
  }

  .tile-styles .ms-Button .ms-Pivot-linkContent {
    position: relative;
    width: 100%;
    height: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin: 0;
  }

  .tile-styles .ms-Button-flexContainer {
    align-items: flex-start;
  }

  .tile-styles .ms-Button .ms-Pivot-count {
    flex-grow: 2;
    text-align: left;
    font-size: 38px;
    line-height: 38px;
    color: ${theme.palette.themePrimary};

    &:hover {
      font-size: 38px;
      line-height: 38px;
      color: ${theme.palette.themeDarkAlt};
    }
  }

  .tile-styles .ms-Button .ms-Pivot-text {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.palette.neutralDark};
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;

    &:hover {
      font-size: 14px;
      line-height: 20px;
      color: ${theme.palette.neutralDark};
    }
  }
`;

const initialTileState = (currentTile: string | null, searchCriteria?: string | null): IInternalFilterState => ({
  tilesForPivot: [],
  currentTile: currentTile,
  searchCriteria: searchCriteria ? searchCriteria : null,
  tiles: OrderedMap<string, IIndexedTile>({} as any),
  control: 0
});

/**
 * @description - DashboardFilter component.
 * There's two ways to use this component:
 *   1. Use the tileRef to refreshTiles and Filters, read new Filters using onFilterChanged
 *   2. Use the tilesAndFilters prop to pass in the tiles and filters, and read new Filters using onFilterChanged
 * These two ways are mutually exclusive.
 * @param props - IDashboardTilesProps<ListType>
 * @returns DashboardFilter
 */
const DashboardFilter = <ListType extends { [key: string]: any }>(
  props: PropsWithChildren<IDashboardTilesProps<ListType>>
) => {
  const [tileState, setTileState] = useState<IInternalFilterState>(
    initialTileState(props.tilesAndFilters?.currentTile || null, props.tilesAndFilters?.searchCriteria || null)
  );
  const [isFilterCommandBarClosed, setFilterCommandBarHidden] = useState<boolean>(true);
  const filterBarRef = useRef<IDashboardFilterBarRef>(null);
  const theme = useTheme();
  const [searchBoxKey, setSearchBoxKey] = useState<number>(1);

  useEffect(() => {
    if (
      props.tilesAndFilters &&
      (props.loadingStatus === LoadingStatus.Resolved || props.loadingStatus === LoadingStatus.Rejected)
    ) {
      if (filterBarRef.current) {
        const filterCriteria = filterBarRef.current.getFilterCriteria();

        const currentTileName = getCurrentTile(tileState, filterCriteria);

        /* To update the tile state we make sure the user (developer, or user, both count as the same)
         * changed the current tile and the OrderedMap of tiles by checking it with the current state.
         */
        if (
          !props.tilesAndFilters.tiles.equals(tileState.tiles) ||
          (props.tilesAndFilters.currentTile !== undefined &&
            props.tilesAndFilters.currentTile !== null &&
            props.tilesAndFilters.currentTile !== "" &&
            props.tilesAndFilters.currentTile !== currentTileName)
        ) {
          let currentTile = "";
          const tilesForPivot: IPivotItem[] = props.tilesAndFilters.tiles.toArray().map(([key, indexedTile], i) => {
            if (props.tilesAndFilters.currentTile === key) {
              currentTile = `${i}`;
            }
            return { name: key, count: indexedTile.tileCount || 0 };
          });
          setTileState({
            tilesForPivot: tilesForPivot,
            currentTile: currentTile,
            searchCriteria: null,
            tiles: props.tilesAndFilters.tiles,
            control: 0
          });
        }

        /* To update the filter bar we recreate it based on the grid items.
         * We grab that generated filter bar and compare it to the current filter bar.
         * If they're different we update the filter bar.
         */
        const filterBar = assembleFilterBar(props.tilesAndFilters.gridItems, props.filters, props.localStorageKey);
        const onlyAvailableFilters = (f: Filters) =>
          ObjectKeys(f).reduce((acc, filterKey) => {
            if (filterKey.toString().indexOf("Available") !== -1) {
              acc[filterKey] = f[filterKey];
            }
            return acc;
          }, {} as Filters);
        const newAvailableFilters = onlyAvailableFilters(filterBar);
        const existingAvailableFilters = onlyAvailableFilters(filterCriteria);
        if (!isEqual(newAvailableFilters, existingAvailableFilters)) {
          filterBarRef.current.updateFilter(filterBar);
        }
      }
    }
  }, [props.tilesAndFilters]);

  useImperativeHandle(props.customRef, () => ({
    refreshTilesAndFilters(items, optionalColumnNameForTiles) {
      const tilesConfiguration = convertGridItemsToFilters(items, optionalColumnNameForTiles || "jeStatus");
      setTileState({
        tilesForPivot: tilesConfiguration.tilesForPivot,
        currentTile: tilesConfiguration.currentTile,
        searchCriteria: null,
        tiles: tilesConfiguration.tiles,
        control: 0
      });
      const filterBar = assembleFilterBar(items, props.filters);
      filterBarRef.current?.updateFilter(filterBar);
    }
  }));

  useEffect(() => {
    if (props.loadingStatus === LoadingStatus.Pending) {
      setFilterCommandBarHidden(true);
    }
  }, [props.loadingStatus]);

  useLayoutEffect(() => {
    if (!isEqual(initialTileState, tileState) && filterBarRef.current) {
      const filterCriteria = filterBarRef.current.getFilterCriteria();
      const currentTileName = getCurrentTile(tileState, filterCriteria);
      const newFilterState = new FilterState({
        currentTile: currentTileName,
        searchCriteria: tileState.searchCriteria,
        filterBar: filterCriteria
      });

      const { state, locationCleared } = props.location
        ? mergeFilterStateWithLocationFilterState(props.location, newFilterState)
        : { state: newFilterState, locationCleared: false };
      if (locationCleared) {
        const getTileIndex = (cTile: string): string | null => {
          const tileObj = tileState.tiles.get(cTile);
          if (tileObj) {
            const newCurrentTile = `${tileObj.tileIndex}`;
            return newCurrentTile;
          }
          return null;
        };
        const newTileIndex = state.currentTile ? getTileIndex(state.currentTile) : null;
        setTileState((p) => ({
          ...p,
          currentTile: newTileIndex,
          searchCriteria: state.searchCriteria
        }));
        setSearchBoxKey(searchBoxKey + 1);
        filterBarRef.current?.updateFilter(state.filterBar);
      } else {
        props.onFilterChanged(state);
      }
    }
  }, [tileState]);

  const handleOnStateChanged = (name: string, newStatus: string | null) => {
    if (tileState.tiles.size !== 0 && name.indexOf("status") !== -1) {
      if (!newStatus) {
        setTileState((p) => ({
          ...p,
          currentTile: null
        }));
      } else {
        const tileObj = tileState.tiles.get(newStatus);
        if (tileObj) {
          const newTile = `${tileObj.tileIndex}`;
          setTileState((p) => ({
            ...p,
            currentTile: newTile
          }));
        }
      }
    } else {
      setTileState((p) => ({ ...p, control: p.control + 1 }));
      setSearchBoxKey(searchBoxKey + 1);
    }
  };

  const handleClick = (item?: PivotItem) => {
    if (item !== undefined) {
      const tileName = item.props.headerText as string;
      // todo: add filter criteria
      //history.replace("/sldashboard", { tileName });
      const tileObj = tileState.tiles.get(tileName);
      let newTile: null | string = tileName;
      if (tileObj) {
        setTileState((p) => {
          const newCurrentTile = `${tileObj.tileIndex}`;
          let currentTile: null | string = newCurrentTile;

          if (p.currentTile === newCurrentTile) {
            newTile = null;
            currentTile = null;
          }

          if (props.tilesAndFilters && !currentTile) {
            return p;
          }
          const newState = {
            ...p,
            currentTile
          };
          return newState;
        });
      }
      filterBarRef.current?.updateTile(newTile);
    }
  };

  const onChangeForSearchBox = debounce(
    (_, newValue) =>
      setTileState((p) => ({
        ...p,
        searchCriteria: newValue || null
      })),
    300
  );

  const onClearForSearchBox = debounce(() => {
    setTileState((p) => ({
      ...p,
      searchCriteria: null
    }));
  }, 300);

  const onSearch = debounce(
    (newValue) =>
      setTileState((p) => ({
        ...p,
        searchCriteria: newValue || null
      })),
    300
  );

  return (
    <React.Fragment>
      <div
        className={css`
          position: relative;
        `}
        data-testid="dashboard-filter"
      >
        {props.loadingStatus === LoadingStatus.Pending ? <LoadingSpinner label="Loading filters" /> : null}

        <div
          className={css`
            display: flex;
            flex-direction: column;
            visibility: ${props.loadingStatus === LoadingStatus.Resolved ? "visible" : "hidden"};
            ${props.loadingStatus === LoadingStatus.Resolved ? "" : "height: 30px;"}
          `}
        >
          {tileState.tilesForPivot.length > 0 ? (
            <div className={styles(theme)}>
              <Pivot
                selectedKey={tileState.currentTile}
                linkSize={PivotLinkSize.large}
                //
                onLinkClick={handleClick}
                className="tile-styles"
                {...(props.loadingStatus === LoadingStatus.Resolved
                  ? { "data-testid": "dashboard-filter-tiles" }
                  : { "data-testid": undefined })}
              >
                {tileState.tilesForPivot.map((value, i) => (
                  <PivotItem
                    headerText={value.name}
                    key={`${i}`}
                    itemCount={value.count}
                    onRenderItemLink={(link) => {
                      if (link) {
                        const { headerText, itemCount } = link;
                        return (
                          <span className="ms-Pivot-linkContent">
                            <span className="ms-Pivot-count">{itemCount}</span>
                            <span className="ms-Pivot-text">{headerText}</span>
                          </span>
                        );
                      } else {
                        return null;
                      }
                    }}
                  />
                ))}
              </Pivot>
            </div>
          ) : null}
          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;

              & > button {
                padding: 0 0 0 15px;
                &.is-checked > span {
                  background-color: ${theme.palette.neutralLighter};
                }
              }
            `}
          >
            <SearchBox
              key={`${searchBoxKey}`}
              styles={{ root: { width: 267 } } as Partial<ISearchBoxStyles>}
              placeholder="Search"
              onClear={onClearForSearchBox}
              onChange={onChangeForSearchBox}
              onSearch={onSearch}
              defaultValue={tileState.searchCriteria === null ? undefined : tileState.searchCriteria}
            />
            <ActionButton
              iconProps={{ iconName: "Filter" } as IIconProps}
              allowDisabledFocus
              disabled={false}
              checked={!isFilterCommandBarClosed}
              onClick={() => {
                setFilterCommandBarHidden(!isFilterCommandBarClosed);
              }}
              ariaLabel="Button to Open Grid Column Filters"
              data-testid="dashboard-filter-open-button"
            >
              Filter
            </ActionButton>
          </div>
          <DashboardFilterBar
            filters={props.filters.map((x) => x.filterName)}
            singleSelectionFilters={props.singleSelectionFilters}
            searchEnabledFilters={props.searchEnabledFilters}
            ref={filterBarRef}
            hidden={isFilterCommandBarClosed}
            onStateChange={handleOnStateChanged}
            savedFilters={props.savedFilters}
          ></DashboardFilterBar>
        </div>
      </div>
    </React.Fragment>
  );
};

DashboardFilter.displayName = "DashboardFilter";

export { DashboardFilter };

import { MessageBarType } from "@fluentui/react";
import { IUserProviderState, DomainDataObjects, DomainDataEnum, LocalMessage } from "../../../../Shared";
import { AdditionalData, GLAttachmentsRef } from "../../../shared";
import { CheckForErrorsInTabs } from "../../../validation";
import { GLPageState } from "../GLCreate.State";
import { GLCreateFormProps } from "../GLCreateForm";
import { ErrorListModel, GLCreateFormikState } from "../GLCreateForm.types";
import { GLCreateTableRef, DiffLineItems, GetAllLineItems } from "../GLCreateLineItems";
import { GLCreateActionsEnum } from "../useWeightage";
import { CreateActionOnSubmitHandlerMap } from "./GLCreateActions.utils";

export type UIActionDelegationOptions = {
  userAction: GLCreateActionsEnum;
  actionOptions?: AdditionalData;
  actions: CreateActionOnSubmitHandlerMap;
  userChanges: GLCreateFormikState;
  lineItemsRef: GLCreateTableRef;
  attachmentsRef: GLAttachmentsRef;
  initialState: GLPageState;
  configuration: GLCreateFormProps["configuration"];
  userContext: Pick<IUserProviderState, "accessToken" | "user">;
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeCompanyCodes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.StatusActions
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.JeParameters
  >;
};

export type GLActionResult = {
  action: GLCreateActionsEnum;
  message: LocalMessage;
  lineItemsErrors?: ErrorListModel[];
  genericHeaderErrors?: string[];
  reponsePageState?: GLPageState;
  blockPage: boolean;
};

export async function UIActionDelegation(opts: UIActionDelegationOptions): Promise<GLActionResult> {
  const {
    userAction,
    actionOptions,
    userChanges,
    lineItemsRef,
    attachmentsRef,
    initialState,
    configuration,
    userContext,
    domainData,
    actions
  } = opts;
  // Deal with the Attachments first, and block any actions
  // if there's any issues with them
  const [changes, attachmentErrors] = await attachmentsRef.saveAndGetAttachments();
  if (attachmentErrors) {
    return {
      action: userAction,
      message: {
        message: "There were errors in the Attachments Tab. Please correct them and try again.",
        type: MessageBarType.error
      },
      blockPage: false
    };
  }

  let [newLineItems, lineItemsErrors] = await lineItemsRef.getData({
    fromAction: true
  });
  if (lineItemsErrors) {
    lineItemsErrors = lineItemsErrors.filter((error) => !error.description.startsWith("Warning"));
  }
  const errors = await CheckForErrorsInTabs({
    configuration: configuration.GeneralLedgerApi,
    userContext,
    domainData: domainData,
    pageConfiguration: initialState.pageConfiguration,
    formikState: userChanges,
    userAction,
    initialState: initialState.createState
  });

  if (userAction === GLCreateActionsEnum.Clear) {
    const allLineItems = GetAllLineItems(newLineItems);
    // Clear just saves a blank JE
    // allow the user to continue regardless of any errors
    const result = await actions[GLCreateActionsEnum.Clear]({
      formikState: userChanges,
      diffedLineItems: allLineItems,
      attachmentObjects: changes,
      state: {
        createState: initialState.createState,
        lineItems: initialState.lineItems,
        pageConfiguration: initialState.pageConfiguration
      }
    });
    return {
      action: userAction,
      message: result.message,
      blockPage: result.blockPage
    };
  }

  const diffedLineItems = DiffLineItems(initialState.pageConfiguration, initialState.lineItems, newLineItems);
  // All actions need to save first (even save)
  const saveResult = await actions[GLCreateActionsEnum.Save]({
    formikState: userChanges,
    diffedLineItems: diffedLineItems,
    attachmentObjects: changes,
    state: {
      createState: initialState.createState,
      lineItems: initialState.lineItems,
      pageConfiguration: initialState.pageConfiguration
    }
  });

  if (saveResult.genericHeaderErrors) {
    return {
      action: GLCreateActionsEnum.Save,
      message: saveResult.message,
      lineItemsErrors,
      blockPage: saveResult.blockPage,
      reponsePageState: saveResult.pageState
    };
  }

  if (userAction === GLCreateActionsEnum.Save) {
    return {
      action: userAction,
      message: saveResult.message,
      lineItemsErrors,
      blockPage: saveResult.blockPage,
      reponsePageState: saveResult.pageState
    };
  } else if (userAction === GLCreateActionsEnum.NoActivity) {
    const actionResult = await actions[userAction as keyof typeof actions](
      {
        formikState: userChanges,
        diffedLineItems: diffedLineItems,
        attachmentObjects: changes,
        state: {
          createState: initialState.createState,
          lineItems: initialState.lineItems,
          pageConfiguration: initialState.pageConfiguration
        }
      },
      actionOptions as AdditionalData
    );
    return {
      action: userAction,
      ...actionResult
    };
  }

  if (newLineItems.length < 2) {
    return {
      action: userAction,
      message: {
        message: "Please add at least 2 line items and try again.",
        type: MessageBarType.error
      },
      genericHeaderErrors: errors,
      lineItemsErrors,
      blockPage: false,
      reponsePageState: saveResult.pageState
    };
  } else if (errors.length !== 0 || lineItemsErrors.length !== 0) {
    const totalErrors = errors.length + lineItemsErrors.length;
    let message = "There are errors in the JE. Please correct them and try again.";
    if (totalErrors === 1) {
      if (errors.length === 1) {
        message = errors[0];
      } else {
        message = lineItemsErrors[0].description;
      }
    }
    return {
      action: userAction,
      message: {
        message,
        type: MessageBarType.error
      },
      genericHeaderErrors: errors,
      lineItemsErrors,
      blockPage: false,
      reponsePageState: saveResult.pageState
    };
  }

  if (
    userAction !== GLCreateActionsEnum.Validate &&
    userAction !== GLCreateActionsEnum.Post &&
    userAction !== GLCreateActionsEnum.PreReview
  ) {
    const actionResult = await actions[userAction as keyof typeof actions]({
      formikState: userChanges,
      diffedLineItems: diffedLineItems,
      attachmentObjects: changes,
      state: {
        createState: initialState.createState,
        lineItems: initialState.lineItems,
        pageConfiguration: initialState.pageConfiguration
      }
    });
    return {
      ...actionResult,
      action: userAction
    };
  }

  const validateResult = await actions[GLCreateActionsEnum.Validate]({
    formikState: userChanges,
    diffedLineItems: diffedLineItems,
    attachmentObjects: changes,
    state: {
      createState: initialState.createState,
      lineItems: initialState.lineItems,
      pageConfiguration: initialState.pageConfiguration
    }
  });

  const rephraseErrorMsg = domainData.JeParameters.filter((item) => item.name === "RephraseSAPErrorMessage").map(
    (item) => item.showUX
  )[0];

  if (validateResult.message.type === MessageBarType.error || userAction === GLCreateActionsEnum.Validate) {
    if (rephraseErrorMsg) {
      if (validateResult.genericHeaderErrors!.length > 0) {
        validateResult.genericHeaderErrors?.forEach((element, index) => {
          if (element.startsWith("Document") && element.endsWith("Update error, transaction FB01")) {
            validateResult.genericHeaderErrors![index] = "Alert - A posting error occurred. Please contact Finsup.";
          }
        });
      }
    }
    return {
      ...validateResult,
      action: userAction,
      reponsePageState: validateResult.pageState
    };
  }
  if (userAction === GLCreateActionsEnum.PreReview) {
    const preReviewResult = await actions[GLCreateActionsEnum.PreReview]({
      formikState: userChanges,
      diffedLineItems: diffedLineItems,
      attachmentObjects: changes,
      state: {
        createState: initialState.createState,
        lineItems: initialState.lineItems,
        pageConfiguration: initialState.pageConfiguration
      }
    });
    return {
      ...preReviewResult,
      action: userAction
    };
  }
  const postResult = await actions[GLCreateActionsEnum.Post]({
    formikState: userChanges,
    diffedLineItems: diffedLineItems,
    attachmentObjects: changes,
    state: {
      createState: initialState.createState,
      lineItems: initialState.lineItems,
      pageConfiguration: initialState.pageConfiguration
    }
  });
  return {
    ...postResult,
    action: userAction
  };
}

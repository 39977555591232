import { GLAttachment } from "../../../Shared/components/Attachments/Attachments.types";
import { GLCommentsModel } from "../../../Shared/utilities/SharedModels";

export type RegionStr = string;

export interface GLJeDetailsState {
  refGuid: string;
  jeId: number;
  rowWeightage: number;
  postingType: number;
  templateId: number;
  sapModule: string;
  header: {
    jeNumber: string;
    author: string;
    poster: string;
    additionalPosters: string[];
    reviewer: string;
    dateModified: string;
    status: string;
    reviewerAction: string;
  };
  detailsTab: {
    documentNumber: string;
    significance: JSX.Element | string;
    fiscalYear: string;
    fiscalPeriod: string;
    description: string;
    refNumber: string;
    reasonCode: string;
    reversalJe: string;
    jePurpose: string;
    companyCode: string;
    isJem: boolean;
    isBpo: boolean;
    planReversalDate: string;
    recurringJe: string;
    recurringJeFiscalYear: string;
    dueDate: string;
    translationDate: string;
    postersOrg: string;
    reviewersOrg: string;
    jeName: string;
    opsDetailName: string;
    fcwChecklistName: string;
    isAdhocOrStd: string;
    jeType: string;
    recurringJeScheduleName: string;
  };
  attachments: GLAttachment[];
  attachments_region: number;
  lock_region: boolean;
  reviewersTab: {
    reviewer: string;
    reviewerAction: string;
    additionalReviewers: string[];
    comments: string;
    clarificationCodes: string[];
    commentItems: GLCommentsModel[];
    preReviewers: string[];
  };
  status: number;
}

export interface GLJeDetailsFormikState {
  detailsTabJePurpose: string;
  detailsTabMapPolicy: boolean;
  detailsTabBulkAcknowledge: boolean;
  attachments_count: number;
  attachments_error: string;
  attachments_region: string;
  postersTabPoster: string;
  postersTabBackupPosters: string[];
  reviewersTabReviewer: string;
  reviewersTabAdditionalReviewers: string[];
  reviewersTabComments: string;
  reviewersTabClarificationCode: number[];
  reviewersTabPreReviewers: string[];
}

export interface GLJeSapDetailsLineItem {
  item: string;
  pos: string;
  account: string;
  accountDescription: string;
  assignmentId: string;
  ccIo: string;
  so: string;
  soli: string;
  tp: string;
  tc: string;
  pc: string;
  transAmt: string;
  transCurr: string;
  statAmount: string;
  statCurr: string;
  gcAmt: string;
  gcCurr: string;
  lineItemDescription: string;
  profitCenterDescription: string;
  localcurramT2: string;
  localcurramT3: string;
  localcurrkeY2: string;
  localcurrkeY3: string;
}

export interface GLJeSapDetailsState {
  header: {
    docNumber: string;
    refNumber: string;
    jeType: string;
    description: string;
    transaction: string;
    companyCode: string;
    totalAmount: string;
    debitAmount: string;
    fiscalYear: string;
    fiscalPeriod: string;
    currency: string;
    postingDate: string;
    creditAmount: string;
    docDate: string;
    timeOfEntry: string;
  };
  lineItems: GLJeSapDetailsLineItem[];
}

export enum JeDetailsTabs {
  jeDetails = "jeDetails",
  attachments = "attachments",
  review = "review",
  otherDetails = "otherDetails"
}

export enum JeModuleTypes {
  FI = "FI",
  ECCS = "ECCS",
  PCA = "PCA"
}

export const JeModuleTypeToNumber = {
  [JeModuleTypes.FI]: 0,
  [JeModuleTypes.ECCS]: 1,
  [JeModuleTypes.PCA]: 2
};

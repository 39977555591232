import { css } from "@emotion/css";
import { SelectionMode, Text } from "@fluentui/react";
import {
  DashboardFilter,
  DashboardGrid,
  exportToExcel,
  FatalError,
  FilterSchema,
  FilterState,
  IDashboardGridRef,
  IIndexedTile,
  JemConfiguration,
  JEMContext,
  LoadingStatus,
  MockDataFn,
  useQuery,
  UserContext
} from "@jem/components";

import { OrderedMap } from "immutable";
import React, { useContext, useEffect, useRef, useState } from "react";
import { getJEMChecklistColumns } from "../../components/JEMChecklistDashboardByOpsDetail/JEMChecklist.Columns";
import { JEMChecklistFilterRows } from "../../components/JEMChecklistDashboardByOpsDetail/JEMChecklistDashboard.filterRows";
import { getOpsDetailsList } from "../../components/JEMChecklistDashboardByOpsDetail/JEMChecklistDashboard.Requests";
import { JEMChecklistDashboardCommandBar } from "../../components/JEMChecklistDashboardByOpsDetail/JEMChecklistDashboardCommandBar";
import JEMChecklistEditPanel from "../../components/JEMChecklistDashboardByOpsDetail/JEMChecklistEditPanel";
import JEMChecklistsOpsDetailSelector, {
  OpsDetailSearchOptions
} from "../../components/JEMChecklistDashboardByOpsDetail/JEMChecklistsOpsDetailSelector";
import { JEMChecklistDashboardRow } from "../../shared/JEMChecklistDashboardRow";
import useLocalStorage, { LocalStorageKeys } from "@jem/components/lib/Shared/hooks/useLocalStorage";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require("lodash/isEqual");

export interface JEMChecklistDashboardByOpsDetailProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  fcwCreateTaskUrl: JemConfiguration["FCWNewJemChecklistLink"];
  searchOptions: OpsDetailSearchOptions | null | undefined;
  onSearchOptionsChanged: (options: OpsDetailSearchOptions) => void;
  mockDashboardDataFn?: MockDataFn<any>;
}
interface IJEMChecklistDashboardPageState {
  editItem: JEMChecklistDashboardRow | null;
}

interface SearchByOpsDetailsKeyOptions {
  fiscalYear: number;
  fiscalMonth: number;
  opsDetailName: string;
  opsDetailId: number;
}

const JEMChecklistDashboardByOpsDetail: React.FC<JEMChecklistDashboardByOpsDetailProps> = (props) => {
  const [pageState, setPageState] = useState<IJEMChecklistDashboardPageState>({ editItem: null });
  const jemContext = useContext(JEMContext);
  const userContext = useContext(UserContext);
  const [searchKey, setSearchKey] = useState<SearchByOpsDetailsKeyOptions | null>(null);

  if (!jemContext) {
    throw new FatalError("Please use a JEMContext Provider.");
  }

  const onEditClick = (editItem: JEMChecklistDashboardRow) => {
    setPageState({
      ...pageState,
      editItem
    });
  };

  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  const {
    error: gridItemsError,
    data: gridItems,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ["byOpsDetail", searchKey],
    queryFn: (queryOptions) => {
      const { queryKey } = queryOptions;
      const [_, theSearchKey] = queryKey as [string, SearchByOpsDetailsKeyOptions];
      if (!theSearchKey) {
        return Promise.resolve([]);
      }
      const { fiscalYear, fiscalMonth, opsDetailName, opsDetailId } = theSearchKey as SearchByOpsDetailsKeyOptions;
      if (!fiscalYear || !fiscalMonth || opsDetailId === undefined || opsDetailName === undefined) {
        return Promise.resolve([]);
      }
      return getOpsDetailsList(props.configuration, userContext.accessToken, fiscalYear, fiscalMonth, opsDetailId);
    },
    enabled: searchKey !== null,
    staleTime: 0
  });

  const status = isLoading || isFetching ? LoadingStatus.Pending : LoadingStatus.Resolved;

  const [filteredItems, setFilteredItems] = useState<JEMChecklistDashboardRow[]>([]);
  const [dashboardCache, setDashboardCache] = useLocalStorage<FilterState>(
    `${LocalStorageKeys.checklistDashboardFilterState}`,
    {} as FilterState
  );
  const [filterState, setFilterState] = useState<FilterState>(dashboardCache ?? ({} as FilterState));
  const dashboardFilters: FilterSchema<JEMChecklistDashboardRow>[] = [
    {
      filterName: "Company Code",
      columnName: "CC"
    },
    {
      filterName: "OpsDetail",
      columnName: "OpsDetail"
    },
    {
      filterName: "Active",
      columnName: "Active"
    }
  ];

  const setCache = () => {
    const { currentTile, searchCriteria, filterBar } = filterState;
    setDashboardCache({ currentTile, searchCriteria, filterBar });
  };

  useEffect(() => {
    setCache();
  }, [filterState]);

  useEffect(
    function refreshOpsDetails() {
      if (props.searchOptions !== null && props.searchOptions !== undefined) {
        const fyfp = {
          fiscalYear: parseInt(props.searchOptions.fiscalYear, 10),
          fiscalMonth: parseInt(props.searchOptions.fiscalMonth, 10)
        };
        setSearchKey({
          fiscalYear: fyfp.fiscalYear,
          fiscalMonth: fyfp.fiscalMonth,
          opsDetailName: props.searchOptions.opsDetailName,
          opsDetailId: Number(props.searchOptions.opsDetailId)
        });
      }
    },
    [props.searchOptions]
  );

  useEffect(() => {
    if (gridItems) {
      setFilteredItems(
        filterState.filterBar ? JEMChecklistFilterRows(filterState, dashboardFilters, gridItems) : gridItems
      );
    }
  }, [gridItems]);

  const filterItems = (newFilterState?: FilterState) => {
    if (!gridItems) {
      return;
    }
    if (newFilterState) {
      if (!isEqual(newFilterState, filterState)) {
        setFilterState(newFilterState);
        const newItems = JEMChecklistFilterRows(newFilterState, dashboardFilters, gridItems);
        setFilteredItems(newItems);
      } else {
        setFilteredItems(gridItems);
      }
    } else {
      setFilteredItems(gridItems);
    }
  };

  const closePanel = () => {
    setPageState({
      ...pageState,
      editItem: null
    });
  };

  const error = gridItemsError;

  return (
    <>
      <JEMChecklistsOpsDetailSelector
        configuration={props.configuration}
        currentSettings={props.searchOptions}
        domainData={jemContext.initInfo.values}
        onUpdateClicked={(fyfp, opsDetail) => {
          if (opsDetail) {
            props.onSearchOptionsChanged({
              fiscalYear: `${fyfp.fiscalYear}`,
              fiscalMonth: `${fyfp.fiscalMonth}`,
              opsDetailId: `${opsDetail.id}`,
              opsDetailName: opsDetail.name
            });
          }
        }}
      />

      {status !== LoadingStatus.Pending && gridItems && gridItems.length == 0 ? (
        <>
          <JEMChecklistDashboardCommandBar configuration={props.fcwCreateTaskUrl} />
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            No Items to show.
          </Text>
        </>
      ) : error ? (
        <Text
          variant="xLarge"
          style={{
            color: "var(--accent-font-color, black)"
          }}
        >
          Error Loading Checklists - {(error as any).message}
        </Text>
      ) : (
        <>
          <div
            className={css`
              position: relative;
              flex-grow: 1;
              width: 100%;
            `}
          >
            <DashboardFilter<JEMChecklistDashboardRow>
              filters={dashboardFilters}
              savedFilters={dashboardCache.filterBar}
              singleSelectionFilters={["Active"]}
              searchEnabledFilters={["OpsDetail", "Company Code"]}
              onFilterChanged={filterItems}
              loadingStatus={LoadingStatus.Resolved}
              tilesAndFilters={{
                currentTile: "",
                tiles: OrderedMap<string, IIndexedTile>({}),
                gridItems: gridItems || [],
                searchCriteria: dashboardCache && dashboardCache.searchCriteria
              }}
              localStorageKey={`${LocalStorageKeys?.checklistDashboardFilterState}`}
            />
          </div>
          <div
            className={css`
              display: inline-flex;
              gap: 16px;
              flex-direction: column;
              flex-wrap: wrap;
              justify-content: center;
              align-items: stretch;
              width: 100%;
              margin: 16px 0 16px 0;
            `}
          >
            <DashboardGrid
              idForLocalStorage={"jemChecklistDashboardConfiguration"}
              columnGenerator={getJEMChecklistColumns({
                onEditClick,
                fcwUrl: props.fcwCreateTaskUrl.replace("/stagedTasks/new", ""),
                opsDetailId: props.searchOptions ? props.searchOptions.opsDetailId : null
              })}
              isSortedIndex={10}
              items={filteredItems}
              isSortedDescending={true}
              loadingStatus={status}
              customRef={dashboardGridRef}
              selectionMode={SelectionMode.multiple}
              height={`500px`}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "jemChecklists",
                  rowsWithHeader: rows,
                  fileName: "jemChecklists.xlsx"
                });
              }}
            >
              <JEMChecklistDashboardCommandBar configuration={props.fcwCreateTaskUrl} />
            </DashboardGrid>
          </div>
        </>
      )}

      {pageState.editItem !== null && (
        <JEMChecklistEditPanel
          checklistItem={pageState.editItem}
          onClosePanel={() => {
            closePanel();
          }}
          onSaveItem={(editedItem: JEMChecklistDashboardRow) => {
            //do something call api
            console.log(editedItem);
            closePanel();
          }}
        />
      )}
    </>
  );
};

JEMChecklistDashboardByOpsDetail.displayName = "JEMChecklistDashboardByOpsDetail";

export default JEMChecklistDashboardByOpsDetail;

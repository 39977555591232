import { MessageBarType } from "@fluentui/react";
import { useContext } from "react";
import { JemConfiguration } from "../../../../JemConfiguration";
import {
  DomainDataEnum,
  DomainDataObjects,
  GeneralLedgerAction,
  IUserProviderState,
  LoggingContext
} from "../../../../Shared";
import { UserContext } from "../../../../Shared/contexts/UserContext/UserContext";
import { ErrorHelper } from "../../../../Shared/utilities/ErrorHelper";
import { ErrorMessages } from "../../../../Shared/utilities/ErrorHelper.messages";
import { getValidUrl, postRequest, putRequest } from "../../../../Shared/utilities/RequestUtilities";
import { noActivityAction } from "../../../shared/noActivityAction";
import {
  GLCreateQueryStringParameters,
  PreReviewCallResponse,
  SaveJeDraftResponse,
  ValidateCallResponse
} from "../GLCreate.types";
import { ErrorListModel, GLCreateOverrides } from "../GLCreateForm.types";
import { GLCreateActionsEnum } from "../useWeightage";
import {
  assembleEmptyJeDetails,
  assembleJeDetails,
  CreateActionOnSubmitHandlerMap,
  CreateActionPayload,
  GLCreateResult
} from "./GLCreateActions.utils";
import { assembleCreatePageState, createBlankCreateState } from "../GLCreate.State";

export function useGLCreateActions(
  configuration: Pick<JemConfiguration, "GeneralLedgerApi" | "featureFlags" | "environment">,
  userContext: Pick<IUserProviderState, "accessToken" | "user" | "jemUser">,
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeCompanyCodes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.StatusActions
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.JeParameters
    | DomainDataEnum.JeStatus
  >,
  queryString: Pick<GLCreateQueryStringParameters, "RefGuid" | "Templateid">,
  defaultOverrides?: Partial<GLCreateOverrides>
): CreateActionOnSubmitHandlerMap {
  const { accessToken } = useContext(UserContext);
  const logger = useContext(LoggingContext);
  const _errorHelper = new ErrorHelper();

  const makeUrl = (endpoint: string) => {
    const base = configuration.GeneralLedgerApi.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };

  return {
    [GLCreateActionsEnum.Save]: async (payload: CreateActionPayload) => {
      logger.appInsights?.trackEvent({
        name: "saveDraft",
        properties: {
          refGuid: payload.state.pageConfiguration.refGuid
        }
      });
      const jeDraftData = assembleJeDetails(payload, "Save");
      const endpoint = makeUrl(
        configuration.GeneralLedgerApi.endpoints.cSaveDraft.replace(
          "{draftName}",
          payload.formikState.headerJeName.split(".")[0]
        )
      );

      const response = await postRequest<SaveJeDraftResponse>(endpoint, jeDraftData, accessToken);

      if (response === undefined || response === null || response["status"] === 400) {
        return {
          message: {
            message: "Could not Save Draft.",
            type: MessageBarType.error
          },
          blockPage: false
        } as GLCreateResult;
      }

      const HeaderError = response.jeHeaderError;
      const AuditError = response.jeAuditError;
      const hdrErrors: string[] = [];
      let _isHeaderError = false;
      const allErrors = new Set();
      if (AuditError && AuditError !== null) {
        const auditErrors = AuditError.split(";");
        for (const auditError of auditErrors) {
          if (auditError.toUpperCase() !== "OK" && auditError) {
            if (allErrors.has(auditError)) {
              continue;
            }
            hdrErrors.push(auditError);
            allErrors.add(auditError);
            _isHeaderError = true;
          }
        }
      }

      if (HeaderError && HeaderError !== null) {
        const headerErrors = HeaderError.split(";");
        for (const hError of headerErrors) {
          if (hError.toUpperCase() !== "OK" && hError) {
            if (allErrors.has(hError)) {
              continue;
            }

            hdrErrors.push(hError);
            allErrors.add(hError);
            _isHeaderError = true;
          }
        }
      }
      const lineItemsErrorsMap: {
        [key: number]: ErrorListModel[];
      } = {};
      if (response.jeLineItemErrors) {
        for (const allErrorsObject of response.jeLineItemErrors) {
          if (allErrorsObject.description === "OK") {
            continue;
          }
          const currentLineItemErrors = new Set();
          const errors = allErrorsObject.description.split(";");
          for (const error of errors) {
            if (currentLineItemErrors.has(error)) {
              continue;
            }
            if (allErrors.has(error)) {
              continue;
            }
            currentLineItemErrors.add(error);
            const rowNumber = allErrorsObject.lineNumber;
            if (lineItemsErrorsMap[rowNumber] && lineItemsErrorsMap[rowNumber].length !== 0) {
              lineItemsErrorsMap[rowNumber][0].description = `${lineItemsErrorsMap[rowNumber][0].description};${error}`;
            } else {
              lineItemsErrorsMap[rowNumber] = [
                {
                  description: error,
                  rowNumber: rowNumber
                }
              ];
            }
          }
        }
      }

      const lineItemsErrors = Object.values(lineItemsErrorsMap).flatMap((x) => x);

      let errorMesageForUI = ErrorHelper.getMessage("ERR1007");
      if (response.otherErrors && response.otherErrors.startsWith("ERR")) {
        errorMesageForUI = ErrorHelper.getMessage(response.otherErrors as keyof typeof ErrorMessages);
      } else if (response.hasError && response.otherErrors) {
        errorMesageForUI = response.otherErrors;
      }

      const createPageState = response.jeDetails
        ? assembleCreatePageState(
            configuration,
            userContext,
            queryString,
            response.jeDetails,
            response as any,
            domainData,
            defaultOverrides
          )
        : createBlankCreateState();

      if (!response && response !== 0) {
        return {
          message: {
            message: "Could not Save Draft.",
            type: MessageBarType.error
          },
          blockPage: false,
          pageState: createPageState
        };
      }

      if (response.hasError) {
        return {
          message: {
            message: errorMesageForUI,
            type: MessageBarType.error
          },
          blockPage: false,
          lineItemsErrors,
          genericHeaderErrors: hdrErrors,
          pageState: createPageState
        };
      }

      return {
        message: {
          message: response.jeDetails?.jeDetailsInfo?.scheduleName
            ? "This draft is saved as part of Recurring JE Schedule. Prereview approval is mandatory for posting."
            : "Journal Entry Draft is Saved Successfully.",
          type: MessageBarType.success
        },
        blockPage: false,
        pageState: createPageState
      };
    },
    [GLCreateActionsEnum.Post]: async (payload: CreateActionPayload) => {
      logger.appInsights?.trackEvent({
        name: "postDraft",
        properties: {
          refGuid: payload.state.pageConfiguration.refGuid
        }
      });
      const endpoint = makeUrl(
        configuration.GeneralLedgerApi.endpoints.cPostDraft.replace("{guid}", payload.state.pageConfiguration.refGuid)
      );
      const r = await putRequest<string>(endpoint, undefined, accessToken);
      if (!r) {
        return {
          message: {
            message: "Could not Post Draft.",
            type: MessageBarType.error
          },
          blockPage: false
        };
      }
      if (typeof r === "string") {
        return {
          message: {
            message: r,
            type: MessageBarType.error
          },
          blockPage: false
        };
      }
      return {
        message: {
          message: "Draft Posted.",
          type: MessageBarType.success
        },
        blockPage: true
      };
    },
    [GLCreateActionsEnum.SendToPoster]: async (payload: CreateActionPayload) => {
      logger.appInsights?.trackEvent({
        name: "sendtoPoster",
        properties: {
          refGuid: payload.state.pageConfiguration.refGuid
        }
      });
      const PosterCommentsData = {
        guid: [payload.state.pageConfiguration.refGuid],
        comments: payload.formikState.postersTabComments,
        userAliases: `${payload.formikState.postersTabPoster};${payload.formikState.postersTabBackupPosters.join(";")}`,
        actionType: "1"
      };
      const endpoint = makeUrl(configuration.GeneralLedgerApi.endpoints.cSendToPosterDraft);
      const response = await postRequest<GeneralLedgerAction>(endpoint, PosterCommentsData, accessToken);
      if (!response || response.status === false) {
        return {
          message: {
            message: "Could not Send to Poster -" + (response?.message || ""),
            type: MessageBarType.error
          },
          blockPage: false
        };
      }
      return {
        message: {
          message: typeof response === "string" ? response : "Sent to Poster successfully.",
          type: MessageBarType.success
        },
        blockPage: false
      };
    },
    [GLCreateActionsEnum.Validate]: async (payload: CreateActionPayload) => {
      logger.appInsights?.trackEvent({
        name: "validate",
        properties: {
          refGuid: payload.state.pageConfiguration.refGuid
        }
      });
      const endpoint = makeUrl(
        configuration.GeneralLedgerApi.endpoints.cValidateDraft.replace(
          "{guid}",
          payload.state.pageConfiguration.refGuid
        )
      );

      const res = await putRequest<ValidateCallResponse>(endpoint, undefined, accessToken);

      if (!res) {
        return {
          message: {
            message: "Could not Validate Draft.",
            type: MessageBarType.error
          },
          blockPage: false
        } as GLCreateResult;
      }

      const HeaderError = res.jeHeaderError;
      const AuditError = res.jeAuditError;
      const hdrErrors: string[] = [];
      let _isHeaderError = false;
      const allErrors = new Set();
      if (AuditError !== null) {
        const auditErrors = AuditError.split(";");
        for (const auditError of auditErrors) {
          if (auditError.toUpperCase() !== "OK" && auditError) {
            if (allErrors.has(auditError)) {
              continue;
            }
            hdrErrors.push(auditError);
            allErrors.add(auditError);
            _isHeaderError = true;
          }
        }
      }

      if (HeaderError !== null) {
        const headerErrors = HeaderError.split(";");
        for (const hError of headerErrors) {
          if (hError.toUpperCase() !== "OK" && hError) {
            if (allErrors.has(hError)) {
              continue;
            }

            hdrErrors.push(hError);
            allErrors.add(hError);
            _isHeaderError = true;
          }
        }
      }
      const lineItemsErrorsMap: {
        [key: number]: ErrorListModel[];
      } = {};
      if (res.jeLineItemErrors) {
        for (const allErrorsObject of res.jeLineItemErrors) {
          if (allErrorsObject.description === "OK") {
            continue;
          }
          const currentLineItemErrors = new Set();
          const errors = allErrorsObject.description.split(";");
          for (const error of errors) {
            if (currentLineItemErrors.has(error)) {
              continue;
            }
            if (allErrors.has(error)) {
              continue;
            }
            currentLineItemErrors.add(error);
            const rowNumber = allErrorsObject.lineNumber;
            if (lineItemsErrorsMap[rowNumber] && lineItemsErrorsMap[rowNumber].length !== 0) {
              lineItemsErrorsMap[rowNumber][0].description = `${lineItemsErrorsMap[rowNumber][0].description};${error}`;
            } else {
              lineItemsErrorsMap[rowNumber] = [
                {
                  description: error,
                  rowNumber: rowNumber
                }
              ];
            }
          }
        }
      }

      const lineItemsErrors = Object.values(lineItemsErrorsMap).flatMap((x) => x);
      let errorMesageForUI = ErrorHelper.getMessage("ERR1007");
      if (res.otherErrors && res.otherErrors.startsWith("ERR")) {
        errorMesageForUI = ErrorHelper.getMessage(res.otherErrors as keyof typeof ErrorMessages);
      } else if (res.hasError && res.otherErrors) {
        errorMesageForUI = res.otherErrors;
      }

      const createPageState = res.jeDetails
        ? assembleCreatePageState(
            configuration,
            userContext,
            queryString,
            res.jeDetails,
            res,
            domainData,
            defaultOverrides
          )
        : createBlankCreateState();
      const result: GLCreateResult = {
        message: {
          message: errorMesageForUI,
          type: MessageBarType.error
        },
        blockPage: false,
        lineItemsErrors,
        genericHeaderErrors: hdrErrors,
        pageState: createPageState
      };

      if (res.hasError) {
        logger.appInsights?.trackEvent({
          name: "validateResponse",
          properties: {
            refGuid: payload.state.pageConfiguration.refGuid,
            lineItemsErrors: lineItemsErrors,
            genericHeaderErrors: hdrErrors,
            response: res
          }
        });
        return result;
      }

      if (
        !res.hasError &&
        Array.isArray(res.sxModelPrediction) &&
        res.sxModelPrediction.length > 0 &&
        res.warningMessage !== null &&
        res.warningMessage !== ""
      ) {
        return {
          message: {
            message: res.otherErrors + ". " + res.warningMessage,
            type: MessageBarType.warning
          },
          blockPage: false,
          pageState: createPageState
        } as GLCreateResult;
      }

      return {
        message: {
          message: "Validation finished with no errors.",
          type: MessageBarType.success
        },
        blockPage: false,
        pageState: createPageState
      } as GLCreateResult;
    },
    [GLCreateActionsEnum.NeedsClarification]: async (payload: CreateActionPayload) => {
      logger.appInsights?.trackEvent({
        name: "needsClarification",
        properties: {
          refGuid: payload.state.pageConfiguration.refGuid
        }
      });
      const clarifyPayload = {
        guid: [payload.state.pageConfiguration.refGuid],
        userAliases: `${payload.formikState.postersTabPoster};${payload.formikState.postersTabBackupPosters.join(";")}`,
        comments: payload.formikState.postersTabComments,
        actionType: "12"
      };
      const endpoint = makeUrl(configuration.GeneralLedgerApi.endpoints.cNeedsClarificationDraft);
      const response = await postRequest<string>(endpoint, clarifyPayload, accessToken);
      if (!response) {
        return {
          message: {
            message: "Could not request clarification.",
            type: MessageBarType.error
          },
          blockPage: false
        };
      }
      return {
        message: {
          message: "Sent for Needing Clarification.",
          type: MessageBarType.success
        },
        blockPage: false
      };
    },
    [GLCreateActionsEnum.Clear]: async (payload: CreateActionPayload) => {
      logger.appInsights?.trackEvent({
        name: "clearDraft",
        properties: {
          refGuid: payload.state.pageConfiguration.refGuid
        }
      });
      const jeDraftData = assembleEmptyJeDetails(payload, "Save");
      const endpoint = makeUrl(
        configuration.GeneralLedgerApi.endpoints.cSaveDraft.replace(
          "{draftName}",
          payload.state.createState.jeName.split(".")[0]
        )
      );
      const response = await postRequest<number>(endpoint, jeDraftData, accessToken);
      if (!response && response !== 0) {
        return {
          message: {
            message: "Could not Clear Draft.",
            type: MessageBarType.error
          },
          blockPage: false
        };
      }

      return {
        message: {
          message: "Draft Cleared.",
          type: MessageBarType.success
        },
        blockPage: false
      };
    },
    [GLCreateActionsEnum.NoActivity]: async (payload, options) => {
      logger.appInsights?.trackEvent({
        name: "noActivity",
        properties: {
          refGuid: payload.state.pageConfiguration.refGuid,
          reason: options?.noActivityReason as string
        }
      });

      const response = await noActivityAction({
        refguid: payload.state.pageConfiguration.refGuid,
        type: "je",
        configuration: configuration.GeneralLedgerApi,
        accessToken: accessToken,
        reason: options?.noActivityReason as string
      });
      return {
        message: {
          message: response.message,
          type: response.status ? MessageBarType.success : MessageBarType.error
        },
        blockPage: true
      };
    },
    [GLCreateActionsEnum.PreReview]: async (payload: CreateActionPayload) => {
      logger.appInsights?.trackEvent({
        name: "preReview",
        properties: {
          refGuid: payload.state.pageConfiguration.refGuid
        }
      });
      const endpoint = makeUrl(
        configuration.GeneralLedgerApi.endpoints.preReviewDraft?.replace(
          "{guid}",
          payload.state.pageConfiguration.refGuid
        )
      );
      const r = await putRequest<PreReviewCallResponse>(endpoint, undefined, accessToken);
      if (!r || r.errorCode !== null) {
        logger.appInsights?.trackEvent({
          name: "preReviewApiError",
          properties: {
            refGuid: payload.state.pageConfiguration.refGuid,
            errors: r?.errorCode
          }
        });
        return {
          message: {
            message: typeof r === "string" ? r : "Could not PreReview.",
            type: MessageBarType.error
          },
          blockPage: false
        };
      }
      return {
        message: {
          message: r.recurringJEScheduleId
            ? "Recurring Schedule JE Draft submitted for prereview. Draft will be posted on next posting date if approved."
            : "Draft sent for PreReview.",
          type: MessageBarType.success
        },
        blockPage: true
      };
    }
  };
}

import { Checkbox, Label, Shimmer, Stack, Text, TextField } from "@fluentui/react";
import { FastField, Field, useFormikContext } from "formik";
import React from "react";
import { LoadingStatus } from "../../../../Shared";
import { SimpleShimmerForForms } from "../../../../Shared/components/SimpleShimmerForForms/SimpleShimmerForForms";
import { labelStyles } from "../../../../Shared/utilities/CommonStyles";
import { GLActionTypes } from "../../../hooks";
import { gridStyle, columnStyles, itemStyle } from "../../../shared/GL.styles";
import { GLJeDetailsFormikState, GLJeDetailsState } from "../GLDetailsForm.types";
import { jeDetailsStateFlags } from "../utils/jeDetailsStateFlags";

export interface UIAuditDetailsProps {
  state: GLJeDetailsState;
  loadingStatus: LoadingStatus;
  disabled: boolean;
  actionWeightages: Record<GLActionTypes, number>;
}

const GLDetailsTab: React.FC<UIAuditDetailsProps> = (props) => {
  const formik = useFormikContext<GLJeDetailsFormikState>();

  const { isBulk, isNotReversalJe, isReleaseAllowed } = jeDetailsStateFlags(props.state, props.actionWeightages);

  return (
    <React.Fragment>
      <Stack>
        <Stack horizontal wrap {...{ tokens: gridStyle }}>
          <Stack {...{ tokens: columnStyles }}>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Doc Number</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.documentNumber} </Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Company Code</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.companyCode}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Ref Number</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.refNumber}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>JE Name</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.jeName}</Text>
              )}
            </div>
          </Stack>
          <Stack {...{ tokens: columnStyles }}>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Poster's Org</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.postersOrg} </Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Reviewer's Org</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.reviewersOrg}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Significance</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.significance}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Task Type</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.isAdhocOrStd}</Text>
              )}
            </div>
          </Stack>
          <Stack {...{ tokens: columnStyles }}>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Classification</Label>
              <Stack horizontal {...{ tokens: gridStyle }}>
                <Stack.Item>
                  <Checkbox label="JEM" checked={props.state.detailsTab.isJem} disabled />
                </Stack.Item>
                <Stack.Item>
                  <Checkbox label="BPO" checked={props.state.detailsTab.isBpo} disabled />
                </Stack.Item>
              </Stack>
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Recurring JE</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.recurringJe}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Recurring Year</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.recurringJeFiscalYear}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Ops Detail</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.opsDetailName}</Text>
              )}
            </div>
          </Stack>
          <Stack {...{ tokens: columnStyles }}>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Fiscal Year/Period</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>
                  {props.state.detailsTab.fiscalYear} {props.state.detailsTab.fiscalPeriod}
                </Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Reversal JE</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.reversalJe}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Reason Code </Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.reasonCode}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Checklist Name</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.fcwChecklistName}</Text>
              )}
            </div>
          </Stack>
          <Stack {...{ tokens: columnStyles }}>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Translation Date</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.translationDate}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Review Due Date</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.dueDate}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Plan Reverse Date</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.planReversalDate}</Text>
              )}
            </div>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Recurring JE schedule name</Label>
              {props.loadingStatus !== LoadingStatus.Resolved ? (
                <Shimmer height={20} />
              ) : (
                <Text>{props.state.detailsTab.recurringJeScheduleName}</Text>
              )}
            </div>
          </Stack>
          <Stack {...{ tokens: columnStyles }}>
            <div style={itemStyle}>
              <Label styles={labelStyles}>Description</Label>
              <Text>{props.state.detailsTab.description}</Text>
            </div>
            <div style={itemStyle}>
              <SimpleShimmerForForms height={64} loadingStatus={props.loadingStatus} label="Purpose">
                <FastField
                  name={"detailsTabJePurpose"}
                  label="Purpose"
                  as={TextField}
                  multiline
                  rows={3}
                  type="input"
                  errorMessage={formik.errors.detailsTabJePurpose}
                />
              </SimpleShimmerForForms>
            </div>
          </Stack>
        </Stack>
        {isReleaseAllowed ? (
          <Stack {...{ tokens: { childrenGap: 10, padding: 10 } }}>
            <Stack.Item>
              <Field
                label="I am accepting responsibility for these entries and that they are in compliance with MAP policy"
                name={"detailsTabMapPolicy"}
                type="checkbox"
                as={Checkbox}
                styles={{
                  root: {
                    paddingBottom: "5px"
                  }
                }}
                disabled={props.disabled}
                onChange={(_event, checked) => {
                  formik.setFieldValue("detailsTabMapPolicy", checked);
                }}
              />
            </Stack.Item>
            {isNotReversalJe && isBulk && (
              <Stack.Item>
                <Field
                  label="I understand that this is a bulk upload and that the tool has added the same back-up document to all Journals within the batch"
                  name={"detailsTabBulkAcknowledge"}
                  type="checkbox"
                  as={Checkbox}
                  styles={{
                    root: {
                      paddingBottom: "5px"
                    }
                  }}
                  disabled={props.disabled}
                  onChange={(_event, checked) => {
                    formik.setFieldValue("detailsTabBulkAcknowledge", checked);
                  }}
                />
              </Stack.Item>
            )}
          </Stack>
        ) : null}
      </Stack>
    </React.Fragment>
  );
};

GLDetailsTab.displayName = "GLDetailsTab";
export { GLDetailsTab };

import { v4 } from "uuid";
import { ApiParsingUtilities } from "../../../../Shared/utilities/ApiParsingUtilities";
import { GLPageState, CreatePageType } from "../GLCreate.State";
import { LineItemModel, ErrorListModel } from "../GLCreateForm.types";
import { lineItemIsNotEmpty } from "./GLCreateLineItems.lineItemIsNotEmpty";
import { InnerLineItem, BlankLineItem, GLCreateLineItemsOptions } from "./GLCreateLineItems.types";
import { getErrorsFromLineItems } from "./GLCreateLineItems.validateLineItem";
import numeral from "numeral";

const _MAX_SIZE = 900;

function TableRowToLineItemsModel(
  opts: LineItemsSettings,
  rowValues: LineItemModel
): GLPageState["lineItems"][number] | null {
  const finalObject: GLPageState["lineItems"][number] = {
    account: null,
    costCenter: "",
    internalOrder: "",
    salesOrder: "",
    soLineItem: "",
    wbsElement: "",
    tradingPartner: "",
    profitCenter: "",
    lineAmount: 0,
    lineAmountInCurrency: 0,
    lineAmountLC2: 0,
    lineAmountLC3: 0,
    lineDescription: "",
    assignment: "",
    taxCode: "",
    processingStatus: "",
    // control variables not to show on UI
    lineNum: 0,
    jEid: 0,
    refGuid: "",
    extSourceId: 0,
    isExtSourceRow: false
  };
  const account =
    ApiParsingUtilities.parseNumberToNumber(rowValues.account, 0) === 0
      ? null
      : ApiParsingUtilities.parseNumberToNumber(rowValues.account, 0);
  // transform using precision

  const lA = numeral(rowValues.lineAmount).value();
  const lineAmount = lA === null ? 0 : Number(lA.toFixed(opts.precision));
  const lAInC = numeral(rowValues.lineAmountInCurrency).value();
  const lineAmountInLocalCurrency = lAInC === null ? 0 : Number(lAInC.toFixed(opts.precision));

  const lAInC2 = numeral(rowValues.lineAmountLC2).value();
  const lineAmountLC2 = lAInC2 === null ? 0 : Number(lAInC2.toFixed(2));

  const lAInC3 = numeral(rowValues.lineAmountLC3).value();
  const lineAmountLC3 = lAInC3 === null ? 0 : Number(lAInC3.toFixed(2));

  finalObject.account = account === 0 ? null : account;
  finalObject.costCenter = ApiParsingUtilities.addDefault(rowValues.costCenter, "");
  finalObject.internalOrder = ApiParsingUtilities.addDefault(rowValues.internalOrder, "");
  finalObject.salesOrder = ApiParsingUtilities.addDefault(rowValues.salesOrder, "");
  finalObject.soLineItem = ApiParsingUtilities.addDefault(rowValues.soLineItem, "");
  finalObject.wbsElement = ApiParsingUtilities.addDefault(rowValues.wbsElement, "");
  finalObject.tradingPartner = ApiParsingUtilities.addDefault(rowValues.tradingPartner, "");
  finalObject.profitCenter = ApiParsingUtilities.addDefault(rowValues.profitCenter, "");
  finalObject.lineAmount = lineAmount;
  finalObject.extSourceId = ApiParsingUtilities.parseNumberToNumber(rowValues.extSourceId, 0);
  finalObject.isExtSourceRow = rowValues.isExtSourceRow;

  if (opts.pageConfiguration.pageType === CreatePageType.DualCurrency) {
    finalObject.lineAmountInCurrency = lineAmountInLocalCurrency;
  }

  if (opts.pageConfiguration.pageType === CreatePageType.FourCurrency) {
    finalObject.lineAmountInCurrency = lineAmountInLocalCurrency;
    finalObject.lineAmountLC2 = lineAmountLC2;
    finalObject.lineAmountLC3 = lineAmountLC3;
  }

  finalObject.lineDescription = ApiParsingUtilities.addDefault(rowValues.lineDescription, "");
  finalObject.assignment = ApiParsingUtilities.addDefault(rowValues.assignment, "");
  finalObject.taxCode = ApiParsingUtilities.addDefault(rowValues.taxCode, "");
  finalObject.processingStatus = ApiParsingUtilities.addDefault(rowValues.processingStatus, "");
  finalObject.refGuid = ApiParsingUtilities.addDefault(rowValues.refGuid, v4());
  finalObject.lineNum = ApiParsingUtilities.parseNumberToNumber(rowValues.lineNum, 0);
  finalObject.jEid = ApiParsingUtilities.parseNumberToNumber(rowValues.jEid, 0);

  // 2 If all the input values are null, just remove the row
  if (
    finalObject.account === null &&
    finalObject.costCenter === "" &&
    finalObject.internalOrder === "" &&
    finalObject.salesOrder === "" &&
    finalObject.soLineItem === "" &&
    finalObject.wbsElement === "" &&
    finalObject.tradingPartner === "" &&
    finalObject.profitCenter === "" &&
    finalObject.lineAmount === 0 &&
    finalObject.lineDescription === "" &&
    finalObject.assignment === "" &&
    finalObject.taxCode === "" &&
    finalObject.lineNum === 0
  ) {
    return null;
  }

  return finalObject;
}

export interface LineItemsSettings {
  precision: number;
  pageConfiguration: GLPageState["pageConfiguration"];
}

export function getItemsForActionPayload(
  options: LineItemsSettings,
  // formik: FormikContextType<GLCreateFormikState>,
  initialState: LineItemModel[],
  items: InnerLineItem[],
  actionOptions?: GLCreateLineItemsOptions
): [LineItemModel[], ErrorListModel[]] {
  const itemsWithNoEmptyRows = items.filter(lineItemIsNotEmpty);
  const errors = getErrorsFromLineItems(options, itemsWithNoEmptyRows, actionOptions);

  const itemsReadyForPayload = itemsWithNoEmptyRows
    .map((item: BlankLineItem, index) => {
      const finalModel = TableRowToLineItemsModel(options, item as LineItemModel);
      if (!finalModel) {
        return finalModel;
      }

      const error = errors.find((e) => e.rowNumber === index);
      if (error) {
        finalModel.processingStatus = error.description;
      } else {
        finalModel.processingStatus = "";
      }
      return finalModel;
    })
    .filter((x) => x !== null) as LineItemModel[];

  return [itemsReadyForPayload, errors];
}

export function getDataForGrid(initialLineItems: LineItemModel[]): LineItemModel[] {
  const maxItems = Math.max(initialLineItems.length, _MAX_SIZE);
  const padItems = Array.from({ length: maxItems - initialLineItems.length }, (_, i) => {
    const blankItem: InnerLineItem = {
      account: undefined,
      costCenter: undefined,
      internalOrder: undefined,
      salesOrder: undefined,
      soLineItem: undefined,
      wbsElement: undefined,
      tradingPartner: undefined,
      profitCenter: undefined,
      lineAmount: undefined,
      lineAmountInCurrency: undefined,
      lineAmountLC2: undefined,
      lineAmountLC3: undefined,
      lineDescription: undefined,
      assignment: undefined,
      taxCode: undefined,
      processingStatus: undefined,
      jEid: undefined,
      lineNum: undefined,
      refGuid: undefined,
      innerRowNumber: i + initialLineItems.length,
      extSourceId: undefined,
      isExtSourceRow: undefined
    };
    return blankItem;
  });
  const allItems = [
    ...initialLineItems.map((x, i) => ({
      ...x,
      innerRowNumber: i
    })),
    ...padItems
  ];
  return allItems as LineItemModel[];
}

import { FilterState, FilterSchema, ScheduleJEResponseFromAPI } from "@jem/components";

// eslint-disable-next-line
const toCamelCase = require("lodash/camelCase");

function filterOnSearchCriteria(searchCriteria: string | null, gridItems: ScheduleJEResponseFromAPI[]) {
  if (searchCriteria !== null && searchCriteria !== "") {
    const newItems: ScheduleJEResponseFromAPI[] = [];
    for (const row of gridItems) {
      for (const [type, value] of Object.entries(row)) {
        // everything is a string, if it is a number then avoid
        if (typeof value === "number" || typeof value === "boolean" || typeof value === "object") {
          continue;
        }

        const colKey = type.toLowerCase();
        if (colKey.indexOf("time") !== -1) {
          const theDate = new Date(value).toLocaleDateString();
          if (theDate && theDate.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1) {
            newItems.push(row);
            break;
          }
        } else {
          if (value && value.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1) {
            newItems.push(row);
            break;
          }
        }
      }
    }
    return newItems;
  }
  return gridItems;
}

function filterOnCommandBarFilters(
  filtersCriteria: FilterState["filterBar"],
  filters: FilterSchema<ScheduleJEResponseFromAPI>[],
  gridItems: ScheduleJEResponseFromAPI[]
) {
  let newItems: ScheduleJEResponseFromAPI[] = gridItems;
  for (const filterType of filters) {
    const camelCaseFilterName = toCamelCase(filterType.filterName);
    const filterValue = filtersCriteria[`${camelCaseFilterName}Selected` as keyof FilterState["filterBar"]];
    if (filterValue !== null) {
      if (!filterValue.length) {
        // user removed all items
        return [];
      } else {
        newItems = newItems.filter((row) => {
          const filterRowName = filterType.columnName;
          if (Array.isArray(row[filterRowName as keyof ScheduleJEResponseFromAPI])) {
            const genericArr = row[filterRowName as keyof ScheduleJEResponseFromAPI] as unknown as string[];
            if (genericArr.every((element) => typeof element === "string")) {
              // The array is of type string[]
              const strArr = row[filterRowName as keyof ScheduleJEResponseFromAPI] as unknown as string[];
              return filterValue.some((item) => strArr.indexOf(item) !== -1);
            } else {
              // The array contains elements of mixed types
              console.error("Array contains mixed types... TODO?");
            }
          } else if (
            filterType.filterName.indexOf("dueDate") !== -1 &&
            row[filterRowName as keyof ScheduleJEResponseFromAPI]
          ) {
            const theDate = row[filterRowName as keyof ScheduleJEResponseFromAPI] as string;
            return filterValue.indexOf(theDate) !== -1;
          } else {
            const cell = row[filterRowName as keyof ScheduleJEResponseFromAPI];
            return filterValue.indexOf(cell as string) !== -1;
          }
        });
      }
    }
  }
  return newItems;
}

export function RecurringScheduleFilterRows(
  filterState: FilterState,
  filters: FilterSchema<ScheduleJEResponseFromAPI>[],
  gridItems: ScheduleJEResponseFromAPI[]
): ScheduleJEResponseFromAPI[] {
  let newItems = gridItems;
  newItems = filterOnCommandBarFilters(filterState.filterBar, filters, newItems);
  newItems = filterOnSearchCriteria(filterState.searchCriteria, newItems);
  return newItems;
}

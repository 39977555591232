import { SilentRequest, AuthenticationResult, AccountInfo, RedirectRequest } from "@azure/msal-browser";
import { JemConfiguration } from "../../JemConfiguration";
import { BlankAccessMessage } from "../contexts/UserContext/UserContext.roles.main";
import { AuthenticationState, IUserProviderState } from "./RequestUtilities";
import { LoadingStatus } from "./Utilities";

export interface AuthModule {
  getAccount(): AccountInfo | null;
  loadAuthModule(setIsLoggedIn: (value: boolean) => void): void;
  handleResponse(response: AuthenticationResult, setIsLoggedIn: (value: boolean) => void): void;
  login(): void;
  logout(): void;
  getInstance(): AuthModule;
  acquireToken(silentRequest: SilentRequest, interactiveRequest: RedirectRequest): Promise<string | null>;
}

export class AuthModuleImpl implements AuthModule {
  private userProviderState: IUserProviderState;

  constructor(userProviderState: IUserProviderState) {
    this.userProviderState = userProviderState;
  }

  getAccount(): AccountInfo | null {
    return this.userProviderState.user
      ? {
          username: this.userProviderState.user.userName,
          name: this.userProviderState.user.name,
          localAccountId: this.userProviderState.user.accountIdentifier
          // If necessary, add other properties from IUserProviderState.user
        }
      : null;
  }

  loadAuthModule(_setIsLoggedIn: (value: boolean) => void): void {
    console.log("loadAuthModule");
  }

  handleResponse(_response: AuthenticationResult, _setIsLoggedIn: (value: boolean) => void): void {
    console.log("handleResponse");
  }

  login(): void {
    console.log("login");
  }

  logout(): void {
    console.log("logout");
  }

  getInstance(): AuthModule {
    console.log("getInstance");
    return {
      getAccount: this.getAccount.bind(this),
      loadAuthModule: this.loadAuthModule.bind(this),
      handleResponse: this.handleResponse.bind(this),
      login: this.login.bind(this),
      logout: this.logout.bind(this),
      getInstance: this.getInstance.bind(this),
      acquireToken: this.acquireToken.bind(this)
    };
  }

  async acquireToken(_silentRequest: SilentRequest, _interactiveRequest: RedirectRequest): Promise<string | null> {
    // Implement the logic to acquire token here
    const a = await this.userProviderState.accessToken();
    return a.accessToken;
  }
}

const UserProviderStateAdapter = (
  instance: AuthModule,
  account: AccountInfo,
  configuration: JemConfiguration
): IUserProviderState => {
  return {
    user: {
      name: account.name || "",
      userName: account.username,
      accountIdentifier: account.homeAccountId,
      alias: account.username ? account.username.split("@")[0] || "" : ""
    },
    jemUser: {
      supervisor: "NA",
      roles: [],
      euaPortalUrl: "NA",
      accessMessageComponent: BlankAccessMessage,
      hasSapAccess: false,
      hasIhccAccess: false,
      hasSapReversalAccess: false,
      hasF05Access: false,
      hasMsSalesAccess: false,
      currencyFormat: "",
      dateFormat: "",
      delegatedManager: "",
      tenants: [],
      showRecurringJE: false
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    login: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logout: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    refreshRoles: (flag: boolean) => {},
    accessToken: async () => {
      const token = await instance.acquireToken({ scopes: configuration.auth.scopes }, { scopes: ["User.Read"] });

      if (typeof token === "string") {
        return {
          accessToken: token
        } as AuthenticationResult;
      } else {
        throw new Error("Can't login");
      }
    },
    status: AuthenticationState.Authenticated,
    jemRolesStatus: LoadingStatus.Resolved
  };
};

export { UserProviderStateAdapter };

import {
  ApiError,
  AuthorizationToken,
  IUserProviderState,
  JemConfiguration,
  OpsDashboardApi,
  getRequest,
  getValidUrl,
  postRequest
} from "@jem/components";
import { IReconFileStatus } from "./ReconProcess.Columns";
import { ReconProcessSearchOptions } from "../../components/ReconFYFPSelector/ReconFYFPSelector";

export interface IReconMessage {
  fileLocation: string;
  reconFiscalYear: string;
  reconPostingPeriod: string;
  userName: string;
}

export interface IReconMissingJEsData {
  sapjeNum: string;
  fiscalYear: number;
  companyCode: string;
  enteredBy: string;
  entryDate: string;
  jeType: string;
  postingPeriod: number;
  jeHeaderText: string;
}

export async function startRecon(
  configuration: JemConfiguration["OpsDashboardApi"],
  getTokenFn: IUserProviderState["accessToken"],
  reconMessagePayload: IReconMessage
): Promise<boolean> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.actionStartRecon}`;
  const endpointUrl = getValidUrl(endpoint);
  const response = await postRequest<boolean>(endpointUrl, reconMessagePayload, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  return response;
}

export async function updateSystemUsers(
  configuration: JemConfiguration["OpsDashboardApi"],
  getTokenFn: IUserProviderState["accessToken"],
  newUsers: string
): Promise<boolean> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.actionUpdateSysUsers}`.replace(
    "{users}",
    `?users=${newUsers}`
  );
  const endpointUrl = getValidUrl(endpoint);
  const response = await postRequest<boolean>(endpointUrl, newUsers, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  return response;
}

export async function syncJEs(
  configuration: JemConfiguration["OpsDashboardApi"],
  getTokenFn: IUserProviderState["accessToken"],
  reconMessagePayload: IReconMissingJEsData[]
): Promise<boolean> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.actionSyncJE}`;
  const endpointUrl = getValidUrl(endpoint);
  const response = await postRequest<boolean>(endpointUrl, reconMessagePayload, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  return response;
}

export const checkReconStatus = async (
  configuration: OpsDashboardApi,
  getTokenFn: IUserProviderState["accessToken"],
  args: ReconProcessSearchOptions
): Promise<string> => {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.acionCheckReconStatus}`.replace(
    "{reconId}",
    `?reconId=FY${args.fiscalYear}P${args.fiscalPeriod.toString().padStart(2, "0")}`
  );
  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<string>(endpointUrl, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  return response;
};

export async function getReconLogFile(
  documentApiConfiguration: JemConfiguration["DocumentsApi"],
  getTokenFn: IUserProviderState["accessToken"],
  fiscalYear: string,
  fiscalPeriod: string,
  fileName: string
): Promise<File> {
  const endpoint = `${documentApiConfiguration.baseApiUrl}${documentApiConfiguration.endpoints.downloadReconLogFile}`
    .replace("{fiscalYear}", fiscalYear)
    .replace("{fiscalPeriod}", fiscalPeriod)
    .replace("{fileName}", fileName);
  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<string>(endpointUrl, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }

  return fetch(response, {
    method: "GET",
    headers: {
      Authorization: await AuthorizationToken(getTokenFn)
    }
  }) as unknown as File;
}

export async function getMissingJEs(
  configuration: OpsDashboardApi,
  getTokenFn: IUserProviderState["accessToken"],
  args: ReconProcessSearchOptions
): Promise<IReconMissingJEsData[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.actionGetMissingJEs}`.replace(
    "{reconId}",
    `?reconId=FY${args.fiscalYear}P${args.fiscalPeriod.toString().padStart(2, "0")}`
  );
  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<IReconMissingJEsData[]>(endpointUrl, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  return response;
}

export const getReconMockData = (): Promise<IReconFileStatus[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const data: IReconFileStatus[] = [
        {
          fileName: "Test file name",
          reconId: "2",
          jeNum: "10000567",
          fiscalYr: "2024",
          companyCode: "1010",
          enteredBy: "LAJACKS",
          jeType: "Regaular"
        },
        {
          fileName: "2 Test file name",
          reconId: "3",
          jeNum: "10000568",
          fiscalYr: "2024",
          companyCode: "1010",
          enteredBy: "LAJACKS",
          jeType: "Batch"
        },
        {
          fileName: "2 Test file name",
          reconId: "3",
          jeNum: "10000568",
          fiscalYr: "2024",
          companyCode: "1010",
          enteredBy: "LAJACKS",
          jeType: "Batch"
        }
      ];
      resolve(data);
    }, 300);
  });
};

import { GLSearchState } from "./GLSearch.types";

export function calculateNavigateParameters(search: GLSearchState): [true, Record<string, string>] | [false, null] {
  const MAX_URL_LENGTH = 2000;
  const searchParams = search.toRecord();
  const queryParams = new URLSearchParams(searchParams).toString();
  const baseUrl = window.location.origin + window.location.pathname;
  const searchUrlLength = baseUrl.length + queryParams.length;
  if (searchUrlLength > MAX_URL_LENGTH) {
    return [false, null];
  }
  return [true, searchParams];
}

export function fromRecord(record: Record<string, string>): GLSearchState {
  const {
    JEStatus,
    Significance,
    JEType,
    AliasType,
    Alias,
    FiscalYear,
    FiscalMonth,
    DocNumber,
    BPOClassification,
    CompanyCode,
    ReasonCode,
    BusinessGroups,
    OpsDetailId,
    JEName,
    SearchType,
    RecurringScheduleName
  } = record;

  // Convert string values to appropriate types
  const parsedJEStatus = JEStatus.split(",").map(Number);
  const parsedSignificance = Significance.split(",");
  const parsedJEType = JEType.split(",");
  const parsedAliasType = AliasType === "P" ? "P" : "R";
  const parsedAlias = Alias.split(",");
  const parsedFiscalYear = Number(FiscalYear);
  const parsedFiscalMonth = FiscalMonth.split(",").map(Number);
  const parsedBPOClassification = BPOClassification.split(",").map(Number);
  const parsedCompanyCode = CompanyCode.split(",").map(Number);
  const parsedReasonCode = ReasonCode.split(",");
  const parsedBusinessGroups = BusinessGroups.split(",");
  const parsedOpsDetailId = OpsDetailId.split(",").map(Number);
  const parsedJEName = JEName;
  const parsedSearchType = SearchType === "true" ? true : false;
  const parsedRecurringJEName = RecurringScheduleName;

  // Create and return GLSearchState instance
  return new GLSearchState({
    JEStatus: parsedJEStatus,
    Significance: parsedSignificance,
    JEType: parsedJEType,
    AliasType: parsedAliasType,
    Alias: parsedAlias,
    FiscalYear: parsedFiscalYear,
    FiscalMonth: parsedFiscalMonth,
    DocNumber: DocNumber,
    BPOClassification: parsedBPOClassification,
    CompanyCode: parsedCompanyCode,
    ReasonCode: parsedReasonCode,
    BusinessGroups: parsedBusinessGroups,
    OpsDetailId: parsedOpsDetailId,
    JEName: parsedJEName,
    SearchType: parsedSearchType,
    RecurringScheduleName: parsedRecurringJEName
  });
}

export function fromURLSearchParams(record: URLSearchParams): GLSearchState {
  const JEStatus = record.get("JEStatus") || "";
  const Significance = record.get("Significance") || "";
  const JEType = record.get("JEType") || "";
  const AliasType = record.get("AliasType") === "R" ? "R" : "P";
  const Alias = record.get("Alias") || "";
  const FiscalYear = Number(record.get("FiscalYear")) || 0;
  const FiscalMonth = (record.get("FiscalMonth") || "")
    .split(",")
    .filter((x) => x)
    .map(Number);
  const DocNumber = record.get("DocNumber") || "";
  const BPOClassification = (record.get("BPOClassification") || "")
    .split(",")
    .filter((x) => x)
    .map(Number);
  const CompanyCode = (record.get("CompanyCode") || "")
    .split(",")
    .filter((x) => x)
    .map(Number);
  const ReasonCode = record.get("ReasonCode") || "";
  const BusinessGroups = record.get("BusinessGroups") || "";
  const OpsDetailId = (record.get("OpsDetailId") || "")
    .split(",")
    .filter((x) => x)
    .map(Number);
  const JEName = record.get("JEName") || "";
  const RecurringScheduleName = record.get("RecurringScheduleName") || "";

  // Create and return GLSearchState instance
  return new GLSearchState({
    JEStatus: JEStatus.split(",")
      .filter((x) => x)
      .map(Number),
    Significance: Significance.split(",").filter((x) => x),
    JEType: JEType.split(",").filter((x) => x),
    AliasType,
    Alias: Alias.split(",").filter((x) => x),
    FiscalYear,
    FiscalMonth,
    DocNumber,
    BPOClassification,
    CompanyCode,
    ReasonCode: ReasonCode.split(",").filter((x) => x),
    BusinessGroups: BusinessGroups.split(",").filter((x) => x),
    OpsDetailId,
    JEName,
    SearchType: record.get("SearchType") === "true" ? true : false,
    RecurringScheduleName
  });
}

import { IColumn, IconButton, mergeStyleSets, Text, Theme, TooltipHost } from "@fluentui/react";
import {
  DEFAULT_NULL_CHARACTER,
  FiscalPeriodStringTransformations,
  GLDashboardRow,
  IExtendedColumn,
  onRenderArray,
  SanitizedDashboardRow,
  SanitizedDraftDashboardRow,
  SanitizedPreReviewDashboardRow,
  SignificanceTypeAndIcon
} from "@jem/components";
import React from "react";
import { Link, Location } from "react-router-dom";
import { significanceIconMapping } from "../../../Shared/utilities/significanceIconMapping";
import { AttachmentDialogRef } from "./AttachmentDialog/AttachmentDialog";

interface GLColumn extends IExtendedColumn {
  fieldName: keyof Omit<GLDashboardRow, "isDraft">;
}

interface GLDraftColumn extends IExtendedColumn {
  fieldName: keyof Omit<GLDashboardRow, "isDraft">;
}

const classNames = mergeStyleSets({
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px"
  }
});

export function getBPOTypeAndIcon(postingType: string, BPOFlag: string) {
  if ((postingType === "4" || postingType === "5" || postingType === "7") && BPOFlag === "true") {
    return {
      bPOType: "Standard",
      icon: (
        <img
          src={require(`../../assets/Icons/Standard.png`)}
          className={classNames.fileIconImg}
          alt={`Standard Icon`}
        />
      )
    };
  } else if ((postingType === "1" || postingType === "3") && BPOFlag === "true") {
    return {
      bPOType: "Non Standard",
      icon: (
        <img
          src={require(`../../assets/Icons/NonStandard.png`)}
          className={classNames.fileIconImg}
          alt={`Non Standard Icon`}
        />
      )
    };
  } else {
    return {
      bPOType: "Non BPO",
      icon: (
        <img src={require(`../../assets/Icons/NonBPO.png`)} className={classNames.fileIconImg} alt={`Non BPO Icon`} />
      )
    };
  }
}

export const getDraftColumns = (location: Location, theme: Theme) => () => {
  return (): IExtendedColumn[] => {
    const cols: GLDraftColumn[] = [
      {
        key: "draftName",
        name: "Name",
        fieldName: "draftName",
        type: "string",
        minWidth: 80,
        onRender: (
          item?: SanitizedDraftDashboardRow,
          index?: number | undefined,
          column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!column || !item) {
            return null;
          }
          const url =
            (item.format === "Batch" || item.format === "Bulk") && item.status !== "Failed in SAP"
              ? `/gl/batch?RefGuid=${item.refGuid}`
              : `/gl/create?RefGuid=${item.refGuid}`;

          return (
            <Link
              to={url}
              state={{
                from: location
              }}
            >
              {item.draftName}
            </Link>
          );
        }
      },
      {
        key: "coCode",
        name: "Co Code",
        fieldName: "companyCode",
        type: "object",
        minWidth: 40,
        onRender: (item?: SanitizedDraftDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.companyCode);
        }
      },
      {
        key: "attachments",
        name: "Attachment",
        fieldName: "attachments",
        type: "string",
        minWidth: 75,
        onRender: (item: SanitizedDraftDashboardRow) => {
          if (Number(item.attachments) > 0) {
            return (
              <TooltipHost content={"Please open Draft to see Attachments"}>
                <img
                  src={require(`../../assets/Icons/attachments.png`)}
                  className={classNames.fileIconImg}
                  alt={`Attachments Icon`}
                />
              </TooltipHost>
            );
          } else {
            return DEFAULT_NULL_CHARACTER;
          }
        }
      },
      {
        key: "description",
        name: "Description",
        fieldName: "description",
        type: "string",
        minWidth: 75
      },
      {
        key: "status",
        name: "Status",
        fieldName: "status",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by status"
      },
      {
        key: "poster",
        name: "Poster",
        fieldName: "poster",
        type: "string",
        minWidth: 90,
        sortDescendingAriaLabel: "Sorted by poster"
      },

      {
        key: "additionalPosters",
        name: "Backup Posters",
        fieldName: "additionalPosters",
        type: "object",
        minWidth: 120,
        sortDescendingAriaLabel: "Sorted by poster",
        onRender: (item?: SanitizedDraftDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.additionalPosters);
        }
      },
      {
        key: "reviewer",
        name: "Reviewer",
        fieldName: "reviewer",
        type: "string",
        minWidth: 120,
        sortDescendingAriaLabel: "Sorted by reviewer",
        onRender: (item?: SanitizedDraftDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.reviewer);
        }
      },
      {
        key: "lastComments",
        name: "Comments",
        fieldName: "lastComments",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by comments"
      },
      {
        key: "fiscalPeriod",
        name: "Fiscal Period",
        fieldName: "fiscalPeriod",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by Fiscal Period"
      },
      {
        key: "isBPOEntry",
        name: "BPO",
        fieldName: "isBPOEntry",
        type: "string",
        minWidth: 75,
        onRender: (item: SanitizedDraftDashboardRow) => {
          const typeAndIcon = getBPOTypeAndIcon(item.postingType, item.isBPOEntry);
          return <TooltipHost content={typeAndIcon.bPOType}>{typeAndIcon.icon}</TooltipHost>;
        }
      },
      {
        key: "format",
        name: "Format",
        fieldName: "format",
        type: "string",
        minWidth: 75
      },
      {
        key: "modifiedOn",
        name: "Date Modified",
        fieldName: "modifiedOn",
        type: "date",
        minWidth: 105
      },
      {
        key: "processingStatus",
        name: "Processing Status",
        fieldName: "processingStatus",
        type: "string",
        minWidth: 120,
        onRender: (item: SanitizedDashboardRow) => {
          const procColor = item.procStatType == "Success" ? theme.palette.green : theme.palette.red;
          return (
            <Text
              variant="small"
              style={{
                color: procColor
              }}
            >
              <TooltipHost content={item.processingStatus}>{item.processingStatus}</TooltipHost>
            </Text>
          );
        }
      }
    ];
    return cols;
  };
};

export const notPostedPostingRequestedColumns = (location: Location) => () => {
  return (): IExtendedColumn[] => {
    const cols: GLColumn[] = [
      {
        key: "draftName",
        name: "Name",
        fieldName: "jeName",
        type: "string",
        minWidth: 80,
        onRender: (
          item?: SanitizedDashboardRow,
          index?: number | undefined,
          column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!column || !item) {
            return null;
          }
          const url =
            item.format === "Batch" || item.format === "Bulk"
              ? `/gl/batch?RefGuid=${item.refGuid}`
              : `/gl/create?RefGuid=${item.refGuid}`;

          return (
            <Link
              to={url}
              state={{
                from: location
              }}
            >
              {item.jeName}
            </Link>
          );
        }
      },
      {
        key: "coCode",
        name: "Co Code",
        fieldName: "companyCode",
        type: "object",
        minWidth: 60,
        onRender: (item?: SanitizedDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.companyCode);
        }
      },
      {
        key: "description",
        name: "Description",
        fieldName: "description",
        type: "string",
        minWidth: 75
      },
      {
        key: "status",
        name: "Status",
        fieldName: "status",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by status"
      },
      {
        key: "lastComments",
        name: "Comments",
        fieldName: "lastComments",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by comments"
      },
      {
        key: "additionalPosters",
        name: "Backup Posters",
        fieldName: "additionalPosters",
        type: "object",
        minWidth: 120,
        sortDescendingAriaLabel: "Sorted by poster",
        onRender: (item?: SanitizedDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.additionalPosters);
        }
      },
      {
        key: "reviewer",
        name: "Reviewer",
        fieldName: "reviewer",
        type: "string",
        minWidth: 120,
        sortDescendingAriaLabel: "Sorted by reviewer",
        onRender: (item?: SanitizedDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.reviewer);
        }
      },
      {
        key: "fiscalPeriod",
        name: "Fiscal Period",
        fieldName: "fiscalPeriod",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by Fiscal Period",
        onRender: (item: SanitizedDashboardRow) => {
          const fyfp = FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(
            Number(item.fiscalYear),
            Number(item.fiscalPeriod)
          );
          return <TooltipHost content={fyfp}>{fyfp}</TooltipHost>;
        }
      },
      {
        key: "isBPOEntry",
        name: "BPO",
        fieldName: "isBPOEntry",
        type: "string",
        minWidth: 75,
        onRender: (item: SanitizedDashboardRow) => {
          const typeAndIcon = getBPOTypeAndIcon(item.postingType, item.isBPO);
          return <TooltipHost content={typeAndIcon.bPOType}>{typeAndIcon.icon}</TooltipHost>;
        }
      },
      {
        key: "format",
        name: "Format",
        fieldName: "format",
        type: "string",
        minWidth: 75
      },
      {
        key: "modifiedOn",
        name: "Date Modified",
        fieldName: "dateModified",
        type: "date",
        minWidth: 105
      }
    ];
    return cols;
  };
};

export const getGLColumns = (location: Location, theme: Theme) => (attachmentRef: AttachmentDialogRef | null) => {
  return (): IExtendedColumn[] => {
    const cols: GLColumn[] = [
      {
        key: "jeName",
        name: "Name",
        fieldName: "jeName",
        type: "string",
        minWidth: 120,
        onRender: (
          item?: SanitizedDashboardRow,
          index?: number | undefined,
          column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!column || !item) {
            return null;
          }
          return (
            <Link
              to={`/gl/details?jeid=${item.jeId}`}
              state={{
                from: location
              }}
            >
              {item.jeName}
            </Link>
          );
        }
      },
      {
        key: "docNumber",
        name: "Doc Number",
        fieldName: "docNumber",
        type: "string",
        minWidth: 80,
        onRender: (
          item?: SanitizedDashboardRow,
          index?: number | undefined,
          column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!column || !item) {
            return null;
          }
          return (
            <Link
              to={`/gl/details?jeid=${item.jeId}`}
              state={{
                from: location
              }}
            >
              {item.docNumber}
            </Link>
          );
        }
      },
      {
        key: "reversalDocNumber",
        name: "Reversal Doc Number",
        fieldName: "reverseJENum",
        type: "string",
        minWidth: 80
      },
      {
        key: "recurringJESchedule",
        name: "Recurring JE Schedule",
        fieldName: "recurringJESchedule",
        type: "string",
        minWidth: 80,
        onRender: (
          item?: SanitizedDashboardRow,
          index?: number | undefined,
          column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!column || !item) {
            return null;
          }
          return (
            <Link
              to={`/gl/recurringSchedule`}
              state={{
                from: location
              }}
            >
              {item.recurringJESchedule}
            </Link>
          );
        }
      },
      {
        key: "significance",
        name: "!",
        fieldName: "siginificance",
        nameForExport: "Significance",
        type: "string",
        minWidth: 10,
        onRender: (item: SanitizedDashboardRow) => {
          const typeAndIcon = SignificanceTypeAndIcon(item.siginificance, significanceIconMapping);
          return <TooltipHost content={typeAndIcon.significanceType}>{typeAndIcon.icon}</TooltipHost>;
        },
        onExport: (item: SanitizedDashboardRow) => {
          const typeAndIcon = SignificanceTypeAndIcon(item.siginificance, significanceIconMapping);
          return typeAndIcon.significanceType;
        }
      },
      {
        key: "coCode",
        name: "Co Code",
        fieldName: "companyCode",
        type: "object",
        minWidth: 60,
        onRender: (item?: SanitizedDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.companyCode);
        }
      },
      {
        key: "jeReferenceNumber",
        name: "JE Reference #",
        fieldName: "refNum",
        type: "string",
        minWidth: 75
      },
      {
        key: "status",
        name: "Status",
        fieldName: "status",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by status"
      },
      {
        key: "jeType",
        name: "JE Type",
        fieldName: "jeType",
        type: "string",
        minWidth: 60
      },
      {
        key: "reasonCode",
        name: "Reason Code",
        fieldName: "reasonDescription",
        type: "string",
        minWidth: 60
      },
      {
        key: "reviewer",
        name: "Reviewer",
        fieldName: "reviewer",
        type: "string",
        minWidth: 120,
        sortDescendingAriaLabel: "Sorted by reviewer",
        onRender: (item?: SanitizedDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.reviewer);
        }
      },
      {
        key: "reviewedBy",
        name: "Reviewed By",
        fieldName: "actualReviewer",
        type: "string",
        minWidth: 60,
        sortDescendingAriaLabel: "Sorted by actual reviewer"
      },
      {
        key: "reviewedDate",
        name: "Reviewed Date",
        fieldName: "reviewedDate",
        type: "date",
        minWidth: 60,
        sortDescendingAriaLabel: "Sorted by reviewed date"
      },
      {
        key: "poster",
        name: "Poster",
        fieldName: "poster",
        type: "string",
        minWidth: 90,
        sortDescendingAriaLabel: "Sorted by poster"
      },
      {
        key: "backupPosters",
        name: "Backup Poster",
        fieldName: "additionalPosters",
        type: "object",
        minWidth: 120,
        sortDescendingAriaLabel: "Sorted by poster",
        onRender: (item?: SanitizedDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.additionalPosters);
        }
      },
      {
        key: "fy",
        name: "FY",
        fieldName: "fiscalYear",
        type: "number",
        minWidth: 30
      },
      {
        key: "fp",
        name: "FP",
        fieldName: "fiscalPeriod",
        type: "number",
        minWidth: 20
      },
      {
        key: "header",
        name: "Header",
        fieldName: "description",
        type: "string",
        minWidth: 75
      },
      {
        key: "attachments",
        name: "Attachment",
        fieldName: "attachmentCount",
        type: "number",
        minWidth: 75,
        onRender: (item: SanitizedDashboardRow) => {
          if (Number(item.attachmentCount) > 0) {
            const el = (
              <IconButton
                onClick={() => {
                  if (attachmentRef) {
                    attachmentRef.open(item as GLDashboardRow);
                  }
                }}
                aria-label="Attachments"
              >
                <img
                  src={require(`../../assets/Icons/attachments.png`)}
                  className={classNames.fileIconImg}
                  alt={`Attachments Icon`}
                />
              </IconButton>
            );
            return el;
          } else {
            return DEFAULT_NULL_CHARACTER;
          }
        },
        onExport: (item: SanitizedDashboardRow) => {
          return item.attachments.map((x) => x.fileName).join(", ");
        }
      },
      {
        key: "dueDate",
        name: "Due Date",
        fieldName: "dueDate",
        type: "date",
        minWidth: 105
      },
      {
        key: "isBPO",
        name: "BPO",
        fieldName: "isBPO",
        type: "number",
        minWidth: 75,
        onRender: (item: SanitizedDashboardRow) => {
          const typeAndIcon = getBPOTypeAndIcon(item.postingType, item.isBPO);
          return <TooltipHost content={typeAndIcon.bPOType}>{typeAndIcon.icon}</TooltipHost>;
        }
      },
      {
        key: "format",
        name: "Format",
        fieldName: "format",
        type: "string",
        minWidth: 75
      },
      {
        key: "dateModified",
        name: "Date Modified",
        fieldName: "dateModified",
        type: "date",
        minWidth: 105
      },
      {
        key: "opsDetailsName",
        name: "Ops Details Name",
        fieldName: "opsDetailsName",
        type: "string",
        minWidth: 105
      },
      {
        key: "jeChecklistName",
        name: "Checklist Name",
        fieldName: "jeChecklistName",
        type: "string",
        minWidth: 105
      },
      {
        key: "isAdhocOrStd",
        name: "Task Type",
        fieldName: "isAdhocOrStd",
        type: "string",
        minWidth: 105
      },
      {
        key: "processingStatus",
        name: "Processing Status",
        fieldName: "processingStatus",
        type: "string",
        minWidth: 120,
        onRender: (item: SanitizedDashboardRow) => {
          const procColor = item.procStatType == "Success" ? theme.palette.green : theme.palette.red;
          return (
            <Text
              variant="small"
              style={{
                color: procColor
              }}
            >
              <TooltipHost content={item.processingStatus}>{item.processingStatus}</TooltipHost>
            </Text>
          );
        }
      }
    ];
    return cols;
  };
};
export const getPreReviewColumns = (location: Location, theme: Theme) => () => {
  return (): IExtendedColumn[] => {
    const cols: GLDraftColumn[] = [
      {
        key: "draftName",
        name: "Name",
        fieldName: "draftName",
        type: "string",
        minWidth: 80,
        onRender: (
          item?: SanitizedDraftDashboardRow,
          index?: number | undefined,
          column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!column || !item) {
            return null;
          }
          const url =
            (item.format === "Batch" || item.format === "Bulk") && item.status !== "Failed in SAP"
              ? `/gl/batch?RefGuid=${item.refGuid}`
              : `/gl/create?RefGuid=${item.refGuid}`;

          return (
            <Link
              to={url}
              state={{
                from: location
              }}
            >
              {item.draftName}
            </Link>
          );
        }
      },
      {
        key: "coCode",
        name: "Co Code",
        fieldName: "companyCode",
        type: "object",
        minWidth: 40,
        onRender: (item?: SanitizedDraftDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.companyCode);
        }
      },
      {
        key: "attachments",
        name: "Attachment",
        fieldName: "attachments",
        type: "string",
        minWidth: 75,
        onRender: (item: SanitizedDraftDashboardRow) => {
          if (Number(item.attachments) > 0) {
            return (
              <TooltipHost content={"Please open Draft to see Attachments"}>
                <img
                  src={require(`../../assets/Icons/attachments.png`)}
                  className={classNames.fileIconImg}
                  alt={`Attachments Icon`}
                />
              </TooltipHost>
            );
          } else {
            return DEFAULT_NULL_CHARACTER;
          }
        }
      },
      {
        key: "description",
        name: "Description",
        fieldName: "description",
        type: "string",
        minWidth: 75
      },
      {
        key: "status",
        name: "Status",
        fieldName: "statusName",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by status"
      },
      {
        key: "poster",
        name: "Poster",
        fieldName: "poster",
        type: "string",
        minWidth: 90,
        sortDescendingAriaLabel: "Sorted by poster"
      },
      {
        key: "additionalPosters",
        name: "Backup Posters",
        fieldName: "additionalPosters",
        type: "object",
        minWidth: 120,
        sortDescendingAriaLabel: "Sorted by poster",
        onRender: (item?: SanitizedDraftDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.additionalPosters);
        }
      },
      {
        key: "reviewer",
        name: "Reviewer",
        fieldName: "reviewer",
        type: "string",
        minWidth: 120,
        sortDescendingAriaLabel: "Sorted by reviewer",
        onRender: (item?: SanitizedDraftDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.reviewer);
        }
      },
      {
        key: "preReviewer",
        name: "PreReviewer",
        fieldName: "preReviewer",
        type: "string",
        minWidth: 120,
        sortDescendingAriaLabel: "Sorted by prereviewer",
        onRender: (item?: SanitizedPreReviewDashboardRow) => {
          if (!item) {
            return null;
          }
          return onRenderArray(item.preReviewer);
        }
      },
      {
        key: "lastComments",
        name: "Comments",
        fieldName: "lastComments",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by comments"
      },
      {
        key: "fiscalPeriod",
        name: "Fiscal Period",
        fieldName: "fiscalPeriod",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by Fiscal Period"
      },
      {
        key: "isBPOEntry",
        name: "BPO",
        fieldName: "isBPOEntry",
        type: "string",
        minWidth: 75,
        onRender: (item: SanitizedDraftDashboardRow) => {
          const typeAndIcon = getBPOTypeAndIcon(item.postingType, item.isBPOEntry);
          return <TooltipHost content={typeAndIcon.bPOType}>{typeAndIcon.icon}</TooltipHost>;
        }
      },
      {
        key: "format",
        name: "Format",
        fieldName: "format",
        type: "string",
        minWidth: 75
      },
      {
        key: "modifiedOn",
        name: "Date Modified",
        fieldName: "modifiedOn",
        type: "date",
        minWidth: 105
      },
      {
        key: "processingStatus",
        name: "Processing Status",
        fieldName: "processingStatus",
        type: "string",
        minWidth: 120,
        onRender: (item: SanitizedDashboardRow) => {
          const procColor = item.procStatType == "Success" ? theme.palette.green : theme.palette.red;
          return (
            <Text
              variant="small"
              style={{
                color: procColor
              }}
            >
              <TooltipHost content={item.processingStatus}>{item.processingStatus}</TooltipHost>
            </Text>
          );
        }
      }
    ];
    return cols;
  };
};

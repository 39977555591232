import { AISearchResult, GLDashboardRowFromApi } from "@jem/components";

export class GLSearchState {
  JEStatus: number[];
  Significance: string[]; // Significance in the UI
  JEType: string[]; // JeType in the UI
  AliasType: "P" | "R"; // 1 = P, 2 = R
  Alias: string[];
  FiscalYear: number;
  FiscalMonth: number[];
  DocNumber: string;
  BPOClassification: number[]; // BPO Classification
  CompanyCode: number[];
  ReasonCode: string[]; // Reason Code
  BusinessGroups: string[]; // Bussiness Groups
  OpsDetailId: number[];
  JEName: string;
  SearchType?: boolean;
  RecurringScheduleName: string;
  constructor(opts: {
    JEStatus: number[];
    Significance: string[]; // Significance in the UI
    JEType: string[]; // JeType in the UI
    AliasType: "P" | "R"; // 1 = P, 2 = R
    Alias: string[];
    FiscalYear: number;
    FiscalMonth: number[];
    DocNumber: string;
    BPOClassification: number[]; // BPO Classification
    CompanyCode: number[];
    ReasonCode: string[]; // Reason Code
    BusinessGroups: string[]; // Bussiness Groups
    OpsDetailId: number[];
    JEName: string;
    SearchType: boolean;
    RecurringScheduleName: string;
  }) {
    this.JEStatus = opts.JEStatus;
    this.Significance = opts.Significance;
    this.JEType = opts.JEType;
    this.AliasType = opts.AliasType;
    this.Alias = opts.Alias;
    this.FiscalYear = opts.FiscalYear;
    this.FiscalMonth = opts.FiscalMonth;
    this.DocNumber = opts.DocNumber;
    this.BPOClassification = opts.BPOClassification;
    this.CompanyCode = opts.CompanyCode;
    this.ReasonCode = opts.ReasonCode;
    this.BusinessGroups = opts.BusinessGroups;
    this.OpsDetailId = opts.OpsDetailId;
    this.JEName = opts.JEName;
    this.SearchType = opts.SearchType;
    this.RecurringScheduleName = opts.RecurringScheduleName;
    if (
      [
        this.JEStatus,
        this.Significance,
        this.JEType,
        this.AliasType,
        this.Alias,
        this.FiscalYear,
        this.FiscalMonth,
        this.DocNumber,
        this.BPOClassification,
        this.CompanyCode,
        this.ReasonCode,
        this.BusinessGroups,
        this.OpsDetailId,
        this.JEName,
        this.SearchType,
        this.RecurringScheduleName
      ].some((val) => val === undefined)
    ) {
      throw new Error("GLSearchState does not accept undefined values");
    }
  }

  toRecord(): Record<string, string> {
    const record: Record<string, string> = {};

    Object.entries(this).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        record[key] = value.join(",");
      } else if (value !== null && typeof value !== "undefined") {
        record[key] = value.toString();
      }
    });

    return record;
  }
}
export const InitialSearchState = new GLSearchState({
  JEStatus: [],
  Significance: [],
  JEType: [],
  AliasType: "P",
  Alias: [],
  FiscalYear: 0,
  FiscalMonth: [],
  DocNumber: "",
  BPOClassification: [],
  CompanyCode: [],
  ReasonCode: [],
  BusinessGroups: [],
  OpsDetailId: [],
  JEName: "",
  SearchType: true,
  RecurringScheduleName: ""
});

export type GLSearchApiPayload = GLDashboardRowFromApi[] | AISearchResult;

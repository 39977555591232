import { JemConfiguration } from "../../../JemConfiguration";
import { APIAnomalyError } from "../../utilities/ErrorHelpers";
import { getObjectFromCacheOrFromEndpoint } from "../../utilities/LocalStorageCache";
import { getRequest, getValidUrl, IUserProviderState } from "../../utilities/RequestUtilities";

export interface ClaimsApiResponse {
  dateFormat: string;
  currencyFormat: string;
  profile: {
    claimType: number;
    claimValue: string | null;
    description: string | null;
  }[];
}

export interface APIProfile {
  hasSapAccess: boolean;
  hasIhccAccess: boolean;
  hasSapReversalAccess: boolean;
  hasF05Access: boolean;
  hasMsSalesAccess: boolean;
  isActive: boolean;
  isTreasuryAdmin: boolean;
  showRecurringJE: boolean;

  fullName: string;
  supervisor: string;
  alias: string;

  sapJeCurrencyFormat: string;
  backupAfcCompanyCodes: string;
  sapIhccCurrencyFormat: string;

  currencyFormat: string;
  dateFormat: string;
  delegatedManager: string;

  roles: string[];
  tenants: { tenant: string; appId: string }[];
}

export async function fetchUserProfile(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<APIProfile | null> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getUserProfile}`;
  const endpointUrl = getValidUrl(endpoint);
  const payload = await getRequest<APIProfile>(endpointUrl, getTokenFn);
  return payload;
}

export async function fetchRefreshedUserProfile(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<APIProfile | null> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getRefreshedUserProfile}`;
  const endpointUrl = getValidUrl(endpoint);
  const payload = await getRequest<APIProfile>(endpointUrl, getTokenFn);
  return payload;
}

export async function getProfile(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  refetchUserJemRoles: boolean
) {
  const userRoles = await getObjectFromCacheOrFromEndpoint<APIProfile | null, JemConfiguration["GeneralLedgerApi"]>(
    configuration,
    "JemUserRoles",
    refetchUserJemRoles ? fetchRefreshedUserProfile : fetchUserProfile,
    getTokenFn,
    6
  );
  if (!userRoles || Object.keys(userRoles).length === 0) {
    throw new APIAnomalyError("No roles received from GeneralLedger API");
  }
  return userRoles;
}

import { BaseButton, Button, Checkbox, DefaultButton, Dropdown, IDropdownOption, PrimaryButton } from "@fluentui/react";
import {
  JemConfiguration,
  DomainDataObjects,
  JemNotification,
  GetCurrentFiscalPeriod,
  UserContext,
  JEMContext,
  IAliasInputRef,
  CompanyCodeMultiSelectorRef,
  IFiscalPeriodsRef,
  ISignificanceRef,
  RefTextFieldRef,
  LoadingStatus,
  SearchAliasInput,
  SignificanceDropdown,
  ForceMaxWidthAndHeightOnDropdownStyle,
  FiscalPeriodsDropdown,
  CompanyCodeMultiSelector,
  RefTextField,
  DomainDataEnum,
  Section,
  SearchOpsDetailDropdown,
  SearchOpsDetailDropdownRef,
  SectionRef
} from "@jem/components";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { GLSearchState } from "./GLSearch.types";
import BPOClassificationDropdown, { BPOClassificationDropdownRef } from "./userInputFields/BPOClassificationDropdown";
import BusinessGroupsDropdown, { BusinessGroupsRef } from "./userInputFields/BusinessGroupsDropdown";
import JEStatus, { JEStatusRef } from "./userInputFields/JEStatusDropdown";
import JETypeDropdown, { JETypeRef } from "./userInputFields/JETypeDropdown";
import ReasonCodeDropdown, { ReasonCodeRef } from "./userInputFields/ReasonCodeDropdown";

export interface GLSearchFormRef {
  expand: () => void;
}

interface IGLSearchFormProps {
  searchState: GLSearchState | undefined;
  configuration: JemConfiguration["GeneralLedgerApi"];
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.JeCompanyCodes
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.JeBusinessGroupInfo
  >;
  triggerRefreshFn: (searchState: GLSearchState, procStatusMessages: JemNotification) => void;
  customRef?: React.MutableRefObject<GLSearchFormRef | null>;
  environment?: JemConfiguration["environment"];
  officeBrowserFeedback: any;
}

export function fiscalYearAndFiscalPeriodForSearch(domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>): {
  fiscalYearsForDropdown: IDropdownOption[];
  currentFiscalYear: number;
  currentFiscalPeriod: number;
} {
  const fiscalPeriod = GetCurrentFiscalPeriod(domainData);

  const fiscalYearsToUseInDropdown = Array.from({ length: 10 }, (_, i) => fiscalPeriod.fiscalYear - 10 + i + 1);
  fiscalYearsToUseInDropdown.push(fiscalPeriod.fiscalYear + 1);
  const fiscalYearsForDropdown = fiscalYearsToUseInDropdown.map((x) => ({ key: x, text: `${x}` } as IDropdownOption));
  return {
    fiscalYearsForDropdown,
    currentFiscalYear: fiscalPeriod.fiscalYear,
    currentFiscalPeriod: fiscalPeriod.fiscalMonth
  };
}

const GLSearchForm: React.FC<IGLSearchFormProps> = (props: IGLSearchFormProps) => {
  const { user } = useContext(UserContext);
  const { initInfo } = useContext(JEMContext);

  const [fiscalYearsLoading, setFiscalYearsLoading] = useState(true);
  const [fiscalPeriodsLoading, setfiscalPeriodsLoading] = useState(true);

  const sectionRef = useRef<SectionRef>(null);

  const aliasesRef = useRef<IAliasInputRef>(null);
  const companyCodesRef = useRef<CompanyCodeMultiSelectorRef>(null);
  const fiscalPeriodRef = useRef<IFiscalPeriodsRef>(null);
  const significanceRef = useRef<ISignificanceRef>(null);
  const jeStatusRef = useRef<JEStatusRef>(null);
  const bpoClassificationRef = useRef<BPOClassificationDropdownRef>(null);
  const jeTypeRef = useRef<JETypeRef>(null);
  const docNumberRef = useRef<RefTextFieldRef>(null);
  const reasonCodeRef = useRef<ReasonCodeRef>(null);
  const businessGroupRef = useRef<BusinessGroupsRef>(null);
  const opsDetailRef = useRef<SearchOpsDetailDropdownRef>(null);
  const jeNameRef = useRef<RefTextFieldRef>(null);
  const recurringJENameRef = useRef<RefTextFieldRef>(null);

  const [fiscalYearSettings, _setfiscalYearSettings] = useState<{
    fiscalYearsForDropdown: IDropdownOption[];
    currentFiscalYear: number;
    currentFiscalPeriod: number;
  }>(() => {
    return fiscalYearAndFiscalPeriodForSearch(props.domainData);
  });
  const [fiscalYear, setFiscalYear] = useState(fiscalYearSettings.currentFiscalYear);
  const [isAIsearch, setIsAIsearch] = useState(true);

  const onChange = React.useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      setIsAIsearch(!!checked);
    },
    []
  );

  useImperativeHandle(props.customRef, () => ({
    expand: () => {
      window.requestAnimationFrame(() => {
        if (sectionRef.current) {
          sectionRef.current.expand();
        }
      });
    }
  }));

  const onChangeFiscalYear = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setFiscalYear(option.key as number);
    }
  };

  const reset = () => {
    const currentYear = fiscalYearSettings.currentFiscalYear;

    setFiscalYear(currentYear);
    if (aliasesRef.current) {
      aliasesRef.current.setInitialAliases({
        aliases: [user.alias],
        aliasType: "P"
      });
    }
    const currentFp = fiscalYearSettings.currentFiscalPeriod;
    const lastFp = currentFp - 1 > 0 ? currentFp - 1 : 12;
    if (lastFp === 12) {
      setFiscalYear(currentYear - 1);
    }
    if (fiscalPeriodRef.current) {
      fiscalPeriodRef.current.setInitialFiscalPeriods([lastFp]);
    }
    if (companyCodesRef.current) {
      companyCodesRef.current.reset();
    }
    if (significanceRef.current) {
      significanceRef.current.reset();
    }
    if (jeStatusRef.current) {
      jeStatusRef.current.reset();
    }
    if (bpoClassificationRef.current) {
      bpoClassificationRef.current.reset();
    }
    if (jeTypeRef.current) {
      jeTypeRef.current.reset();
    }
    if (docNumberRef.current) {
      docNumberRef.current.reset();
    }
    if (reasonCodeRef.current) {
      reasonCodeRef.current.reset();
    }
    if (businessGroupRef.current) {
      businessGroupRef.current.reset();
    }
    if (opsDetailRef.current) {
      opsDetailRef.current.reset();
    }
    if (jeNameRef.current) {
      jeNameRef.current.reset();
    }
    if (recurringJENameRef.current) {
      recurringJENameRef.current.reset();
    }
  };

  const submit = async (
    ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement>
  ) => {
    ev.preventDefault();
    if (
      aliasesRef.current &&
      companyCodesRef.current &&
      fiscalPeriodRef.current &&
      significanceRef.current &&
      jeStatusRef.current &&
      bpoClassificationRef.current &&
      jeTypeRef.current &&
      docNumberRef.current &&
      reasonCodeRef.current &&
      businessGroupRef.current &&
      opsDetailRef.current &&
      jeNameRef.current &&
      recurringJENameRef.current
    ) {
      const Aliases = aliasesRef.current.getAliases();
      const AliasType = aliasesRef.current.getAliasType();
      const CompanyCode = companyCodesRef.current.getCompanyCodes();
      const FiscalPeriods = fiscalPeriodRef.current.getFiscalPeriods();
      const Significance = significanceRef.current.getSignificance();
      const JEStatus = jeStatusRef.current.getStatuses();
      const BPOClassification = bpoClassificationRef.current.getBpoClassification();
      const JEType = jeTypeRef.current.getJEType();
      const DocNumber = docNumberRef.current.getValue();
      const ReasonCode = reasonCodeRef.current.getReasonCode();
      const BusinessGroups = businessGroupRef.current.getBusinessGroups();
      const OpsDetail = opsDetailRef.current.getOpsDetail();
      const JEName = jeNameRef.current.getValue();
      const SearchType = isAIsearch ? true : false;
      const RecurringScheduleName = recurringJENameRef.current.getValue();
      const currentState = new GLSearchState({
        Alias: Aliases,
        AliasType: AliasType,
        CompanyCode: CompanyCode.map(Number).filter((x) => !isNaN(x)),
        Significance: Significance,
        FiscalMonth: FiscalPeriods,
        FiscalYear: fiscalYear,
        JEStatus,
        BPOClassification,
        JEType,
        DocNumber,
        ReasonCode,
        BusinessGroups,
        OpsDetailId: OpsDetail,
        JEName,
        SearchType,
        RecurringScheduleName
      });
      props.triggerRefreshFn(currentState, {
        subjectHeader: " ",
        summaryBodyText: " ",
        type: "Information"
      });
      if (
        initInfo.values?.JeParameters.filter((item) => item.name === "AllowSurveyToTrigger").map(
          (item) => item.showUX
        )[0]
      ) {
        console.log("SurveyActivityTriggered");
        props.officeBrowserFeedback.floodgate.getEngine().getActivityListener().logActivity("SurveyActivityTriggered");
      }
    }
  };

  useEffect(
    function loadSearchState() {
      if (initInfo.status !== LoadingStatus.Resolved) return;

      setFiscalYearsLoading(false);
      setfiscalPeriodsLoading(false);

      if (props.searchState !== undefined) {
        if (companyCodesRef.current) {
          companyCodesRef.current.setCompanyCodes(props.searchState.CompanyCode.map(Number));
        }

        setFiscalYear(props.searchState.FiscalYear);

        if (aliasesRef.current) {
          aliasesRef.current.setInitialAliases({
            aliases: props.searchState.Alias,
            aliasType: props.searchState.AliasType
          });
        }
        if (fiscalPeriodRef.current) {
          fiscalPeriodRef.current.setInitialFiscalPeriods(props.searchState.FiscalMonth);
        }
        if (significanceRef.current) {
          significanceRef.current.setInitialSignificance(props.searchState.Significance);
        }
        if (jeStatusRef.current) {
          jeStatusRef.current.setInitialStatuses(props.searchState.JEStatus);
        }
        if (bpoClassificationRef.current) {
          bpoClassificationRef.current.setInitialBpoClassification(props.searchState.BPOClassification);
        }
        if (jeTypeRef.current) {
          jeTypeRef.current.setInitialJEType(props.searchState.JEType);
        }
        if (docNumberRef.current) {
          docNumberRef.current.setInitialValue(props.searchState.DocNumber);
        }
        if (reasonCodeRef.current) {
          reasonCodeRef.current.setInitialReasonCode(props.searchState.ReasonCode);
        }
        if (businessGroupRef.current) {
          businessGroupRef.current.setInitialBusinessGroups(props.searchState.BusinessGroups);
        }
        if (opsDetailRef.current && props.searchState.OpsDetailId) {
          opsDetailRef.current.setInitialOpsDetail(props.searchState.OpsDetailId);
        }
      } else {
        if (aliasesRef.current) {
          aliasesRef.current.setInitialAliases({
            aliases: [user.alias],
            aliasType: "P"
          });
        }
        // if lastFp is 12, then fiscalYear should be current -1
        const currentFp = fiscalYearSettings.currentFiscalPeriod;
        const lastFp = currentFp - 1 > 0 ? currentFp - 1 : 12;
        if (lastFp === 12) {
          setFiscalYear(fiscalYearSettings.currentFiscalYear - 1);
        }
        if (fiscalPeriodRef.current) {
          fiscalPeriodRef.current.setInitialFiscalPeriods([lastFp]);
        }
      }
    },

    [props.searchState, initInfo.status]
  );

  return (
    <React.Fragment>
      <Section label="Search Fields" customRef={sectionRef}>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <RefTextField customRef={jeNameRef} label={"JE Name:"} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <SearchOpsDetailDropdown
                configuration={props.configuration}
                customRef={opsDetailRef}
                styles={{
                  root: {
                    width: "100%"
                  }
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <JEStatus customRef={jeStatusRef} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <SignificanceDropdown
                ref={significanceRef}
                loading={false}
                styles={{
                  width: "100%",
                  maxWidth: "100%",
                  minWidth: "80px"
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6 ms-xxl6">
                  <Dropdown
                    label="Fiscal Year:"
                    selectedKey={fiscalYear}
                    onChange={onChangeFiscalYear}
                    options={fiscalYearSettings.fiscalYearsForDropdown}
                    disabled={fiscalYearsLoading}
                    styles={ForceMaxWidthAndHeightOnDropdownStyle({
                      width: "100%",
                      minWidth: "75px",
                      height: "300px",
                      maxWidth: "100%"
                    })}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6 ms-xxl6">
                  <FiscalPeriodsDropdown
                    ref={fiscalPeriodRef}
                    loading={fiscalPeriodsLoading}
                    styles={{
                      width: "100%",
                      minWidth: "85px",
                      height: "300px",
                      maxWidth: "100%"
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <BPOClassificationDropdown customRef={bpoClassificationRef} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <JETypeDropdown customRef={jeTypeRef} loading={false} domainData={props.domainData} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <RefTextField customRef={docNumberRef} label={"Doc Number/s:"} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <ReasonCodeDropdown customRef={reasonCodeRef} loading={false} domainData={props.domainData} />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
              <BusinessGroupsDropdown customRef={businessGroupRef} loading={false} domainData={props.domainData} />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
              <SearchAliasInput
                ref={aliasesRef}
                optionsForCheckboxes={[
                  {
                    key: "P",
                    text: "Poster"
                  },
                  {
                    key: "R",
                    text: "Reviewer"
                  }
                ]}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
              <CompanyCodeMultiSelector customRef={companyCodesRef} />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
              <RefTextField customRef={recurringJENameRef} label={"Recurring JE Name:"} />
            </div>
          </div>
          <div className="ms-Grid-row">
            {
              <div className={"ms-Grid-col ms-sm12 ms-md6 ms-lg3 ms-lgPush6 ms-xxl1 ms-xxlPush10"}>
                <Checkbox label="AI Search (Preview)" checked={isAIsearch} onChange={onChange} />
              </div>
            }
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3 ms-lgPush6 ms-xxl1 ms-xxlPush10">
              <PrimaryButton
                text="Search"
                allowDisabledFocus
                onClick={submit}
                type="submit"
                disabled={false}
                styles={{
                  root: {
                    width: "100%",
                    marginTop: "8px",
                    maxWidth: "100%"
                  }
                }}
              />
            </div>

            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3 ms-lgPush6 ms-xxl1 ms-xxlPush10">
              <DefaultButton
                text="Reset"
                allowDisabledFocus
                onClick={reset}
                styles={{
                  root: {
                    width: "100%",
                    marginTop: "8px",
                    maxWidth: "100%"
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Section>
    </React.Fragment>
  );
};

GLSearchForm.displayName = "GLSearchForm";

export default GLSearchForm;

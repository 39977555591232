import {
  DomainDataEnum,
  DomainDataObjects,
  IUserProviderState,
  JemConfiguration,
  SanitizedDashboardRow,
  SignificanceOptions,
  postRequest,
  sanitizeAISearchDashboardRow,
  sanitizeDashboardRow
} from "@jem/components";
import { v4 } from "uuid";
import { GLSearchState, GLSearchApiPayload } from "./GLSearch.types";
import { BusinessGroupsOptions } from "./userInputFields/BusinessGroupsDropdown";
import { JETypeOptions } from "./userInputFields/JETypeDropdown";
import { ReasonCodeOptions } from "./userInputFields/ReasonCodeDropdown";

// never export this thing from this file
interface GLSearchStateForApi {
  JEType: string[]; // Significance in the UI - Must have all for ALL
  DocType: string[]; // JeType in the UI - Must have all for ALL
  AliasType: 1 | 2; // 1 = P, 2 = R
  Alias: string[] | [""];
  FiscalValue: {
    FiscalYear: number;
    FiscalMonth: number;
  }[];
  FiscalYear: number;
  DocNumber: string | null; // Doc Number in ui, must be null for empty string
  EntryType: number[]; // BPO Classification
  CompanyCode: number[] | null; // Company Code - must be null empty
  JEReasonCode: string[]; // Reason Code - must have all for ALL
  BGCode: string[]; // Bussiness Groups - must be empty for ALL
  JEStatus: number[]; // JE Status - must be empty for ALL
  // none of the following are being used from the looks of it
  IsBPO: false;
  IsHBI: false;
  View: 1;
  JEName: string | null;
  OpsDetailId: number[];
  uiLimit: number;
  totalCount: number;
  nextPageIndex: number;
  allowAISearchinUI?: boolean;
  RecurringScheduleName: string | null;
}

export async function getGridItemsFromPayload(
  getTokenFn: IUserProviderState["accessToken"],
  state: GLSearchState,
  config: JemConfiguration["GeneralLedgerApi"],
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.JeCompanyCodes
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.JeBusinessGroupInfo
  >,
  pageSize: number,
  nextPageIndex?: number,
  totalCount?: number,
  isExport?: boolean
): Promise<SanitizedDashboardRow[]> {
  const {
    Alias, // array of strings, when empty [""]
    AliasType, // 1 or 2
    BPOClassification, // [0] means all, [] means empty, otherwise it's an array of numbers
    BusinessGroups, // when all selected it must be all values possible
    CompanyCode, // null when empty, otherwise it's an array of numbers
    DocNumber, // when empty it must be null
    FiscalMonth, // when all selected it must be all values possible
    FiscalYear, //
    JEStatus, // when all selected it must be an empty array, also must be empty for ALL
    JEType, // when all selected it must be all values possible
    ReasonCode, // when all selected it must be all values possible
    Significance, // when empty [], when all it must be all values possible
    JEName,
    OpsDetailId,
    SearchType,
    RecurringScheduleName
  } = state;

  const bpos = BPOClassification.includes(-1) ? [] : BPOClassification.includes(0) ? [0] : BPOClassification;

  const businessGroups = BusinessGroups.includes("none")
    ? []
    : BusinessGroups.includes("all")
    ? BusinessGroupsOptions(domainData)
        .slice(2)
        .map((x) => x.key as string)
    : BusinessGroups;

  const jeStatuses = JEStatus.includes(-1) ? [] : JEStatus.includes(0) ? [] : JEStatus;

  const jeTypes = JEType.includes("none")
    ? []
    : JEType.includes("all")
    ? JETypeOptions(domainData)
        .slice(2)
        .map((x) => x.key as string)
    : JEType;

  const reasonCodes = ReasonCode.includes("none")
    ? []
    : ReasonCode.includes("all")
    ? ReasonCodeOptions(domainData)
        .slice(2)
        .map((x) => x.key as string)
    : ReasonCode;

  const significances = Significance.includes("none")
    ? []
    : Significance.includes("all")
    ? SignificanceOptions.slice(2).map((x) => x.key as string)
    : Significance;
  const searchType = SearchType;
  const data: GLSearchStateForApi = {
    JEType: significances,
    DocType: jeTypes,
    AliasType: AliasType === "P" ? 1 : 2,
    Alias: Alias.length === 0 ? [""] : Alias,
    FiscalValue: (FiscalMonth.includes(-1)
      ? []
      : FiscalMonth.includes(0)
      ? Array.from({ length: 12 }, (_, i) => i + 1)
      : FiscalMonth
    ).map((fm) => ({ FiscalYear: FiscalYear, FiscalMonth: fm })),
    FiscalYear: FiscalYear,
    DocNumber: DocNumber === "" /*|| isNaN(Number(DocNumber))*/ ? null : `${DocNumber}`, //removing this to send string with multiple doc numbers separated by comma (,)
    EntryType: bpos,
    CompanyCode: CompanyCode.length === 0 ? null : CompanyCode,
    JEReasonCode: reasonCodes,
    BGCode: businessGroups,
    JEStatus: jeStatuses,
    IsBPO: false,
    IsHBI: false,
    View: 1,
    JEName: JEName === "" || !JEName ? null : JEName,
    OpsDetailId: OpsDetailId,
    uiLimit: pageSize,
    totalCount: totalCount || 0,
    nextPageIndex: nextPageIndex || 0,
    allowAISearchinUI: searchType,
    RecurringScheduleName: RecurringScheduleName === "" || !RecurringScheduleName ? null : RecurringScheduleName
  };

  try {
    let searchurl = "";
    let gridData: GLSearchApiPayload | null = null;
    console.log("searchType", searchType);
    searchurl = `${config.endpoints.getJESearchResults}`;
    searchurl = isExport ? "api/v2.0/Search/export/{refguid}" : searchurl;
    const url = `${config.baseApiUrl}${searchurl}`.replace("{refguid}", v4());
    gridData = await postRequest<GLSearchApiPayload>(url, data, getTokenFn);
    if (gridData === null) {
      return [];
    }
    // Check if gridData is of type AISearchResult
    if ("searchInfos" in gridData) {
      return sanitizeAISearchDashboardRow(gridData);
    } else {
      if (Array.isArray(gridData)) {
        return gridData.map(sanitizeDashboardRow);
      }
    }
  } catch (e) {
    // return empty for now
    console.error(e);
    return [];
  }
  return [];
}

import { css } from "@emotion/css";
import { ProgressIndicator } from "@fluentui/react";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JemConfiguration } from "../../../JemConfiguration";
import { PageContext } from "../../contexts/PageContext/PageContext";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { RequireAtLeastOne } from "../../utilities/TypeUtils";
import { debounce, LoadingStatus } from "../../utilities/Utilities";
import { AppHeader } from "./AppHeader";
import { AppNav } from "./AppNav";

export interface IAppShell {
  props: {
    children?: React.ReactNode;
    configuration: JemConfiguration;
    officeBrowserFeedback: any;
  };
}
interface IAppShellState {
  isCollapsed: boolean;
}

const initialState: IAppShellState = {
  isCollapsed: false
};

type IAppShellReducer = (state: IAppShellState, changes: RequireAtLeastOne<IAppShellState>) => IAppShellState;

let AppShell: React.FC<IAppShell["props"]> = (props) => {
  const navigate = useNavigate();
  const mainRef = useRef<HTMLElement>(null);
  const pageContext = useContext(PageContext);
  const userContext = useContext(UserContext);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

  const [resizeObserver] = useState<ResizeObserver>(
    new ResizeObserver(
      debounce(() => {
        if (mainRef.current) {
          const width = Math.floor(mainRef.current.getBoundingClientRect().width);
          const height = Math.floor(mainRef.current.getBoundingClientRect().height);
          pageContext.pageResized({ width, height });
        }
      }, 100)
    )
  );

  const [state, setState] = useReducer<IAppShellReducer>(
    (previousState, changes) => ({
      ...previousState,
      ...changes
    }),
    initialState
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (mainRef.current) {
        resizeObserver.observe(mainRef.current);
        clearInterval(interval);
      }
    }, 300);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (
      userContext.jemRolesStatus === LoadingStatus.Resolved ||
      userContext.jemRolesStatus === LoadingStatus.Rejected
    ) {
      setLoadingStatus(LoadingStatus.Resolved);
    }
  }, [userContext]);

  const onClickHandler = (event: React.MouseEvent, route: string) => {
    if (event.ctrlKey) {
      window.open(route, "_blank");
    } else if (event.type === "click") {
      navigate(route);
    }
  };

  let widthOfNavBar = "0px";
  if (userContext.jemRolesStatus === LoadingStatus.Resolved || userContext.jemRolesStatus === LoadingStatus.Rejected) {
    widthOfNavBar = state.isCollapsed ? "48px" : "228px";
  }

  return (
    <div
      className={css`
        display: grid;
        grid-template-columns: var(--width-of-navbar, 228px) 1fr;
        grid-template-rows: 48px 1fr;
        grid-template-areas:
          "header header"
          "nav content";
        width: 100%;
        height: 100%;
        margin: 0;

        & [role="banner"] {
          grid-area: header;
          position: sticky;
          top: 0;
          z-index: 100;
        }
        & [role="navigation"] {
          grid-area: nav;
        }
        & main {
          grid-area: content;
          overflow: auto;
          padding-bottom: 16px;
          transition-property: width;
          transition-duration: 0.2s;
        }
      `}
      style={{
        "--width-of-navbar": widthOfNavBar
      }}
    >
      <AppHeader configuration={props.configuration}></AppHeader>
      {loadingStatus === LoadingStatus.Resolved ? (
        <>
          <AppNav
            onNavCollapsed={(isCollapsed) => {
              setState({ isCollapsed });
              pageContext.setNavCollapsedFlag(isCollapsed);
            }}
            configuration={props.configuration}
            appName="JEM"
            onClick={onClickHandler}
            officeBrowserFeedback={props.officeBrowserFeedback}
          ></AppNav>
          <main id="main" tabIndex={-1} ref={mainRef}>
            {props.children}
          </main>
        </>
      ) : (
        <main>
          <ProgressIndicator
            label="Loading User Permissions"
            styles={{
              root: {
                textAlign: "center"
              }
            }}
          />
        </main>
      )}
    </div>
  );
};

AppShell = React.memo(AppShell);

export { AppShell };

import React, { useContext, createContext, useEffect, useState } from "react";
import * as msal from "@azure/msal-browser";

import { LoggingContext } from "../LoggingContext/LoggingContext";

import { msalSettings, MsalSettings } from "./UserContext.msalConfiguration";
import { getMockUserContextObj } from "./UserContext.mocks";
import { AuthenticationState, IUserProviderState } from "../../utilities/RequestUtilities";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { FatalError } from "../../utilities/ErrorHelpers";
import { JemConfiguration } from "../../../JemConfiguration";
import { BlankAccessMessage, getRolesForUI, JemUserRoles } from "./UserContext.roles.main";
import { LoadingStatus } from "../../utilities/Utilities";

const initialState: IUserProviderState = {
  user: {
    alias: "",
    name: "",
    userName: "",
    accountIdentifier: ""
  },
  jemUser: {
    supervisor: "",
    roles: [],
    euaPortalUrl: "",
    accessMessageComponent: BlankAccessMessage,
    hasSapAccess: false,
    hasIhccAccess: false,
    hasSapReversalAccess: false,
    hasF05Access: false,
    hasMsSalesAccess: false,
    currencyFormat: "",
    dateFormat: "",
    delegatedManager: "",
    tenants: [],
    showRecurringJE: false
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshRoles: () => {},
  accessToken: () => Promise.resolve(undefined),
  status: AuthenticationState.Unauthenticated,
  jemRolesStatus: LoadingStatus.Idle
};

export class AuthError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

const UserContext = createContext(initialState);

interface IUserProviderCommonProps {
  children: React.ReactNode;
  mock: boolean | IUserProviderState;
  jemConfiguration: Pick<JemConfiguration, "GeneralLedgerApi" | "euaAccessPortal"> & {
    IhccApi?: JemConfiguration["IhccApi"];
  };
}

export type LegacyRolesFn = (userContext: Pick<IUserProviderState, "user" | "accessToken">) => Promise<JemUserRoles>;

export interface IUserProviderPropsToInitialize {
  msalSettings: MsalSettings;
  legacyUserRolesFn?: LegacyRolesFn;
  instance?: never;
  redirectSettings?: never;
}

export interface IUserProviderPropsToPreventInitialization {
  redirectSettings: MsalSettings["redirectSettings"];
  instance: msal.IPublicClientApplication;
  msalSettings?: never;
  legacyUserRolesFn?: never;
}

export type IUserProviderProps = IUserProviderCommonProps &
  (IUserProviderPropsToInitialize | IUserProviderPropsToPreventInitialization);

const setAccountOnProvider = (
  account: msal.AccountInfo,
  instance: msal.IPublicClientApplication,
  setAuthState: React.Dispatch<AuthenticationState>,
  setGraphData: React.Dispatch<IUserProviderState["user"]>
) => {
  instance.setActiveAccount(account);
  setAuthState(AuthenticationState.Authenticated);
  setGraphData({
    alias: account.username ? account.username.split("@")[0] || "" : "",
    name: account.name || "",
    userName: account.username,
    accountIdentifier: account.homeAccountId
  });
};

const handleAccount = async (
  instance: msal.IPublicClientApplication,
  account: string,
  redirectSettings: MsalSettings["redirectSettings"]
): Promise<msal.AccountInfo> => {
  const acc = instance.getAccountByHomeId(account);
  if (acc) {
    const request = { ...redirectSettings };
    request.account = acc;
    const r = await instance.acquireTokenSilent(request);
    if (r !== null && r.account !== null) {
      return r.account;
    } else {
      throw new AuthError("No Account Returned.");
    }
  } else {
    throw new AuthError("No Account locally.");
  }
};

const MsalUserProvider: React.FC<
  Pick<
    IUserProviderProps,
    "children" | "msalSettings" | "jemConfiguration" | "instance" | "redirectSettings" | "legacyUserRolesFn"
  >
> = (props) => {
  const [instance, setInstance] = useState<msal.IPublicClientApplication | null>(null);

  useEffect(() => {
    const initializeInstance = async () => {
      if (props.msalSettings) {
        const publicClientApp = await msal.PublicClientApplication.createPublicClientApplication(
          props.msalSettings.configuration
        );

        setInstance(publicClientApp);
      } else {
        setInstance(props.instance as msal.PublicClientApplication);
      }
    };

    initializeInstance();
  }, [props.msalSettings, props.instance]);

  const redirectSettings = props.msalSettings
    ? props.msalSettings.redirectSettings
    : (props.redirectSettings as MsalSettings["redirectSettings"]);

  const [graphData, setGraphData] = useState<IUserProviderState["user"] | null>(null);
  const [authState, setAuthState] = useState<AuthenticationState>(AuthenticationState.Unauthenticated);
  const [userJemRoles, setUserJemRoles] = useState<JemUserRoles>(initialState.jemUser);
  const [userJemRolesState, setUserJemRolesState] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [refetchUserJemRoles, setRefetchUserJemRoles] = useState<boolean>(false);

  useEffect(() => {
    const getUserData = async () => {
      if (graphData === null && instance !== null) {
        setAuthState(AuthenticationState.InProgress);
        const response = await instance.handleRedirectPromise();
        if (response !== null && response.account !== null) {
          setAccountOnProvider(response.account, instance, setAuthState, setGraphData);
        } else {
          const currentAccounts = instance.getAllAccounts();
          if (currentAccounts.length === 1) {
            try {
              // Single account scenario
              const accountData = await handleAccount(instance, currentAccounts[0].homeAccountId, redirectSettings);
              setAccountOnProvider(accountData, instance, setAuthState, setGraphData);
            } catch (e) {
              instance.acquireTokenRedirect(redirectSettings);
            }
          } else {
            instance.acquireTokenRedirect(redirectSettings);
          }
        }
      }
    };
    getUserData();
  }, [instance]);

  useEffect(() => {
    if (authState === AuthenticationState.Authenticated && graphData !== null && instance !== null) {
      const getUserRoles = async () => {
        const getToken = async () => {
          const response = await instance.acquireTokenSilent(redirectSettings);
          if (response) {
            return response;
          } else {
            throw new Error("Can't login");
          }
        };
        try {
          !refetchUserJemRoles && setUserJemRolesState(LoadingStatus.Pending);
          const jemUserRoles = props.legacyUserRolesFn
            ? await props.legacyUserRolesFn({
                accessToken: getToken,
                user: graphData
              })
            : await getRolesForUI(props.jemConfiguration, getToken, refetchUserJemRoles);
          setUserJemRoles(jemUserRoles);
          !refetchUserJemRoles && setUserJemRolesState(LoadingStatus.Resolved);
          if (refetchUserJemRoles) setRefetchUserJemRoles(false);
        } catch (e) {
          console.error(e);
          setUserJemRolesState(LoadingStatus.Rejected);
        }
      };
      getUserRoles();
    }
  }, [authState, graphData, instance, refetchUserJemRoles]);

  if (
    userJemRolesState !== LoadingStatus.Idle &&
    userJemRolesState !== LoadingStatus.Pending &&
    graphData !== null &&
    instance !== null
  ) {
    return (
      <UserContext.Provider
        value={{
          user: graphData,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          login: () => {
            return instance.loginRedirect(redirectSettings);
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          logout: () => {
            return instance.logoutRedirect({});
          },
          refreshRoles: (flag: boolean) => {
            setRefetchUserJemRoles(flag);
          },
          accessToken: async (overrideRedirectSettings?: msal.RedirectRequest) => {
            try {
              const tokenSettings = overrideRedirectSettings || redirectSettings;
              const response = await instance.acquireTokenSilent(tokenSettings);
              if (response) {
                return response;
              } else {
                throw new Error("Can't login");
              }
            } catch (e) {
              if (e instanceof msal.InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(redirectSettings);
              }
            }
          },
          status: AuthenticationState.Authenticated,
          jemRolesStatus: userJemRolesState,
          jemUser: userJemRoles
        }}
      >
        {props.children}
      </UserContext.Provider>
    );
  } else {
    return <LoadingSpinner label="Loading JEM" />;
  }
};

const UserProvider = (props: IUserProviderProps): JSX.Element => {
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Setup an ApplicationInsights Provider.");
  }

  if (!props.mock) {
    return <MsalUserProvider {...props} />;
  } else if (props.mock && typeof props.mock === "object") {
    return <UserContext.Provider value={props.mock}>{props.children}</UserContext.Provider>;
  } else {
    return <UserContext.Provider value={getMockUserContextObj()}>{props.children}</UserContext.Provider>;
  }
};

export { UserContext, UserProvider, msalSettings };
